import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import './bootstrap-cosmo.css';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';

const i18en = require('./locales/en/translation.json');
const i18ru = require('./locales/ru/translation.json');

i18next
    .use(initReactI18next)
    .init({
        lng: 'ru',
        fallbackLng: 'en',
        resources: {
            en: {
                translation: i18en
            },
            ru: {
                translation: i18ru
            }
        }
    });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <App />
        </HelmetProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
