import { Col, Image } from "react-bootstrap";

const ImagesBlock = ({images}) => {
    return (
        images.map((image, index) => (
            <Col key={index} xs={6} md={4} lg={3} xl={2} className="mt-3">
                <Image src={image} rounded fluid />
            </Col>
        ))
    )
};

export default ImagesBlock;
