import { Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import FitnessRatingElement from "../catalog/FitnessRatingElement";
import { getInsoleText } from "../../app/fitness";

const FitnessEstimateElement = ({ fitnessEstimate }) => {
    function getFitnessDetails(fitnessEstimate) {
        let details = [];

        details.push(
            <Col xs={6} lg={3} key="rating"><Trans>Overall rating</Trans></Col>,
            <Col xs={6} lg={3} key="rating_value"><FitnessRatingElement rating={fitnessEstimate.fitnessRating} /></Col>
        );

        details.push(
            <Col xs={6} lg={3} key="size"><Trans>Optimal size</Trans></Col>,
            <Col xs={6} lg={3} key="size_value"><b>{fitnessEstimate.size}</b></Col>
        );

        if (fitnessEstimate.fitnessRatings['length'] !== undefined && fitnessEstimate.deviations['length'] !== undefined) {
            details.push(
                <Col xs={6} lg={3} key="length"><Trans>Length</Trans></Col>,
                <Col xs={6} lg={3} key="length_value"><FitnessRatingElement rating={fitnessEstimate.fitnessRatings['length']} deviation={fitnessEstimate.deviations['length']} /></Col>
            );
        }

        if (fitnessEstimate.fitnessRatings['width'] !== undefined && fitnessEstimate.deviations['width'] !== undefined) {
            details.push(
                <Col xs={6} lg={3} key="width"><Trans>Width</Trans></Col>,
                <Col xs={6} lg={3} key="width_value"><FitnessRatingElement rating={fitnessEstimate.fitnessRatings['width']} deviation={fitnessEstimate.deviations['width']} /></Col>
            );
        }

        if (fitnessEstimate.fitnessRatings['height'] !== undefined && fitnessEstimate.deviations['height'] !== undefined) {
            details.push(
                <Col xs={6} lg={3} key="height"><Trans>Height</Trans></Col>,
                <Col xs={6} lg={3} key="height_value"><FitnessRatingElement rating={fitnessEstimate.fitnessRatings['height']} deviation={fitnessEstimate.deviations['height']} /></Col>
            );
        }

        if (fitnessEstimate?.insole) {
            details.push(
                <Col xs={6} lg={3} key="insole"><Trans>Insole</Trans></Col>,
                <Col xs={6} lg={3} key="insole_value">{getInsoleText(fitnessEstimate.insole)}</Col>
            );
        }

        if (fitnessEstimate?.extraInsole) {
            details.push(
                <Col xs={6} lg={3} key="extra_insole"><Trans>Extra insole</Trans></Col>,
                <Col xs={6} lg={3} key="extra_insole_value">{getInsoleText(fitnessEstimate.extraInsole)}</Col>
            );
        }

        return details;
    }

    return (
        <Container>
            <Row>
                {getFitnessDetails(fitnessEstimate).flat()}
            </Row>
        </Container>
    );
};

export default FitnessEstimateElement;
