async function apiCalculateSize(measurementId) {
    const url = process.env.REACT_APP_SHOESERVER_URL + '/api/calculateSize';
    const response = await fetch(url + "?" + new URLSearchParams({ measurementId: measurementId }), {
        'Accept': 'application/json',
        'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const data = await response.json();
    return data;
}

export {
    apiCalculateSize
};
