import { faArrowDown91, faArrowDownAZ, faArrowDownShortWide, faSort, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { Trans } from "react-i18next";

const SortButton = ({ sortMode, setSortMode, ratingSortEnabled }) => {
    function getSortIcon() {
        switch (sortMode) {
            case "NAME":
                return faArrowDownAZ;
            case "PRICE":
                return faArrowDownShortWide;
            case "RATING":
                return faArrowDown91;
            default:
                return faSort;
        }
    }

    function getSortText() {
        switch (sortMode) {
            case "NAME":
                return <Trans>Name</Trans>;
            case "PRICE":
                return <Trans>Price</Trans>;
            case "RATING":
                return <Trans>Rating</Trans>;
            default:
                return <Trans>Sort</Trans>;
        }
    }

    return (
        <Dropdown>
            <Dropdown.Toggle className="btn btn-secondary" id="dropdown-sort">
                <FontAwesomeIcon icon={getSortIcon()} /> {getSortText()}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => setSortMode('')} active={sortMode === ''}>
                    <FontAwesomeIcon icon={faSort} /> <Trans>Default</Trans>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setSortMode("NAME")} active={sortMode === "NAME"}>
                    <FontAwesomeIcon icon={faArrowDownAZ} /> <Trans>Name</Trans>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setSortMode("PRICE")} active={sortMode === "PRICE"}>
                    <FontAwesomeIcon icon={faArrowDownShortWide} /> <Trans>Price</Trans>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setSortMode("RATING")} active={sortMode === "RATING"} disabled={!ratingSortEnabled}>
                    <FontAwesomeIcon icon={faArrowDown91} /> <Trans>Rating</Trans>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default SortButton;
