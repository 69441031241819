class ProfessionApi {
    constructor(sessionId) {
        this.sessionId = sessionId;
    }

    ready() {
        return !!this.sessionId;
    }

    async getProfessionList(queryStr) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/professionList';

        const params = new URLSearchParams();
        if (queryStr) {
            params.append('queryStr', queryStr);
        }

        let headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint + "?" + params, { headers: headers });
        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const data = await response.json();
        return data;
    }

    async getClimaticList(codeName) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/climaticList';

        const params = new URLSearchParams();
        if (codeName) {
            params.append('codeName', 'True');
        }

        let headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint + "?" + params, { headers: headers });
        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const data = await response.json();
        return data;
    }

    async getProtectivePropertyList(codeName) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/protectiveProperties';

        const params = new URLSearchParams();
        if (codeName) {
            params.append('codeName', 'True');
        }

        let headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint + "?" + params, { headers: headers });
        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const data = await response.json();
        return data;
    }

    async getRiskFactorList(codeName) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/riskFactors';

        let params = new URLSearchParams();
        if (codeName) {
            params.append('codeName', 'True');
        }

        let headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint + "?" + params, { headers: headers });
        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const data = await response.json();
        return data;
    }

    async getProfessionSelection(profession, climatic, riskFactors) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/professionSelection';
        const url = endpoint;
        
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        let body = {
            'profession': profession.profession,
        };
        if (climatic) {
            body['climatic'] = climatic;
        }
        if (riskFactors) {
            body['riskFactors'] = riskFactors;
        }

        const response = await fetch(url, { headers: headers, method: 'POST', body: JSON.stringify(body) });
        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const data = await response.json();
        return data;
    }

    async getEmployeeSelection(employeeId, season) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/employeeSelection';
        const params = new URLSearchParams({
            'employee_id': employeeId,
            'season': season
        });

        let headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint + "?" + params, { headers: headers });
        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const data = await response.json();
        return data;
    }
}

export default ProfessionApi;
