import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "@react-hook/window-size";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Trans } from "react-i18next";
import Webcam from "react-webcam";
import '../../styles/WebcamShooter.scss';
import { clamp } from "../../utils/clamp";
import { imageToBase64, imageToJpeg } from "../../utils/jpeg";
import { showNotification } from "../../utils/notification";
import Loader from "../global/Loader";
import piexif from "piexifjs";

const WebcamShooter = ({ size = 2000, pictureCount = 1, pictureHelpers, handleCancel, handleDone }) => {
    const [windowWidth, windowHeight] = useWindowSize();

    const [videoCapabilities, setVideoCapabilities] = useState(null);
    const [photoCapabilities, setPhotoCapabilities] = useState(null);

    const videoConstraints = useMemo(() => ({
        width: clamp(Math.min(windowWidth * 0.95, windowHeight * 0.8, size), videoCapabilities?.width?.min, videoCapabilities?.width?.max),
        height: clamp(Math.min(windowWidth * 0.95, windowHeight * 0.8, size), videoCapabilities?.height?.min, videoCapabilities?.height?.max),
        facingMode: "environment",
        advanced: [{ torch: true }],
    }), [windowWidth, windowHeight, videoCapabilities, size]);

    const screenshotConstraints = useMemo(() => ({
        // NOTE: no clamp, it seems that screenshot size could be bigger than video capabilities, but how to handle that?
        width: clamp(size, videoCapabilities?.width?.min, videoCapabilities?.width?.max),
        height: clamp(size, videoCapabilities?.height?.min, videoCapabilities?.height?.max),
    }), [size, videoCapabilities]);

    const photoSettings = useMemo(() => ({
        "fillLightMode": "flash",
        "imageWidth": clamp(size, photoCapabilities?.imageWidth?.min, photoCapabilities?.imageWidth?.max),
        "imageHeight": clamp(size, photoCapabilities?.imageHeight?.min, photoCapabilities?.imageHeight?.max),
    }), [size, photoCapabilities]);

    const webcamRef = useRef(null);
    const [images, setImages] = useState([]);

    const makeMetadata = useCallback(() => {
        const metadata = {};

        // Source aspect ratio
        // NOTE: we suppose that camera API will provide correct focal length for the given size, but the image can be cropped after that by shorter side if needed
        // so to determine source aspect ratio we use maximum possible image width and height
        if (photoCapabilities?.imageWidth?.max && photoCapabilities?.imageHeight?.max) {
            metadata.sourceAspectRatio = photoCapabilities.imageWidth.max / photoCapabilities.imageHeight.max;
        }

        return metadata;
    }, [photoCapabilities]);

    const writeMetadata = useCallback((imageBase64) => {
        // Prepare metadata
        const metadata = makeMetadata();

        if (metadata.length === 0) {
            return imageBase64;
        }

        // Write metadata to string
        const metadataString = JSON.stringify(metadata);

        // Update Exif
        let exif = piexif.load(imageBase64);
        
        // Save metadata to User Comment Exif field
        exif["Exif"][piexif.ExifIFD.UserComment] = metadataString;

        const updatedImage = piexif.insert(piexif.dump(exif), imageBase64);
        
        return updatedImage;
    }, [makeMetadata]);

    const takePhoto = useCallback(async (ref, photoSettings) => {
        // Get video track
        const mediaStream = ref.current.stream;
        const videoTrack = mediaStream.getVideoTracks()[0];

        // Capture image (to blob)
        const imageCapture = new ImageCapture(videoTrack);
        let blob = await imageCapture.takePhoto(photoSettings);

        // Convert to JPEG
        if (blob.type !== "image/jpeg") {
            blob = await imageToJpeg(blob);
        }

        // Convert to base64
        let base64Url = await imageToBase64(blob);

        // Write metadata
        base64Url = await writeMetadata(base64Url);

        return base64Url;
    }, [writeMetadata]);

    const capture = useCallback(async () => {
        const prevImages = images;

        let imageSrc;
        try {
            imageSrc = await takePhoto(webcamRef, photoSettings);
        } catch (err) {
            console.log(err);
            showNotification(t("High quality photo failed, low quality is used") + ": " + err, 'warning');

            imageSrc = webcamRef.current.getScreenshot(screenshotConstraints);
        }

        const newImages = [...prevImages, imageSrc];
        setImages(newImages);

        if (newImages.length >= pictureCount) {
            handleDone(newImages);
        }
    }, [handleDone, images, pictureCount, photoSettings, screenshotConstraints, takePhoto, webcamRef]);

    // Load video and photo capabilities
    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
            .then(stream => {
                const videoTrack = stream.getTracks()[0];
                const capabilities = videoTrack.getCapabilities();
                setVideoCapabilities(capabilities);

                return Promise.resolve(videoTrack)
            })
            .catch(err => {
                console.log(err);
                showNotification(t("Video not supported") + ": " + err, 'danger');
            })
            .then(videoTrack => {
                const imageCapture = new ImageCapture(videoTrack);
                return imageCapture.getPhotoCapabilities();
            })
            .then(capabilities => {
                setPhotoCapabilities(capabilities);

                if (capabilities.imageWidth?.max < size || capabilities.imageHeight?.max < size) {
                    showNotification(t("High resolution photo not supported"), 'warning');
                }

                if (!Array.isArray(capabilities.fillLightMode) || !('flash' in capabilities.fillLightMode)) {
                    showNotification(t("Flash control not supported"), 'warning');
                }
            })
            .catch(err => {
                console.log(err);
                showNotification(t("High quality photo not supported") + ": " + err, 'warning');
            });
    }, [size]);

    if (!videoCapabilities) {
        return (
            <Loader />
        );
    }

    return (
        <div className="my-3 video-view" style={{ width: videoConstraints.width, height: videoConstraints.height }}>
            <Webcam
                audio={false}
                ref={webcamRef}
                videoConstraints={videoConstraints}
                screenshotFormat="image/jpeg"
                screenshotQuality={0.95}
                width={videoConstraints.width}
                height={videoConstraints.height}
                className="capture"
            />

            <div className="video-helper-text">
                {pictureHelpers?.[images.length]?.text && pictureHelpers[images.length].text}
            </div>

            {pictureHelpers?.[images.length]?.image && (
                <div className="video-helper-img">
                    <img src={pictureHelpers[images.length].image} alt="helper" />
                    <p><Trans>Photo example</Trans></p>
                </div>
            )}

            <div className="d-flex justify-content-center align-items-center video-controls">
                <div className="flex-grow-1"></div>
                <button className="btn btn-secondary me-2" onClick={handleCancel}><Trans>Cancel</Trans></button>
                <button className="btn btn-primary rounded-circle fs-1" onClick={capture}><FontAwesomeIcon icon={faCamera} /></button>
                <span className="ms-2">{(images.length + 1)} <Trans>of</Trans> {pictureCount}</span>
                <div className="flex-grow-1"></div>
            </div>
        </div>
    );
};

export default WebcamShooter;
