function parseProtPropertiesStr(protPropertiesStr) {
    return protPropertiesStr
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item.length > 0)
        .map((item) => item.split("/")
            .map((item) => item.trim())
            .filter((item) => item.length > 0));
}

function buildProtPropertiesStr(protProperties) {
    return protProperties
        .filter((item) => item.length > 0)
        .map((item) => item.join("/"))
        .join(",");
}

export { parseProtPropertiesStr, buildProtPropertiesStr };
