import { t } from "i18next";
import { showNotification } from "./notification";

async function shareUrl(title, text, url) {
    if (navigator.share) {
        try {
            await navigator.share({
                    title: title,
                    text: text,
                    url: url
                });
            console.log("Shared");
        } catch (err) {
            console.log(err);
        }
    } else {
        navigator.clipboard.writeText(url);
        showNotification(t("Link copied to clipboard"), 'success');
    }
}

export { shareUrl };
