import { t } from "i18next";
import { Helmet } from 'react-helmet-async';
import Header from "../components/global/Header";
import { Alert, Col, Container, Row } from "react-bootstrap";
import "../styles/Applications.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAppleWhole, faGlobe, faInfoCircle, faMobileAndroid } from "@fortawesome/free-solid-svg-icons";
import { Trans } from "react-i18next";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import Footer from "../components/global/Footer";

const Applications = () => {
    return (
        <>
            <Helmet>
                <title>{t("Applications")} - FeetApp</title>
            </Helmet>

            <Header />

            <Container className="mt-3">
                <Alert variant="info">
                    <Trans>Use smartphone with FeetApp specially designed application to perform feet measurement</Trans>
                </Alert>

                <Row className="align-items-start mt-5">
                    <Col md={4} className="text-center ms-auto">
                        <div>
                            <a href={process.env.REACT_APP_APPSTORE_LINK} target="_blank" rel="noreferrer">
                                <img className="market-big rounded" src="/appstore-badge.png" alt="App Store" />
                            </a>
                        </div>

                        <div className="my-3">
                            <QRCode value={process.env.REACT_APP_APPSTORE_LINK} />
                        </div>

                        <div>
                            <Alert variant="light">
                                <FontAwesomeIcon icon={faAppleWhole} /> <Trans>iOS application for iPhone &amp; iPad</Trans>
                            </Alert>
                        </div>
                    </Col>

                    <Col md={4} className="text-center me-auto">
                        <div>
                            <a href={process.env.REACT_APP_GOOGLE_PLAY_LINK} target="_blank" rel="noreferrer">
                                <img className="market-big rounded" src="/google-play-badge.png" alt="Google Play" />
                            </a>
                        </div>

                        <div className="my-3">
                            <QRCode value={process.env.REACT_APP_GOOGLE_PLAY_LINK} />
                        </div>

                        <div>
                            <Alert variant="light">
                                <FontAwesomeIcon icon={faMobileAndroid} /> <Trans>Android application for smartphones &amp; tablets</Trans>
                            </Alert>
                        </div>
                    </Col>

                    {/* <Col md={4} className="text-center">
                        <div>
                            <a href={process.env.REACT_APP_WEBSITE_URL} target="_blank" rel="noreferrer">
                                <img className="market-big rounded" src="/mobile-browser-badge.png" alt="Mobile web-browser" />
                            </a>
                        </div>

                        <div className="my-3">
                            <QRCode value={process.env.REACT_APP_WEBSITE_URL} />
                        </div>

                        <div>
                            <Alert variant="light">
                                <FontAwesomeIcon icon={faGlobe} /> <Trans>Mobile browser directly with <Link to={process.env.REACT_APP_WEBSITE_URL}>FeetApp</Link> website</Trans><br />
                                <FontAwesomeIcon icon={faInfoCircle} /> <i><Trans>Chrome browser on Android is recommended to use</Trans></i>
                            </Alert>
                        </div>
                    </Col> */}
                </Row>
            </Container>

            <Footer />
        </>
    );
};

export default Applications;
