import { Trans } from "react-i18next";

const Loader = ({ compact, text }) => {
    function getText() {
        if (text) {
            return text + "...";
        } else {
            return <Trans>Loading...</Trans>;
        }
    }
    
    return (
        <div>
            {!compact && (
                <p className="text-center">{getText()}</p>
            )}
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">{getText()}</span>
                </div>
            </div>
        </div>
    )
};

export default Loader;
