import StarsRating from "./StarsRating";
import { getDeviationText, getRatingText } from "../../app/fitness";

const FitnessRatingElement = ({rating, deviation}) => {
    function getRatingElement(rating) {
        const { text, style } = getRatingText(rating);
        return (
            <span className={style}>
                {text}
            </span>
        )
    }

    function getDeviationElement(deviation, rating) {
        let { text, style } = getDeviationText(deviation);

        if (rating !== undefined) {
            // Override style by rating style
            const { style: ratingStyle } = getRatingText(rating);
            style = ratingStyle;
        }

        return (
            <span className={style}>
                {text}
            </span>
        );
    }

    return (
        <div>
            <StarsRating rating={rating} /> {deviation ? getDeviationElement(deviation, rating) : getRatingElement(rating)}
        </div>
    );
};

export default FitnessRatingElement;
