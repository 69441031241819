import { useMemo } from "react";
import { getAvailableSizes, getShoeInsoles } from "../../app/shoeModel";
import AddToCartButton from "./AddToCartButton";

const AddToCartBlock = ({ item, humanName, selectedSize, setSelectedSize, selectedInsole, disabled }) => {
    const fitnessSize = useMemo(() => item.fitnessEstimate?.size, [item]);

    const insoles = useMemo(() => getShoeInsoles(item), [item]);
    const addToCartInsole = useMemo(() => {
        if (selectedInsole) {
            return selectedInsole;
        } else if (item.fitnessEstimate?.insole?.id && insoles.find(insole => insole.id === item.fitnessEstimate.insole.id)) {
            return item.fitnessEstimate.insole;
        } else if (insoles?.[0]) {
            return insoles[0];
        } else {
            return null;
        }
    }, [item, insoles, selectedInsole]);

    return (
        <div className='d-flex justify-content-between align-items-center'>
            <div className="text-start me-2">
                {item.availableSize && item.availableSize.length > 0 && (
                    <select className="form-select" value={selectedSize} onChange={(e) => setSelectedSize(e.target.value)}>
                        {getAvailableSizes(item).map((size) => (
                            <option key={size} value={size}>{size}{fitnessSize && fitnessSize === size && " ★"}</option>
                        ))}
                    </select>
                )}
            </div>

            <AddToCartButton item={item} selectedSize={selectedSize} selectedInsole={addToCartInsole} humanName={humanName} disabled={disabled} />
        </div>
    );
};

export default AddToCartBlock;
