import { Badge } from "react-bootstrap";
import useLocalStorageState from "use-local-storage-state";

const CartCountBadge = () => {
    const [cartItems ] = useLocalStorageState('cartItems', { defaultValue: [] });

    const cartCount = cartItems.length;

    if (cartCount === 0) {
        return null;
    }

    return (
        <Badge bg="secondary" pill>{cartCount}</Badge>
    )
};

export default CartCountBadge;
