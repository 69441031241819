import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { apiGetMeasurement } from "../app/measurementApi";
import { apiCalculateSize } from "../app/fitnessApi";
import { showNotification } from "../utils/notification";
import { t } from "i18next";
import { Helmet } from 'react-helmet-async';
import Header from "../components/global/Header";
import MeasurementDetails from "../components/measurement/MeasurementDetails";
import Loader from "../components/global/Loader";
import { Breadcrumb, Container } from "react-bootstrap";
import { getMeasurementName } from "../app/measurement";
import Footer from "../components/global/Footer";
import useEmployeeApi from "../app/useEmployeeApi";
import { Trans } from "react-i18next";
import EmployeeDetails from "../components/employee/EmployeeDetails";

const MeasurementView = () => {
    const [searchParams] = useSearchParams();

    const measurementId = useMemo(() => {
        return searchParams.get('measurementId');
    }, [searchParams]);

    const employeeApi = useEmployeeApi();

    const [isLoading, setIsLoading] = useState(true);
    const [measurement, setMeasurement] = useState();
    const [measurementSize, setMeasurementSize] = useState();
    const [employee, setEmployee] = useState(null);

    const loadData = useCallback(async () => {
        setIsLoading(true);

        try {
            const measurementData = await apiGetMeasurement(measurementId);
            setMeasurement(measurementData);

            const measurementSize = await apiCalculateSize(measurementId);
            setMeasurementSize(measurementSize);

            if (employeeApi.ready()) {
                const employee = await employeeApi.getEmployeeByMeasurement(measurementId);
                setEmployee(employee);
            } else {
                setEmployee(null);
            }
        } catch (err) {
            console.log(err);
            showNotification(t("Error loading measurement") + ": " + err, 'danger');
        }

        setIsLoading(false);
    }, [employeeApi, measurementId]);

    useEffect(() => {
        if (measurementId) {
            loadData();
        }
    }, [loadData, measurementId]);

    return (
        <>
            <Helmet>
                <title>{measurement ? getMeasurementName(measurement) + " - " + t("FeetApp") : t("FeetApp")}</title>
            </Helmet>

            <Header />

            {measurement && (
                <Container className="mt-3">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/measurements">{t("Measurements")}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{getMeasurementName(measurement)}</Breadcrumb.Item>
                    </Breadcrumb>

                    <MeasurementDetails measurement={measurement} measurementSize={measurementSize} />
                </Container>
            )}

            {employee && (
                <Container className="mt-3">
                    <h3><Trans>measurementViewEmployeeSection</Trans></h3>
                    <EmployeeDetails employee={employee} />
                </Container>
            )}

            {isLoading && <Loader />}

            <Footer />
        </>
    );
};

export default MeasurementView;
