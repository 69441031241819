import { useCallback, useMemo } from "react";
import useDebounce from "../../utils/useDebounce";
import { parseCodeDescriptionString } from "../../utils/codeDescriptionString";
import { Badge, Button, Form, InputGroup, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";

function ProtPropertySelect({ protProperties, value, values, handleSelected }) {
    const parsedProtProperties = useMemo(() => {
        return protProperties.map((protProperty) => {
            return parseCodeDescriptionString(protProperty);
        });
    }, [protProperties]);

    const [filterStr, setFilterStr, debouncedFilterStr] = useDebounce("", 500);

    const filteredProtProperties = useMemo(() => {
        if (!debouncedFilterStr) {
            return parsedProtProperties;
        }

        return parsedProtProperties.filter((protProperty) => {
            return protProperty.code.toLowerCase().includes(debouncedFilterStr.toLowerCase()) ||
                protProperty.description.toLowerCase().includes(debouncedFilterStr.toLowerCase());
        });
    }, [debouncedFilterStr, parsedProtProperties]);

    const isSelected = useCallback((codeName) => {
        return value === codeName || values?.includes(codeName);
    }, [value, values]);

    return (
        <div>
            {/* Filter input */}
            <InputGroup className="my-3 text-sm">
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control
                    type="text"
                    placeholder={t("employeeSearch")}
                    value={filterStr}
                    onChange={(e) => setFilterStr(e.target.value)}
                />
            </InputGroup>

            {/* ProtProperties list with scroll */}
            <div
                style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                }}
            >
                <Stack>
                    {filteredProtProperties.map((p) => (
                        <div key={p.code}>
                            <Button
                                variant="link"
                                className={"text-start " + (isSelected(p.code) && "bg-primary text-white")}
                                onClick={() => handleSelected(p.code)}
                            ><Badge bg="light">{p.code}</Badge> {p.description}"</Button>
                        </div>
                    ))}
                </Stack>
            </div>
        </div>
    );
}

export default ProtPropertySelect;
