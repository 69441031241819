import { Trans } from "react-i18next";
import outputNumber from "../../utils/number";
import { Col, Row, Table } from "react-bootstrap";
import { t } from "i18next";

const FeetMeasurementsTable = ({ measurement }) => {
    return (
        <Row>
            <Col lg={6} className="mx-auto">
                <Table striped bordered hover className="mt-3">
                    <thead>
                        <tr>
                            <th className="text-muted"><Trans>Foot</Trans></th>
                            <th><Trans>Left</Trans></th>
                            <th><Trans>Right</Trans></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-muted"><Trans>Length</Trans></td>
                            <td>{outputNumber(measurement?.left?.length, t("mm"))}</td>
                            <td>{outputNumber(measurement?.right?.length, t("mm"))}</td>
                        </tr>
                        <tr>
                            <td className="text-muted"><Trans>Width</Trans></td>
                            <td>{outputNumber(measurement?.left?.width, t("mm"))}</td>
                            <td>{outputNumber(measurement?.right?.width, t("mm"))}</td>
                        </tr>
                        <tr>
                            <td className="text-muted"><Trans>Segment length</Trans></td>
                            <td>{outputNumber(measurement?.left?.segmentLength, t("mm"))}</td>
                            <td>{outputNumber(measurement?.right?.segmentLength, t("mm"))}</td>
                        </tr>
                        <tr>
                            <td className="text-muted"><Trans>Segment width</Trans></td>
                            <td>{outputNumber(measurement?.left?.segmentWidth, t("mm"))}</td>
                            <td>{outputNumber(measurement?.right?.segmentWidth, t("mm"))}</td>
                        </tr>
                        <tr>
                            <td className="text-muted"><Trans>Girth 55%</Trans></td>
                            <td>{outputNumber(measurement?.left?.girth55, t("mm"))}</td>
                            <td>{outputNumber(measurement?.right?.girth55, t("mm"))}</td>
                        </tr>
                        <tr>
                            <td className="text-muted"><Trans>Girth 68%</Trans></td>
                            <td>{outputNumber(measurement?.left?.girth68, t("mm"))}</td>
                            <td>{outputNumber(measurement?.right?.girth68, t("mm"))}</td>
                        </tr>
                        <tr>
                            <td className="text-muted"><Trans>Width 55%</Trans></td>
                            <td>{outputNumber(measurement?.left?.width55, t("mm"))}</td>
                            <td>{outputNumber(measurement?.right?.width55, t("mm"))}</td>
                        </tr>
                        <tr>
                            <td className="text-muted"><Trans>Width 68%</Trans></td>
                            <td>{outputNumber(measurement?.left?.width68, t("mm"))}</td>
                            <td>{outputNumber(measurement?.right?.width68, t("mm"))}</td>
                        </tr>
                        <tr>
                            <td className="text-muted"><Trans>Height 55%</Trans></td>
                            <td>{outputNumber(measurement?.left?.height55, t("mm"))}</td>
                            <td>{outputNumber(measurement?.right?.height55, t("mm"))}</td>
                        </tr>
                        <tr>
                            <td className="text-muted"><Trans>Height 68%</Trans></td>
                            <td>{outputNumber(measurement?.left?.height68, t("mm"))}</td>
                            <td>{outputNumber(measurement?.right?.height68, t("mm"))}</td>
                        </tr>
                        <tr>
                            <td className="text-muted"><Trans>Heel track width</Trans></td>
                            <td>{outputNumber(measurement?.left?.heelTrackWidth, t("mm"))}</td>
                            <td>{outputNumber(measurement?.right?.heelTrackWidth, t("mm"))}</td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
};

export default FeetMeasurementsTable;
