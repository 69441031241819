import { Badge, Button, Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import { parseCodeDescriptionString } from "../../utils/codeDescriptionString";
import { useMemo } from "react";

const ProtPropertyVariants = ({ selectedProtProperties, setSelectedProtProperties, protPropertyVariants, setProtPropertyVariants }) => {
    const sortedPropertyVariants = useMemo(() => {
        if (protPropertyVariants) {
            return Object.fromEntries(Object.entries(protPropertyVariants).sort(([prop1, variants1], [prop2, variants2]) => prop1.localeCompare(prop2)));
        } else {
            return null;
        }
    }, [protPropertyVariants]);

    function processVariantSelected(selectedVariant) {
        // Find previously selected property
        let prevVariant = null;
        for (const p in protPropertyVariants) {
            if (protPropertyVariants[p].includes(selectedVariant)) {
                prevVariant = p;
                break;
            }
        }

        if (!prevVariant) {
            return;
        }

        // Update selected properties and property variants
        let selProps = selectedProtProperties;
        let propVariants = protPropertyVariants;
        const curPropVariants = protPropertyVariants[prevVariant];

        // Remove previously selected variant
        selProps = selProps.filter((p) => p !== prevVariant);
        delete propVariants[prevVariant];

        // Insert newly selected variant
        selProps = [...selProps, selectedVariant];
        propVariants[selectedVariant] = curPropVariants;

        // Update state
        setSelectedProtProperties(selProps);
        setProtPropertyVariants(propVariants);
    }

    return (
        <div>
            {(selectedProtProperties && protPropertyVariants && Object.keys(protPropertyVariants).length > 0) ? (
                <div className="d-flex flex-column my-3" style={{ maxHeight: "300px", overflowY: "auto" }}>
                    <Container>
                        {Object.entries(sortedPropertyVariants).map(([selProp, propVariants]) => (
                            <Row key={selProp} className="my-2">
                                <Col xs={12}>
                                    <div className="d-flex justify-content-start">
                                        {propVariants.length > 1 ? (
                                            propVariants.map((protProperty) => (
                                                <span key={protProperty}>
                                                    <Badge bg="light">{parseCodeDescriptionString(protProperty).code}</Badge>
                                                    <Button
                                                        key={protProperty}
                                                        id={protProperty}
                                                        variant={selectedProtProperties.includes(protProperty) ? "primary" : "outline-primary"}
                                                        style={{ border: "none", textAlign: "left" }}
                                                        className="me-2"
                                                        onClick={() => processVariantSelected(protProperty)}
                                                    >
                                                        {parseCodeDescriptionString(protProperty).description}
                                                    </Button>
                                                </span>
                                            ))) : (
                                            <span>
                                                <Badge bg="light">{parseCodeDescriptionString(selProp).code}</Badge> {parseCodeDescriptionString(selProp).description}
                                            </span>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </Container>
                </div>
            ) : (
                <Container>
                    <Row>
                        <Col xs={12}>
                            <p>
                                <Trans>noPropertiesSelected</Trans>
                            </p>
                        </Col>
                    </Row>
                </Container>
            )}
        </div>
    );
};

export default ProtPropertyVariants;
