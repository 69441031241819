function downloadBlob(blob, filename) {
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    // Cleanup the link element and object URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

function selectFile(suffix) {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = suffix;
    return new Promise((resolve, reject) => {
        input.onchange = () => {
            const file = input.files[0];
            if (file) {
                resolve(file);
            } else {
                reject();
            }
        };
        input.click();
    });
}

export { downloadBlob, selectFile };
