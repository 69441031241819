import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useMemo, useState } from "react";
import { Button, Collapse, Form, InputGroup } from "react-bootstrap";
import { Trans } from "react-i18next";

const CartLoader = ({ loadCallback, cartHistory }) => {
    const [open, setOpen] = useState(false);
    const [cartId, setCartId] = useState('');

    const dataItemsForHistory = useMemo(() => {
        if (cartHistory) {
            // Sort history values by ID
            const cartHistorySorted = Object.values(cartHistory).sort((a, b) => a.id.localeCompare(b.id));

            return cartHistorySorted.map((item) => {
                let str = item.id;
                if (item.name) {
                    str += ` (${item.name})`;
                }
                if (item.submitted) {
                    str += " ✅";
                }
                return <option key={item.id} value={item.id}>{str}</option>;
            });
        } else {
            return [];
        }
    }, [cartHistory]);

    const handleLoadCart = () => {
        loadCallback(cartId);
    }

    return (
        <div className="row">
            <div className="col-md-4 me-auto">
                <button
                    className="btn btn-link"
                    onClick={() => setOpen(!open)}
                    aria-controls="cartCollapse"
                    aria-expanded={open}
                >
                    <Trans>Load another shopping list</Trans>
                </button>

                <Collapse in={open}>
                    <div id="cartCollapse" className="mt-3">
                        <Form>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder={t("Enter shopping list ID")}
                                    value={cartId}
                                    list="cartHistoryList"
                                    onChange={(e) => setCartId(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleLoadCart();
                                        }
                                    }}
                                />

                                <datalist id="cartHistoryList">
                                    {dataItemsForHistory}
                                </datalist>
                                
                                <Button
                                    variant="primary"
                                    onClick={handleLoadCart}
                                >
                                    <FontAwesomeIcon icon={faCartArrowDown} className="mr-2" />
                                    <Trans>Load</Trans>
                                </Button>
                            </InputGroup>
                        </Form>
                    </div>
                </Collapse>
            </div>
        </div>
    )
};

export default CartLoader;
