import { useCallback, useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { t } from 'i18next';
import { Trans } from 'react-i18next';

import Header from '../components/global/Header';
import ShoeCard from '../components/catalog/ShoeCard';
import Loader from '../components/global/Loader';
import MeasurementFilter from '../components/catalog/MeasurementFilter';
import PaginationBlock from '../components/global/PaginationBlock';
import FiltersForm from '../components/catalog/FiltersForm';
import FiltersButton from '../components/catalog/FiltersButton';
import { apiGetShoeModels, apiGetShoeProperties } from '../app/catalogApi';
import { showNotification } from '../utils/notification';
import SortButton from '../components/catalog/SortButton';
import Footer from '../components/global/Footer';
import SearchForm from '../components/global/SearchForm';
import useSessionStorageState from '../utils/useSessionStorageState';
import { Button, Modal, Spinner } from 'react-bootstrap';
import useDebounce from '../utils/useDebounce';
import useEmployeeApi from '../app/useEmployeeApi';
import useProfessionApi from '../app/useProfessionApi';
import EmployeeSelect from '../components/employee/EmployeeSelect';
import SeasonSelect from '../components/employee/SeasonSelect';

const Catalog = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();

    const employeeApi = useEmployeeApi();
    const professionApi = useProfessionApi();

    const [measurementId, setMeasurementId] = useSessionStorageState('catalogMeasurementId', null);
    const [humanName, setHumanName] = useSessionStorageState('catalogHumanName', null);
    const [employee, setEmployee] = useSessionStorageState('catalogEmployee', null);
    const [employeeSeason, setEmployeeSeason] = useSessionStorageState('catalogEmployeeSeason', 'summer');

    const [employeeSelectOpen, setEmployeeSelectOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [modelsLoading, setModelsLoading] = useState(false);

    const abortControllerRef = useRef(null);

    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useSessionStorageState('catalogPage', 1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [sortMode, setSortMode] = useSessionStorageState('catalogSort', measurementId ? "RATING" : "");

    const ratingSortEnabled = useMemo(() => {
        return measurementId;
    }, [measurementId]);

    const DEFAULT_SELECTED_PROPERTIES = {
        availability: ["В наличии", "В производстве"],
    };
    const [defaultSelected] = useState(DEFAULT_SELECTED_PROPERTIES);

    const [searchString, setSearchString] = useSessionStorageState("catalogSearchString", "");;
    const [searchStringForUpdate, setSearchStringForUpdate] = useState(searchString);
    const [shoeProperties, setShoeProperties] = useState(null);
    const [selectedProperties, setSelectedProperties] = useSessionStorageState("catalogProperties", DEFAULT_SELECTED_PROPERTIES);

    const [, setCardPlayingModelId, cardPlayingModelId] = useDebounce(null, 500);

    const dismissMeasurementFilter = () => {
        setMeasurementId(null);
        setHumanName(null);
        setEmployee(null);

        setSearchParams({});
        setSortMode("");
    };

    const updateSelectedProperties = (newProperties) => {
        setCurrentPage(1);
        setSelectedProperties(newProperties);
    }

    function onSearchSubmit(searchString) {
        setCurrentPage(1);
        setSearchStringForUpdate(searchString);
    }

    function onFiltersClear() {
        setSearchString("");
        setSearchStringForUpdate("");
    }

    const fetchEmployee = useCallback(async (employeeId) => {
        try {
            // Fetch employee
            const employee = await employeeApi.getEmployee(employeeId);
            setEmployee(employee);

            return employee;
        } catch (err) {
            console.log(err);
            showNotification(t("employeeFetchFailed") + ": " + err, 'danger');
        }
    }, [employeeApi, setEmployee]);

    const selectEmployee = useCallback(async (employee) => {
        try {
            setEmployee(employee);

            if (employeeSeason && employee?.measurement_ids?.length > 0) {
                // Perform profession selection
                const selection = await professionApi.getEmployeeSelection(employee.id, employeeSeason);

                // Select last measurement from employee
                setMeasurementId(employee?.measurement_ids[employee?.measurement_ids.length - 1]);

                // Set human name from employee
                const humanName = [employee?.first_name, employee?.last_name].filter((item) => item).join(" ");
                setHumanName(humanName);

                // Insert selection into state
                setCurrentPage(1);
                navigate(".", {
                    state: {
                        selectedProperties: {
                            climaticConditions: [selection.climaticCondition],
                            //riskFactors: selectedRiskFactors,
                            protectiveProperties: selection.protectiveProperties,
                        }
                    }
                });
            }
        } catch (err) {
            console.log(err);
            showNotification(t("employeeFetchFailed") + ": " + err, 'danger');
        }
    }, [employeeSeason, navigate, professionApi, setCurrentPage, setEmployee, setHumanName, setMeasurementId]);

    // Scroll on the top when page changes
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [currentPage]);

    // Use data from state and searchParams
    useEffect(() => {
        // Employee season
        if (searchParams.get('employeeSeason')) {
            if (employeeSeason !== searchParams.get('employeeSeason')) {
                setEmployeeSeason(searchParams.get('employeeSeason'));
            }
        }

        // Employee id
        if (searchParams.get('employeeId')) {
            if (employee?.id !== searchParams.get('employeeId')) {
                fetchEmployee(searchParams.get('employeeId'))
                    .then((employee) => {
                        selectEmployee(employee);
                    });
            }
        }

        // Measurement id
        if (searchParams.get('measurementId')) {
            if (measurementId !== searchParams.get('measurementId')) {
                setMeasurementId(searchParams.get('measurementId'));
                setCurrentPage(1);
                setSortMode("RATING");
            }
        }

        // Human name
        if (searchParams.get('humanName')) {
            if (humanName !== searchParams.get('humanName')) {
                setHumanName(searchParams.get('humanName'));
            }
        }
    }, [searchParams, humanName, setCurrentPage, setHumanName, employeeSeason, setEmployeeSeason, measurementId, setMeasurementId, setSortMode, employee?.id, fetchEmployee, selectEmployee]);

    // Use data from state
    useEffect(() => {
        // Search string
        if (location.state?.searchString) {
            setSearchString(location.state.searchString);
            setSearchStringForUpdate(location.state.searchString);
            location.state.searchString = null;
        }

        // Populate selected properties from given state
        if (location.state?.selectedProperties) {
            for (const [key, value] of Object.entries(location.state.selectedProperties)) {
                setSelectedProperties((prev) => ({ ...prev, [key]: [...value] }));
            }

            location.state.selectedProperties = null;
        }
    }, [location, setSearchString, setSelectedProperties, setSearchStringForUpdate]);

    // Fetch properties
    useEffect(() => {
        apiGetShoeProperties()
            .then(data => {
                setShoeProperties(data);
            })
            .catch(err => {
                console.log(err);
                showNotification(t("Error loading shoe properties") + ": " + err, 'danger');
            })
    }, []);

    // Fetch shoe models
    useEffect(() => {
        // Abort previous call
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();

        // NOTE: fetch is slightly delayed to avoid unnecessary refetch after dependencies change right after component mount
        const delayedFetch = setTimeout(() => {
            setModelsLoading(true);

            apiGetShoeModels(16, currentPage, measurementId, searchStringForUpdate, selectedProperties, sortMode, abortControllerRef.current.signal)
                .then(data => {
                    setModelsLoading(false);
                    setIsLoading(false);
                    setItems(data.shoeModels);
                    setTotalPages(data.page.pageCount); // Adjust the page number to start at 1
                    setTotalCount(data.page.totalCount);

                    // Reset current page if it is greater than the total number of pages
                    if (currentPage > data.page.pageCount) {
                        setCurrentPage(data.page.pageCount);
                    }
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log('Fetch request was aborted');
                    } else {
                        console.log(err);
                        showNotification(t("Error loading shoe models") + ": " + err, 'danger');
                        setModelsLoading(false);
                        setIsLoading(false);
                    }
                });
        }, 200);

        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }

            clearTimeout(delayedFetch);
        };
    }, [currentPage, measurementId, searchStringForUpdate, selectedProperties, sortMode, setCurrentPage]);

    return (
        <>
            <Helmet>
                <title>{t("Catalog")} - FeetApp</title>
            </Helmet>

            <Header />

            <div className='container'>
                {/* Measurement info */}
                {!measurementId && (employeeApi.ready() ? (
                    <div className='mt-3 alert alert-info'>
                        <Trans>catalogSelectMeasurementAlert <Link to="/measurements">measurements</Link></Trans>
                    </div>
                ) : (
                    <div className='mt-3 alert alert-info'>
                        <Trans>Use <Link to="/measurements">measurements</Link> tab to select a measurement to fit optimal shoes, or use <a
                            href="/fitAll" onClick={() => navigate("/fitAll", { state: { selectedProperties: selectedProperties, searchString: searchString } })}
                        > fit all</a> to fit for all measurements from  the shopping list.</Trans>
                    </div>
                ))}

                <Modal show={employeeSelectOpen} onHide={() => setEmployeeSelectOpen(false)}>
                    <Modal.Header>
                        <Modal.Title><Trans>selectEmployee</Trans></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SeasonSelect
                            season={employeeSeason}
                            setSeason={setEmployeeSeason} />

                        <EmployeeSelect
                            active={true}
                            handleActive={setEmployeeSelectOpen}
                            selected={employee}
                            handleSelected={(employee) => {
                                selectEmployee(employee);
                                setEmployeeSelectOpen(false);
                            }} />
                    </Modal.Body>
                </Modal>

                {/* Search form */}
                <div className='mt-3'>
                    <SearchForm searchString={searchString} setSearchString={setSearchString} onSubmit={onSearchSubmit} />
                </div>

                {/* Active filters & sort button */}
                <div className='mt-3 d-flex align-items-center'>
                    <div className='d-xl-none me-2'>
                        <FiltersButton shoeProperties={shoeProperties} selected={selectedProperties} setSelected={updateSelectedProperties} defaultSelected={defaultSelected} onClear={onFiltersClear} />
                    </div>

                    {/* Measurement plate - big screen only */}
                    {measurementId ? (
                        <div className='d-none d-xl-block'>
                            <MeasurementFilter measurementId={measurementId} humanName={humanName} employee={employee} season={employeeSeason} closeCallback={dismissMeasurementFilter} />
                        </div>
                    ) : (employeeApi.ready() && (
                        <div className='d-none d-xl-block'>
                            <Button variant="primary" onClick={() => setEmployeeSelectOpen(true)}>
                                <Trans>catalogSelectEmployee</Trans>
                            </Button>
                        </div>
                    ))}

                    {/* Total number - big screen only */}
                    {totalCount > 0 && (
                        <div className='ms-auto d-none d-xl-flex align-items-center'>
                            <span className='text-secondary'>{measurementId ? t("Total suitable models") : t("Total")}: {totalCount}</span> {modelsLoading ? <Spinner className='ms-1' size='sm ' /> : null}
                        </div>
                    )}

                    <div className='ms-auto'>
                        <SortButton sortMode={sortMode} setSortMode={setSortMode} ratingSortEnabled={ratingSortEnabled} />
                    </div>
                </div>

                {/* Measurement plate - small screen only */}
                <div className='mt-3 d-flex d-xl-none align-items-center'>
                    {measurementId ? (
                        <div>
                            <MeasurementFilter measurementId={measurementId} humanName={humanName} employee={employee} season={employeeSeason} closeCallback={dismissMeasurementFilter} />
                        </div>
                    ) : (employeeApi.ready() && (
                        <div>
                            <Button variant="secondary" onClick={() => setEmployeeSelectOpen(true)}>
                                <Trans>catalogSelectEmployee</Trans>
                            </Button>
                        </div>
                    ))}

                    {totalCount > 0 && (
                        <div className={'d-flex align-items-center ' + (measurementId ? 'ms-auto' : 'ms-auto me-auto')}>
                            <span className='text-secondary'>{t("Total")}: {totalCount}</span> {modelsLoading ? <Spinner className='ms-1' size='sm ' /> : null}
                        </div>
                    )}
                </div>

                <div className='row'>
                    <div className='col-xl-3 d-none d-xl-block'>
                        <div className='mt-3'>
                            {shoeProperties ? (
                                <FiltersForm shoeProperties={shoeProperties} selected={selectedProperties} setSelected={updateSelectedProperties} defaultSelected={defaultSelected} onClear={onFiltersClear} />
                            ) : (
                                <Loader />
                            )}
                        </div>
                    </div>
                    <div className='col-xl-9'>

                        <div className="mt-3">
                            {!isLoading ? (
                                <>
                                    {/* Shoe models */}
                                    <div className="row mt-3">
                                        {items.length > 0 ? (
                                            items.map(item => (
                                                <div className="col-sm-6 col-md-4 col-lg-3 mb-3 d-flex align-items-stretch" key={item.modelId}>
                                                    <ShoeCard item={item} measurementId={measurementId} humanName={humanName} cardPlayingModelId={cardPlayingModelId} setCardPlayingModelId={setCardPlayingModelId} />
                                                </div>
                                            ))) : (
                                            <div className="col-12">
                                                {/* No shoe models */}
                                                <div className="text-center"><Trans>No shoe models</Trans></div>
                                            </div>
                                        )}
                                    </div>

                                    {/* Pagination */}
                                    <PaginationBlock currentPage={currentPage} totalPages={totalPages} maxDisplayedPages={5} setCurrentPage={setCurrentPage} />
                                </>
                            ) : (
                                <div className="col-12">
                                    <Loader />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Catalog;
