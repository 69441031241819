import { t } from "i18next"
import { Trans } from "react-i18next"
import Header from "../components/global/Header"
import Footer from "../components/global/Footer"
import { Helmet } from "react-helmet-async";
import useLocalStorageState from "use-local-storage-state";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiGetShoeModel } from "../app/catalogApi";
import { showNotification } from "../utils/notification";
import { Alert, Badge, Breadcrumb, Carousel, Col, Container, Form, Row } from "react-bootstrap";
import Loader from "../components/global/Loader";
import { getPropectiveProperties, getShoeDetails, getTypeBrandNameStr } from "../app/shoeModel";
import FitnessRatingElement from "../components/catalog/FitnessRatingElement";
import outputNumber from "../utils/number";
import { CSS_COLORS, getCssColorName } from "../utils/cssColors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";

const ComparisionView = () => {
    const [comparisionItems] = useLocalStorageState('comparisionItems', { defaultValue: [] });
    const [items, setItems] = useState([]);

    const [diffsOnly, setDiffsOnly] = useState(false);

    const shoeDetails = useMemo(() => {
        if (items.length !== 2) {
            return {};
        }

        const allDetails = items.map(item => {
            return getShoeDetails(item);
        });

        let details1 = allDetails[0];
        let details2 = allDetails[1];

        // Convert detailes from array [{key:key, ...}, ...] to object {key: {key: key, ...}, ...}
        details1 = details1.reduce((obj, item) => {
            obj[item.key] = item;
            return obj;
        }, {});
        details2 = details2.reduce((obj, item) => {
            obj[item.key] = item;
            return obj;
        }, {});

        // Collect all keys
        const keys = new Set([...Object.keys(details1), ...Object.keys(details2)]);

        // Mix details
        const details = {};
        for (const key of keys) {
            if (!diffsOnly || !(key in details1) || !(key in details2) || details1[key].value !== details2[key].value) {
                details[key] = [details1[key], details2[key]];
            }
        }

        return details;
    }, [items, diffsOnly]);


    const navigate = useNavigate();

    const getShoeModelUrl = useCallback((item, comparisionItem) => {
        let urlSearchParams = new URLSearchParams({
            modelId: item.modelId
        });

        if (comparisionItem?.measurementId) {
            urlSearchParams.append("measurementId", comparisionItem.measurementId);
        }

        if (comparisionItem?.selectedSize) {
            urlSearchParams.append("selectedSize", comparisionItem.selectedSize);
        }

        if (comparisionItem?.selectedInsole?.id) {
            urlSearchParams.append("selectedInsole", comparisionItem.selectedInsole.id);
        }

        if (comparisionItem?.humanName) {
            urlSearchParams.append("humanName", comparisionItem.humanName);
        }

        return "/shoeModel?" + urlSearchParams.toString();
    }, []);

    function renderRowTitle(title) {
        return (
            <>
                <span className="d-none d-md-inline">{title}</span>
                <span className="d-inline d-md-none fw-bold">{title}</span>
            </>
        )
    }

    function renderImages(item, comparisionItem) {
        return (
            <a href={getShoeModelUrl(item, comparisionItem)}>
                <Carousel interval={null}>
                    {item.imagesFixed.map(image => (
                        <Carousel.Item key={image}>
                            <img
                                className="shoe-details"
                                src={image}
                                alt={item.name}
                            />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </a>
        );
    }

    function renderFitnessEstimate(item, comparisionItem) {
        return (
            <FitnessRatingElement rating={item.fitnessEstimate.fitnessRating} />
        );
    }

    function renderSelectionDetails(item, comparisionItem) {
        return (
            <div className="d-flex flex-column bg-light p-2 rounded">
                {comparisionItem?.selectedSize && (
                    <div>{t("comparisionSelectedSize")}: <span className="text-primary">{comparisionItem.selectedSize}</span></div>
                )}

                {comparisionItem?.selectedInsole && (
                    <div>{t("comparisionSelectedInsole")}: <span className="text-primary">{comparisionItem.selectedInsole.name}</span> ({outputNumber(comparisionItem.selectedInsole.forefootThickness, t("mm"))})</div>
                )}

                {comparisionItem?.humanName && comparisionItem?.measurementId && (
                    <div><Trans>comparisionFor</Trans> <a href={`/measurement?measurementId=${comparisionItem.measurementId}`}>{comparisionItem.humanName}</a></div>
                )}
            </div>
        );
    }

    function renderShoeDetail(item, detail) {
        if (!detail) {
            return "-";
        } else if (detail.isCssColor) {
            return (
                <span>
                    {getCssColorName(detail.value)} <FontAwesomeIcon className="mx-2" icon={faSquare} style={{ color: CSS_COLORS[detail.value] }} />
                </span>
            )
        } else if (detail.isProtectiveProperties) {
            const protectiveProperties = getPropectiveProperties(item);
            return (
                <div className="d-flex flex-column">
                    {protectiveProperties.map((property) => (
                        <span>
                            <Badge className="border me-1" bg="light">{property.code}</Badge>
                            {property.description}
                        </span>
                    ))}
                </div>
            );
        } else {
            return detail.value;
        }
    }

    // If no items in comparision, redirect to catalog
    useEffect(() => {
        // Fetch data
        const fetchShoeModelData = async (comparisionItem) => {
            return await apiGetShoeModel(comparisionItem.modelId, comparisionItem?.measurementId, comparisionItem?.selectedInsole?.id, comparisionItem?.selectedSize);
        }

        const fetchData = async () => {
            const promises = comparisionItems.map(fetchShoeModelData);

            const data = await Promise.all(promises);
            setItems(data);
        }

        const delayedFetch = setTimeout(() => {
            if (comparisionItems.length >= 2) {
                fetchData().catch((error) => {
                    console.error(error);
                    showNotification("error", t("comparisionFetchError"));
                });
            }
        }, 200);

        return () => clearTimeout(delayedFetch);
    }, [comparisionItems, navigate]);

    return (
        <>
            <Helmet>
                <title>{t("comparisionPageTitle")}</title>
            </Helmet>

            <Header />

            {items.length >= 2 ? (
                <Container>
                    <Breadcrumb className="mt-3 mb-5">
                        <Breadcrumb.Item href="/catalog">{t("Catalog")}</Breadcrumb.Item>
                        <Breadcrumb.Item href={getShoeModelUrl(items[0], comparisionItems[0])}>{getTypeBrandNameStr(items[0])}</Breadcrumb.Item>
                        <Breadcrumb.Item active><Trans>comparisionBreadcrumbWith</Trans> {getTypeBrandNameStr(items[1])}</Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="my-3">
                        <Form.Check type="switch" id="diffsOnly" label={t("comparisionDiffsOnly")} checked={diffsOnly} onChange={(event) => setDiffsOnly(event.target.checked)} />
                    </div>

                    {/* Title */}
                    <Row className="mt-2">
                        <Col xs={12} md={4}></Col>
                        <Col xs={6} md={4}><h5>{getTypeBrandNameStr(items[0])}</h5></Col>
                        <Col xs={6} md={4}><h5>{getTypeBrandNameStr(items[1])}</h5></Col>
                    </Row>

                    {/* Images */}
                    <Row className="mt-2">
                        <Col xs={12} md={4}></Col>
                        <Col xs={6} md={4}>{renderImages(items[0], comparisionItems[0])}</Col>
                        <Col xs={6} md={4}>{renderImages(items[1], comparisionItems[1])}</Col>
                    </Row>

                    {/* Selection details */}
                    <Row className="mt-2">
                        <Col xs={12} md={4}></Col>
                        <Col xs={6} md={4}>{renderSelectionDetails(items[0], comparisionItems[0])}</Col>
                        <Col xs={6} md={4}>{renderSelectionDetails(items[1], comparisionItems[1])}</Col>
                    </Row>

                    {/* Fitness estimate */}
                    {items[0].fitnessEstimate && items[1].fitnessEstimate && (
                        <Row className="mt-2">
                            <Col xs={12} md={4}>{renderRowTitle(t("comparisionFitness"))}</Col>
                            <Col xs={6} md={4}>{renderFitnessEstimate(items[0], comparisionItems[0])}</Col>
                            <Col xs={6} md={4}>{renderFitnessEstimate(items[1], comparisionItems[1])}</Col>
                        </Row>
                    )}

                    {/* Comparision table */}
                    {Object.keys(shoeDetails).map((key) => (
                        <Row key={key} className="mt-2">
                            <Col xs={12} md={4}>{renderRowTitle(key)}</Col>
                            <Col xs={6} md={4}>{renderShoeDetail(items[0], shoeDetails[key][0])}</Col>
                            <Col xs={6} md={4}>{renderShoeDetail(items[1], shoeDetails[key][1])}</Col>
                        </Row>
                    ))}
                </Container>
            ) : comparisionItems.length >= 2 ? (
                <Loader text={t("comparisionLoading")} />
            ) : (
                <Alert variant="warning" className="my-3">
                    <Trans>comparisionEmpty</Trans>
                </Alert>
            )
            }

            <Footer />
        </>
    );
};

export default ComparisionView;
