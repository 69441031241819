import { faCamera, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useState } from "react";
import { Alert, Breadcrumb, Button, Col, Collapse, Container, Form, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet-async';
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useLocalStorageState from 'use-local-storage-state';
import { apiMakeMeasurement } from "../app/measurementApi";
import Header from "../components/global/Header";
import ImagesBlock from "../components/shooting/ImagesBlock";
import Loader from "../components/global/Loader";
import WebcamShooter from "../components/shooting/WebcamShooter";
import { showNotification } from "../utils/notification";
import Footer from "../components/global/Footer";
import ShootingGuide from "../components/shooting/ShootingGuide";

const MakeMeasurement = () => {
    const [shootingMode, setShootingMode] = useState(null);
    const [pictureHelpers, setPictureHelpers] = useState(null);

    const [isProcessing, setIsProcessing] = useState(false);
    const [processingStatus, setProcessingStatus] = useState(null);

    const [cartId] = useLocalStorageState('cartId');
    const [cartName] = useLocalStorageState('cartName');
    const [localMeasurementIds, setLocalMeasurementIds] = useLocalStorageState('localMeasurementIds', { defaultValue: [] });

    const [name, setName] = useState("");
    const [sex, setSex] = useState("");
    const [age, setAge] = useState("");
    const [weight, setWeight] = useState("");
    const [usualSize, setUsualSize] = useState("");
    const [sheetFormat, setSheetFormat] = useState("A4");
    const [extendedParamsOpen, setExtendedParamsOpen] = useState(false);

    const [imagesRight, setImagesRight] = useState([]);
    const [imagesLeft, setImagesLeft] = useState([]);

    const [warning, setWarning] = useState(null);

    const navigate = useNavigate();

    function startShooting(mode) {
        if (mode === "LEFT") {
            setImagesLeft([]);

            setPictureHelpers([
                { text: t("Left toe from the top"), image: "/photoExamples/leftTopToe.jpg" },
                { text: t("Left heel from the top"), image: "/photoExamples/leftTopHeel.jpg" },
                { text: t("Left foot inside from the floor"), image: "/photoExamples/leftFloor.jpg" },
            ]);
        } else {
            setImagesRight([]);

            setPictureHelpers([
                { text: t("Right toe from the top"), image: "/photoExamples/rightTopToe.jpg" },
                { text: t("Right heel from the top"), image: "/photoExamples/rightTopHeel.jpg" },
                { text: t("Right foot inside from the floor"), image: "/photoExamples/rightFloor.jpg" },
            ])
        }

        setShootingMode(mode);
    }

    function handleCancel() {
        if (shootingMode === "LEFT") {
            setImagesLeft([]);
        } else {
            setImagesRight([]);
        }

        setShootingMode(null);
    }

    function handleDone(images) {
        if (shootingMode === "LEFT") {
            setImagesLeft(images);
        } else {
            setImagesRight(images);
        }

        setShootingMode(null);
    }

    function submit() {
        setIsProcessing(true);

        const humanInfo = {
            name: name !== "" ? name : null,
            sex: sex !== "" ? sex : null,
            age: age !== "" ? parseInt(age) : null,
            weight: weight !== "" ? parseFloat(weight) : null,
            usualSize: usualSize !== "" ? usualSize : null
        };

        apiMakeMeasurement(humanInfo, imagesRight, imagesLeft, cartId, sheetFormat, (status) => {
            setProcessingStatus(status);
        })
            .then((data) => {
                setIsProcessing(false);
                setLocalMeasurementIds([...localMeasurementIds, data.measurementId]);
                
                if (data?.warnings?.[0]) {
                    setWarning(data.warnings[0]);
                    showNotification(t("Measurement done with warnings"), 'warning');
                } else {
                    navigate("/measurement?" + new URLSearchParams({ measurementId: data.measurementId }));
                }
            })
            .catch((err) => {
                console.log(err);
                setIsProcessing(false);
                showNotification(t("Error processing images") + ": " + err, 'danger');
            });
    }

    return (
        <div>
            <Helmet>
                <title>{t("Take measurement")} - FeetApp</title>
            </Helmet>

            <Header />

            {shootingMode ? (
                <WebcamShooter pictureCount={3} pictureHelpers={pictureHelpers} handleCancel={handleCancel} handleDone={handleDone} />
            ) : (
                <>
                    <Container className="my-3">
                        <Breadcrumb className="mt-3">
                            <Breadcrumb.Item href="/measurements">{t("Measurements")}</Breadcrumb.Item>
                            <Breadcrumb.Item active>{t("Take measurement")}</Breadcrumb.Item>
                        </Breadcrumb>

                        {/* {!cartId && (
                            <Alert variant="warning"><Trans>It is recommended to synchronize shopping list when making a new measurement</Trans></Alert>
                        )} */}

                        {cartName && (
                            <Alert variant="success"><Trans>measurementWillBeSavedTo</Trans> <b>{cartName}</b>.</Alert>
                        )}

                        <Form>
                            <Row className="mt-3">
                                <Col sm={12} md={6}>
                                    <Form.Control type="text" placeholder={t("Person name")} value={name} onChange={(e) => setName(e.target.value)} />
                                </Col>
                                <Col sm={6} md={3}>
                                    <Form.Select value={sex} onChange={(e) => setSex(e.target.value)}>
                                        <option value=""><Trans>Sex</Trans></option>
                                        <option value="MALE"><Trans>Male</Trans></option>
                                        <option value="FEMALE"><Trans>Female</Trans></option>
                                    </Form.Select>
                                </Col>
                                <Col sm={6} md={3}>
                                    <Button variant="outline-secondary" onClick={() => setExtendedParamsOpen(!extendedParamsOpen)} aria-controls="extendedParams" aria-expanded={extendedParamsOpen}>
                                        {extendedParamsOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />} <Trans>Extended parameters</Trans>
                                    </Button>
                                </Col>
                            </Row>

                            <Collapse in={extendedParamsOpen}>
                                <Row className="mt-3" id="extendedParams">
                                    <Col sm={6} lg={3}>
                                        <Form.Group controlId="age">
                                            <Form.Label><Trans>Age</Trans></Form.Label>
                                            <Form.Control type="number" placeholder={t("Enter age")} value={age} onChange={(e) => setAge(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6} lg={3}>
                                        <Form.Group controlId="weight">
                                            <Form.Label><Trans>Weight</Trans></Form.Label>
                                            <Form.Control type="number" placeholder={t("Enter weight in kg")} value={weight} onChange={(e) => setWeight(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6} lg={3}>
                                        <Form.Group controlId="usualSize">
                                            <Form.Label><Trans>Usual size</Trans></Form.Label>
                                            <Form.Control type="number" placeholder={t("Enter usual shoe size")} step={0.5} value={usualSize} onChange={(e) => setUsualSize(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6} lg={3}>
                                        <Form.Group controlId="sheetFormat">
                                            <Form.Label><Trans>Sheet format</Trans></Form.Label>
                                            <Form.Select value={sheetFormat} onChange={(e) => setSheetFormat(e.target.value)}>
                                                <option value="A4"><Trans>A4</Trans></option>
                                                <option value="A3"><Trans>A3</Trans></option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Collapse>
                        </Form>

                        <ShootingGuide />

                        <h3 className="mt-3"><Trans>Right foot</Trans></h3>

                        <Row>
                            <ImagesBlock images={imagesRight} />
                        </Row>

                        <Row className="mt-2">
                            <Col>
                                <Button className="mt-2" variant={imagesRight.length === 0 ? "primary" : "secondary"} onClick={() => startShooting("RIGHT")}><FontAwesomeIcon icon={faCamera} /> <Trans>Take images</Trans></Button>
                            </Col>
                        </Row>

                        <h3 className="mt-3"><Trans>Left foot</Trans></h3>

                        <Row>
                            <ImagesBlock images={imagesLeft} />
                        </Row>

                        <Row className="mt-2">
                            <Col>
                                <Button className="mt-2" variant={imagesLeft.length === 0 ? "primary" : "secondary"} onClick={() => startShooting("LEFT")}><FontAwesomeIcon icon={faCamera} /> <Trans>Take images</Trans></Button>
                            </Col>
                        </Row>

                        {isProcessing ? (
                            <Loader text={processingStatus} />
                        ) : (
                            <>
                                {(name.length > 0 && (imagesLeft.length > 0 || imagesRight.length > 0)) && (
                                    <Button className="mt-3" variant="primary" onClick={submit}><Trans>Submit</Trans></Button>
                                )}
                            </>

                        )}

                        {warning && (
                            <Alert className="mt-2" variant="warning">{warning}</Alert>
                        )}
                    </Container>

                    <Footer />
                </>
            )}
        </div>
    );
};

export default MakeMeasurement;
