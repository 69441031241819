import { t } from "i18next";

class ClientApi {
    constructor(sessionId) {
        this.sessionId = sessionId;
    }

    ready() {
        return !!this.sessionId;
    }

    async prepareVkLogin(windowLocationOrigin, redirectPathname) {
        const redirectUrl = `${windowLocationOrigin}${redirectPathname}`;

        // Generate codes on the backend
        const prepareEndpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/vk_prepare';
        const response = await fetch(prepareEndpoint + '?' + new URLSearchParams({ redirect_uri: redirectUrl }));
        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const data = await response.json();

        // Redirect to VK authorization page
        const authEndpoint = "https://id.vk.com/authorize"
        return authEndpoint + '?' + new URLSearchParams({
            "response_type": "code",
            "client_id": data.client_id,
            "code_challenge": data.code_challenge,
            "code_challenge_method": data.code_challenge_method,
            "redirect_uri": redirectUrl,
            "state": data.state,
            "scope": data.scope
        });
    }

    async performVkAuth(code, type, state, device_id) {
        if (type !== "code_v2") {
            throw new Error(t("loginVkInvalidType"));
        }

        if (!code || !state || !device_id) {
            throw new Error(t("loginVkInvalidParams"));
        }

        const authEndpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/vk_auth';
        const response = await fetch(authEndpoint + '?' + new URLSearchParams({ code, state, device_id }));
        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data.session_id;
    }

    async passwordLogin({ email, password }) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/password_login';

        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData,
            headers: {
                'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY
            }
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data.session_id;
    }

    async passwordRegisterAskCode(email, captchaToken) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/password_register_ask';

        const formData = new FormData();
        formData.append('email', email);
        formData.append('captcha_token', captchaToken);

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData,
            headers: {
                'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY
            }
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }

    async passwordRegister(regData) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/password_register';

        const formData = new FormData();
        for (const [key, value] of Object.entries(regData)) {
            formData.append(key, value);
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData,
            headers: {
                'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY
            }
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data.session_id;
    }

    async checkSession() {
        if (!this.sessionId) {
            return false;
        }

        try {
            const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/check_session';
            const response = await fetch(endpoint, {
                headers: {
                    'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
                    'X-Session-ID': this.sessionId
                }
            });

            if (response.status === 200) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }

    async getUserData() {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/get_user_data';

        const response = await fetch(endpoint, {
            headers: {
                'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
                'X-Session-ID': this.sessionId
            }
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data;
    }

    async updateUserData({ newPassword, oldPassword, newPasswordRepeated, firstName, lastName, companyName, email, phone }) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/update_user_data';

        const formData = new FormData();
        if (newPassword !== undefined && newPassword !== null) {
            formData.append('new_password', newPassword);
        }
        if (oldPassword !== undefined && oldPassword !== null) {
            formData.append('old_password', oldPassword);
        }
        if (newPasswordRepeated !== undefined && newPasswordRepeated !== null) {
            formData.append('new_password_repeated', newPasswordRepeated);
        }
        if (firstName !== undefined && firstName !== null) {
            formData.append('first_name', firstName);
        }
        if (lastName !== undefined && lastName !== null) {
            formData.append('last_name', lastName);
        }
        if (companyName !== undefined && companyName !== null) {
            formData.append('company_name', companyName);
        }
        if (phone !== undefined && phone !== null) {
            formData.append('phone', phone);
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData,
            headers: {
                'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
                'X-Session-ID': this.sessionId
            }
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data;
    }

    async changePassword(oldPassword, newPassword, newPasswordRepeated) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/change_password';

        const formData = new FormData();
        formData.append('old_password', oldPassword);
        formData.append('new_password', newPassword);
        formData.append('new_password_repeated', newPasswordRepeated);

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData,
            headers: {
                'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
                'X-Session-ID': this.sessionId
            }
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }

    async resetPassword({ code, email, captchaToken }) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/reset_password';

        let formData = new FormData();
        formData.append('email', email);

        if (captchaToken !== undefined && captchaToken !== null) {
            formData.append('captcha_token', captchaToken);
        }

        if (code !== undefined && code !== null) {
            formData.append('confirmation_code', code);
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            body: formData,
            headers: {
                'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            }
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return true;
    }

    async changeEmail({ code, email }) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/change_email';

        const params = new URLSearchParams();
        params.append('new_email', email);

        if (code !== undefined && code !== null) {
            params.append('confirmation_code', code);
        }

        const response = await fetch(endpoint + '?' + params, {
            headers: {
                'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
                'X-Session-ID': this.sessionId
            }
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }

    async confirmPhone({ code, phone }) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/confirm_phone';

        const params = new URLSearchParams();
        if (code !== undefined && code !== null) {
            params.append('confirmation_code', code);
        }
        if (phone !== undefined && phone !== null) {
            params.append('phone', phone);
        }

        const response = await fetch(endpoint + '?' + params, {
            headers: {
                'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
                'X-Session-ID': this.sessionId
            }
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }

    async deleteProfile(code) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/delete_profile';

        const params = new URLSearchParams();
        if (code !== undefined && code !== null) {
            params.append('confirmation_code', code);
        }

        const response = await fetch(endpoint + '?' + params, {
            headers: {
                'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
                'X-Session-ID': this.sessionId
            }
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }

    async logout() {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/logout';

        const response = await fetch(endpoint, {
            headers: {
                'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
                'X-Session-ID': this.sessionId
            }
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }
}

export default ClientApi;
