import { t } from "i18next";
import { CloseButton } from "react-bootstrap";
import { Trans } from "react-i18next";

function MeasurementFilter({ measurementId, humanName, employee, season, closeCallback }) {
    function getSeasonText(season) {
        switch (season) {
            case "winter":
                return t("Winter");
            case "summer":
                return t("Summer");
            default:
                return "";
        }
    }

    return (
        <div className="bg-primary-subtle border rounded d-flex justify-content-between align-items-center me-2 p-2">
            <div className="d-flex flex-column">
                <div>
                    <span className="d-none d-md-inline"><Trans>Fitting models for</Trans> </span>
                    <span className="fw-bold">{humanName !== "" ? humanName : t("Measurement") + " " + measurementId}</span>
                </div>

                {employee?.profession && (
                    <div>
                        <small className="text-muted">
                            {employee.profession}
                            {employee?.department && <span className="ms-1">({employee?.department})</span>}
                            {season && <span className="ms-1">&mdash; {getSeasonText(season)}</span>}
                        </small>
                    </div>
                )}
            </div>

            <div className="ms-2">
                <CloseButton onClick={closeCallback} />
            </div>
        </div>
    );
};

export default MeasurementFilter;
