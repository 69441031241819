const codeDescriptionRegex = /^([^ ]+) - (.*)$/u;

function parseCodeDescriptionString(codeDescriptionString) {
    const match = codeDescriptionString.match(codeDescriptionRegex);

    if (match) {
        return { code: match[1], description: match[2] };
    } else {
        return { code: codeDescriptionString.substring(0, 3), description: codeDescriptionString };
    }
}

function testCodeDescriptionString(codeDescriptionString) {
    const match = codeDescriptionString.match(codeDescriptionRegex);
    return !!match;
}

export { parseCodeDescriptionString, testCodeDescriptionString };
