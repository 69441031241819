function clamp(number, min, max) {
    let clamped_number = number;

    if (min !== undefined) {
        clamped_number = Math.max(min, clamped_number);
    }

    if (max !== undefined) {
        clamped_number = Math.min(max, clamped_number);
    }

    return clamped_number;
}

export { clamp };
