import { Helmet } from 'react-helmet-async';

import Header from '../components/global/Header';
import CartMeasurements from "../components/measurement/CartMeasurements";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faQrcode } from "@fortawesome/free-solid-svg-icons";
import LocalMeasurements from "../components/measurement/LocalMeasurements";
import { useCallback, useEffect, useState } from "react";
import { Button, Collapse } from "react-bootstrap";
import { useParams } from "react-router";
import useLocalStorageState from "use-local-storage-state";
import { Link } from "react-router-dom";
import Footer from "../components/global/Footer";
import ClientMeasurements from '../components/measurement/ClientMeasurements';
import EmployeesMeasurements from '../components/measurement/EmployeesMeasurements';
import useEmployeeApi from '../app/useEmployeeApi';

const Measurements = () => {
    const { measurementIds } = useParams();
    const [sessionId,] = useLocalStorageState('sessionId');
    const [cartId] = useLocalStorageState('cartId');
    const [cartMeasurementsOpen, setCartMeasurementsOpen] = useState(true);

    const employeeApi = useEmployeeApi();
    const [employees, setEmployees] = useState(null);
    
    const updateEmployees = useCallback(async () => {
        if (employeeApi.ready()) {
            const employees = await employeeApi.getEmployees();
            setEmployees(employees);
        } else {
            setEmployees(null);
        }
    }, [employeeApi]);

    useEffect(() => {
        const delayedUpdate = setTimeout(() => {
            updateEmployees();
        }, 200);
        return () => clearTimeout(delayedUpdate);
    }, [updateEmployees]);

    return (
        <>
            <Helmet>
                <title>{t("Measurements")} - FeetApp</title>
            </Helmet>

            <Header />

            <div className="container my-3">
                {/* Helper info */}
                <div className="alert alert-info">
                    <FontAwesomeIcon icon={faInfoCircle} /> <Trans>useFeetAppMobileOrScanner</Trans>
                    <Link to="/applications" className="ms-1">
                        <FontAwesomeIcon icon={faQrcode} /> <Trans>Applications</Trans>
                    </Link>
                </div>

                {sessionId ? (
                    <>
                        <h3 className='mt-3'><Trans>clientMeasurements</Trans></h3>
                        <ClientMeasurements measurementIds={measurementIds} employees={employees} />

                        <h3 className='mt-3'><Trans>employeesMeasurements</Trans></h3>
                        <EmployeesMeasurements employees={employees} />
                    </>
                ) : (
                    <>
                        <h3><Trans>Local measurements</Trans></h3>

                        <LocalMeasurements measurementIds={measurementIds} />

                        <Button variant="link" className="my-3" onClick={() => setCartMeasurementsOpen(!cartMeasurementsOpen)}
                            aria-controls="cart-measurements" aria-expanded={cartMeasurementsOpen}>
                            {cartMeasurementsOpen ? <Trans>Hide shopping list measurements</Trans> : <Trans>Show shopping list measurements</Trans>}
                        </Button>

                        <Collapse in={cartMeasurementsOpen}>
                            <div id="cart-measurements">
                                <div className="my-3 d-flex justify-content-between">
                                    <h3><Trans>Shopping list measurements</Trans></h3>

                                    {cartId && (
                                        <h5 className='text-muted'>{cartId}</h5>
                                    )}
                                </div>

                                <CartMeasurements />
                            </div>
                        </Collapse>
                    </>
                )}
            </div>

            <Footer />
        </>
    );
};

export default Measurements;
