import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next";
import { Button, Form, InputGroup } from "react-bootstrap"

const SearchForm = ({ searchString, setSearchString, onSubmit }) => {
    return (
        <Form className="d-flex" role="search" onSubmit={(e) => {
            e.preventDefault();
            onSubmit(e.target[0].value);
        }}>
            <InputGroup>
                <Form.Control
                    type="search"
                    placeholder={t("sfSearchPlaceholder")}
                    aria-label="Search"
                    aria-describedby="search-addon"
                    value={searchString}
                    onChange={(e) => {
                        setSearchString(e.target.value);
                        if (e.target.value === "") {
                            onSubmit("");
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            onSubmit(e.target.value);
                        }
                    }}
                />
                <Button id="search-addon" variant="primary" type="submit"><FontAwesomeIcon icon={faSearch} /></Button>
            </InputGroup>
        </Form>
    )
};

export default SearchForm;
