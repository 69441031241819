import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CloseButton, Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";

const RiskFactorsSelect = ({ riskFactors, active, onEditing, selectedRiskFactors, setSelectedRiskFactors, size }) => {
    return (
        <div>
            {(active && selectedRiskFactors) ? (
                <div>
                    <div className="d-flex flex-column my-3" style={{ maxHeight: "300px", overflowY: "auto" }}>
                        <Container>
                            <Row>
                                {riskFactors.map((riskFactor) => (
                                    <Col xs={12} md={6} key={riskFactor}>
                                        <Button
                                            // type="radio"
                                            size={size || ""}
                                            variant={selectedRiskFactors.includes(riskFactor) ? "primary" : "outline-primary"}
                                            style={{ border: "none", textAlign: "left" }}
                                            className="my-1"
                                            onClick={() => {
                                                if (selectedRiskFactors.includes(riskFactor)) {
                                                    setSelectedRiskFactors(selectedRiskFactors.filter(item => item !== riskFactor));
                                                } else {
                                                    setSelectedRiskFactors([...selectedRiskFactors, riskFactor]);
                                                }
                                            }}
                                        >
                                            {riskFactor}
                                        </Button>
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </div>

                    <div className="d-flex justify-content-center my-3">
                        <CloseButton onClick={() => onEditing(false)} />
                    </div>
                </div>
            ) : (
                <div className="d-flex flex-column my-3" style={{ maxHeight: "300px", overflowY: "auto" }}>
                    <Container>
                        <Row>
                            {selectedRiskFactors.length > 0 ? selectedRiskFactors.map((riskFactor) => (
                                <Col xs={12} md={6} key={riskFactor}>
                                    <Button
                                        key={riskFactor}
                                        type="checkbox"
                                        size={size || ""}
                                        variant={selectedRiskFactors.includes(riskFactor) ? "primary" : "outline-primary"}
                                        style={{ border: "none", textAlign: "left" }}
                                        className="my-1"
                                        onClick={() => {
                                            onEditing(true);
                                        }}
                                    >
                                        {riskFactor}
                                    </Button>
                                </Col>
                            )) : (
                                <Col xs={12}>
                                    <p><Trans>noRiskFactors</Trans></p>
                                </Col>
                            )}

                            <Col xs={12}>
                                <div className="d-flex justify-content-center align-items-center my-1">
                                    <Button variant="outline-secondary" className="ms-2" style={{ border: "none" }} onClick={() => onEditing(true)}>
                                        <FontAwesomeIcon icon={faEdit} /> <Trans>Edit</Trans>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
        </div>
    );
};

export default RiskFactorsSelect;
