function outputNumber(number, units, digits) {
    if (digits === undefined) {
        digits = 1;
    }

    if (typeof(number) === "number") {
        if (units !== undefined) {
            return number.toFixed(digits) + " " + units;
        } else {
            return number.toFixed(digits);
        }
    } else {
        return "–"; // endash
    }
}

export default outputNumber;
