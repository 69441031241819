import { useCallback, useEffect, useMemo, useState } from "react";
import { apiGetShoeModel } from "../../app/catalogApi";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import { getShoeImageUrl, getTypeBrandNameStr, isShoeOutOfStock } from "../../app/shoeModel";
import "../../styles/ShoeCardDetails.scss";
import { Badge, Card, OverlayTrigger, Placeholder } from "react-bootstrap";
import { Trans } from "react-i18next";

const ShoeVariantCard = ({ modelId, measurementId, humanName, selected, key }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [item, setItem] = useState();

    const shoeImageUrl = useMemo(() => item && getShoeImageUrl(item, true), [item]);
    const isOutOfStock = useMemo(() => item && isShoeOutOfStock(item), [item]);

    const getShoeModelUrl = useCallback(() => {
        if (!item) {
            return null;
        }

        let urlSearchParams = new URLSearchParams({
            modelId: item.modelId
        });

        if (measurementId) {
            urlSearchParams.append("measurementId", measurementId);
        }

        return "/shoeModel?" + urlSearchParams.toString();
    }, [item, measurementId]);

    const renderPopup = useCallback(() => {
        if (!item) {
            return null;
        }

        return (
            <Card className={isOutOfStock ? 'out-of-stock ' : ''} style={{ width: '10rem' }}>
                <Card.Img variant="top" src={shoeImageUrl} />
                <Card.Body>
                    {isOutOfStock && (
                        <div>
                            <span><Badge bg="secondary" ><Trans>outOfStock</Trans></Badge></span>
                        </div>
                    )}
                    <div>{getTypeBrandNameStr(item)}</div>
                </Card.Body>
            </Card>
        );
    }, [item, shoeImageUrl, isOutOfStock]);


    // Fetch item on mount
    useEffect(() => {
        const fetchItem = async () => {
            const data = await apiGetShoeModel(modelId, measurementId);
            setItem(data);
            setIsLoading(false);
        };

        const delayedFetch = setTimeout(() => {
            fetchItem().catch((err) => {
                console.log(err);
                showNotification(t("shoeVariantFetchError") + ": " + err, 'danger');
                setIsLoading(false);
            });
        }, 200);

        return () => clearTimeout(delayedFetch);
    }, [modelId, measurementId]);

    return (
        <Card className={"mx-2 my-2 border-3 p-1 " + (selected ? ' border-primary ' : '') + (isOutOfStock ? 'out-of-stock ' : '')} style={{ width: '5rem' }} key={key}>
            {isLoading ? (
                <Placeholder animation="glow" as={Card.Img} />
            ) : !shoeImageUrl ? (
                <Placeholder as={Card.Img} />
            ) : (
                <OverlayTrigger delay={{ show: 250, hide: 400 }} overlay={renderPopup()} placement="top">
                    <a href={getShoeModelUrl()} title={getTypeBrandNameStr(item)}>
                        <Card.Img variant="top" src={shoeImageUrl} />
                    </a>
                </OverlayTrigger>
            )}
        </Card>
    )
};

export default ShoeVariantCard;
