import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CloseButton, Col, Container, Row } from "react-bootstrap"
import { Trans } from "react-i18next";

const ClimaticSelect = ({ climaticList, active, onEditing, selectedClimatic, setSelectedClimatic }) => {
    return (
        <div>
            {active ? (
                <>
                    <div className="d-flex flex-column my-3" style={{ maxHeight: "300px", overflowY: "auto" }}>
                        <Container>
                            <Row>
                                {climaticList.map((climatic) => (
                                    <Col xs={12} key={climatic}>
                                        <Button
                                            variant={climatic === selectedClimatic ? "primary" : "outline-primary"}
                                            style={{ border: "none", textAlign: "left" }}
                                            className="my-1"
                                            onClick={() => {
                                                setSelectedClimatic(climatic);
                                            }}
                                        >
                                            {climatic}
                                        </Button>
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </div>

                    <div className="d-flex justify-content-center my-3">
                        <CloseButton variant="outline-secondary" onClick={() => onEditing(false)} />
                    </div>
                </>
            ) : (
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="d-flex align-items-center justify-content-start">
                            {selectedClimatic && (
                                <Button
                                    variant="primary"
                                    className="my-1"
                                    style={{ border: "none" }}
                                    onClick={() => {
                                        onEditing(true);
                                    }}
                                >
                                    {selectedClimatic}
                                </Button>
                            )}

                            <Button variant="outline-secondary"
                                size="sm"
                                className="ms-2"
                                style={{ border: "none" }}
                                onClick={() => onEditing(true)}
                            >
                                <FontAwesomeIcon icon={faEdit} /> <Trans>Edit</Trans>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            )}
        </div>
    );
};

export default ClimaticSelect;
