class ClientMeasurementApi {
    constructor(sessionId) {
        this.sessionId = sessionId;
    }

    ready() {
        return !!this.sessionId;
    }

    async addMeasurement(measurementId, ignoreAlreadyAdded) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/add_measurement';

        let formData = new FormData();
        formData.append('measurement_id', measurementId);

        if (ignoreAlreadyAdded) {
            formData.append('ignore_already_added', 'True');
        }

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: formData
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }

    async getMeasurements() {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/get_measurements';

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data;
    }

    async deleteMeasurement(measurementId) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/delete_measurement';

        let formData = new FormData();
        formData.append('measurement_id', measurementId);

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: formData
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }
}

export default ClientMeasurementApi;
