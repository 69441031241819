import { useMemo } from "react";
import useLocalStorageState from "use-local-storage-state";
import EmployeeApi from "./employeeApi";

function useEmployeeApi() {
    const [sessionId, ] = useLocalStorageState("sessionId", null);
    return useMemo(() => new EmployeeApi(sessionId), [sessionId]);
}

export default useEmployeeApi;
