import { useMemo } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Button } from "react-bootstrap";

const Quantifier = ({ productItem, updateCallback, removeCallback }) => {
    const quantity = useMemo(() => productItem.quantity, [productItem]);

    const increase = () => {
        updateCallback(productItem, quantity + 1);
    };

    const decrease = () => {
        const newValue = quantity - 1;

        if (newValue <= 0) {
            removeCallback(productItem);
        } else {
            updateCallback(productItem, newValue);
        }

        return newValue;
    };

    return (
        <div className="d-flex justify-content-center align-items-center">
            <Button variant={quantity > 1 ? "primary" : "danger"} size="sm" onClick={decrease}>
                {quantity > 1 ? (
                    <FontAwesomeIcon icon={faMinus} />
                ) : (
                    <FontAwesomeIcon icon={faRemove} />
                )}
            </Button>
            <span className="quantity mx-2">{quantity}</span>
            <Button variant="primary" size="sm" onClick={increase}>
                <FontAwesomeIcon icon={faPlus} />
            </Button>
        </div>
    )
};

export default Quantifier;
