import { t } from 'i18next';
import { Trans } from 'react-i18next';
import CurrencyFormatter from '../global/CurrencyFormatter';
import Quantifier from '../catalog/Quantifier';
import { Badge, Col, Row } from 'react-bootstrap';
import { useEffect, useMemo, useState } from 'react';
import { showNotification } from '../../utils/notification';
import { apiGetShoeModel } from '../../app/catalogApi';
import { getShoeImageUrl, isShoeOutOfStock } from '../../app/shoeModel';
import "../../styles/ShoeCardDetails.scss";

const CartItem = ({ cartItemData, updateCallback, removeCallback }) => {
    const [item, setItem] = useState(null);

    const imageUrl = useMemo(() => {
        if (item) {
            return getShoeImageUrl(item, true);
        } else {
            return null;
        }
    }, [item]);

    const shoeModelUrl = useMemo(() => {
        if (item) {
            let params = { modelId: item.modelId };

            if (cartItemData?.measurementId) {
                params.measurementId = cartItemData.measurementId;
            }

            if (cartItemData?.size) {
                params.size = cartItemData.size;
            }

            if (cartItemData?.insoleId) {
                params.insoleId = cartItemData.insoleId;
            }

            return "/shoeModel?" + new URLSearchParams(params);
        } else {
            return null;
        }
    }, [cartItemData.insoleId, cartItemData.measurementId, cartItemData.size, item]);

    const isOutOfStock = useMemo(() => {
        if (item) {
            return isShoeOutOfStock(item);
        } else {
            return false;
        }
    }, [item]);

    // fetch model item
    useEffect(() => {
        const fetchItem = async () => {
            const item = await apiGetShoeModel(cartItemData.modelId);
            setItem(item);
        }

        const delayedFetch = setTimeout(() => {
            fetchItem().catch((err) => {
                console.error(err);
                showNotification(t("cartItemFetchFailed") + ": " + err, 'danger');
            });
        }, 200);

        return () => clearTimeout(delayedFetch);
    }, [cartItemData.modelId]);

    return (
        <Row className={'mb-1 ' + (isOutOfStock ? 'out-of-stock ' : '') }>
            <Col xs={4} sm={2} xl={1}>
                <div className='p-2'>
                    {imageUrl && (
                        <a href={shoeModelUrl}>
                            <img src={imageUrl} alt="Shoe" className="w-100" />
                        </a>
                    )}
                </div>
            </Col>
            <Col xs={8} sm={6} xl={7}>
                <p>
                    <div>
                        <a href={shoeModelUrl}>
                            {cartItemData.modelType && cartItemData.modelType + " "}
                            {cartItemData.modelBrand && cartItemData.modelBrand + " "}
                            {cartItemData.modelName}
                        </a>
                        {cartItemData.humanName && (" " + t("for") + " " + cartItemData.humanName)}
                    </div>

                    {isOutOfStock && (
                        <Badge bg="secondary" ><Trans>outOfStock</Trans></Badge>
                    )}

                    <div className='text-small text-muted'><Trans>Article</Trans>: {cartItemData.article}</div>
                    <div><Trans>Size</Trans>: {cartItemData.size}</div>

                    {cartItemData.insoleName && (
                        <div><Trans>Insole</Trans>: {cartItemData.insoleName}</div>
                    )}
                </p>
            </Col>
            <Col xs={8} sm={2}>
                <Quantifier productItem={cartItemData} updateCallback={updateCallback} removeCallback={removeCallback} />
            </Col>
            <Col xs={4} sm={2}>
                <div className='d-flex justify-content-end'>
                    <span className='fw-bold'><CurrencyFormatter amount={cartItemData.price} /></span>
                </div>
            </Col>
        </Row>
    );
};

export default CartItem;
