import { useMemo } from "react";
import { Trans } from "react-i18next";

const PaginationBlock = ({ currentPage, totalPages, maxDisplayedPages, setCurrentPage }) => {
    const displayPagesArray = useMemo(() => {
        if (!maxDisplayedPages || maxDisplayedPages < 2 || totalPages <= maxDisplayedPages) {
            return Array.from({ length: totalPages }, (_, index) => index + 1);
        } else {
            const rangeStart = Math.max(currentPage - Math.floor(maxDisplayedPages / 2), 1);
            const rangeEnd = Math.min(rangeStart + maxDisplayedPages - 1, totalPages);

            let array = Array.from({ length: rangeEnd - rangeStart + 1 }, (_, index) => rangeStart + index);

            if (!array.includes(1)) {
                array = [1].concat(array);
            }

            if (!array.includes(totalPages)) {
                array = array.concat([totalPages]);
            }

            return array;
        }
    }, [currentPage, maxDisplayedPages, totalPages]);

    if (totalPages <= 1) {
        return null;
    }

    return (
        <div className="col-md-12 d-flex justify-content-center mt-3">
            <nav>
                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}><Trans>Previous</Trans></button>
                    </li>
                    {displayPagesArray.map(page => (
                        <li className={`page-item ${currentPage === page ? 'active' : ''}`} key={page}>
                            <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}><Trans>Next</Trans></button>
                    </li>
                </ul>
            </nav>
        </div>
    )
};

export default PaginationBlock;
