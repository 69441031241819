import { useMemo } from "react";
import useLocalStorageState from "use-local-storage-state";
import ClientApi from "./clientApi";

function useClientApi() {
    const [sessionId, ] = useLocalStorageState("sessionId", null);
    return useMemo(() => new ClientApi(sessionId), [sessionId]);
}

export default useClientApi;
