import { useEffect, useState } from "react";

// Taken from https://stackoverflow.com/a/67360129/685653
const useDebounce = (initialValue = "", delay) => {
    const [actualValue, setActualValue] = useState(initialValue);
    const [debounceValue, setDebounceValue] = useState(initialValue);

    useEffect(() => {
        const debounceId = setTimeout(() => setDebounceValue(actualValue), delay);
        return () => clearTimeout(debounceId);
    }, [actualValue, delay]);
    
    return [actualValue, setActualValue, debounceValue];
};

export default useDebounce;