import { t } from "i18next";
import { Form } from "react-bootstrap";

function SeasonSelect({ season, setSeason }) {
    return (
        <Form>
            <Form.Check inline label={t("seasonSummer")} onChange={() => setSeason("summer")} checked={season === "summer"} />
            <Form.Check inline label={t("seasonWinter")} onChange={() => setSeason("winter")} checked={season === "winter"} />
        </Form>
    );
}

export default SeasonSelect;
