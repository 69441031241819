import { faIdCard, faQrcode, faShoePrints, faShoppingBag, faUser, faUserGear } from '@fortawesome/free-solid-svg-icons';

import { Container, Nav, Navbar } from "react-bootstrap";
import { NotificationContainer } from "react-notifications";
import 'react-notifications/lib/notifications.css';
import CartCountBadge from "./CartCountBadge";

import { t } from 'i18next';
import "../../styles/Header.scss";
import HeaderNavLink from './HeaderNavLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from 'react-i18next';
import { useState } from 'react';
import ProfileBadge from '../profile/ProfileBadge';

const Header = () => {
    const currentPath = window.location.pathname;

    const [mobileNavbarExpanded, setMobileNavbarExpanded] = useState(false);

    function renderCartBadge() {
        return (
            <CartCountBadge />
        );
    }

    return (
        <div>
            <div className="d-none d-md-block">
                <Navbar expand="md" className="bg-light">
                    <Container>
                        <Navbar.Brand href="/">
                            <img className="logo rounded" src="/logo-big.png" alt="FeetApp logo" />
                        </Navbar.Brand>

                        <Navbar.Toggle aria-controls="navbar-main" />

                        <Navbar.Collapse id="navbar-main">
                            <Nav className="me-auto">
                                <HeaderNavLink href="/measurements" title={t("Measurements")} active={currentPath.startsWith("/measurements")} direction='horizontal' />
                                <HeaderNavLink href="/catalog" title={t("Fitting")} active={currentPath.startsWith("/catalog")} direction='horizontal' />
                                <HeaderNavLink href="/professions" title={t("Professions")} active={currentPath.startsWith("/professions")} direction='horizontal' />
                            </Nav>

                            <Nav>
                                <HeaderNavLink href="/applications" title={t("Applications")} active={currentPath.startsWith("/applications")} icon={faQrcode} />
                                <HeaderNavLink href="/cart" title={t("Shopping list")} active={currentPath.startsWith("/cart")} icon={faShoppingBag} renderBadge={renderCartBadge} />
                                <HeaderNavLink href="/profile" title={t("Profile")} active={currentPath.startsWith("/profile")} icon={faUser} renderBadge={() => <ProfileBadge />} />
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>

            <div className='d-block d-md-none'>
                <Navbar expand="md" className="bg-light" onToggle={setMobileNavbarExpanded}>
                    <Container>
                        <Navbar.Brand href="/">
                            <img className="logo rounded" src="/logo-big.png" alt="FeetApp logo" />
                        </Navbar.Brand>

                        <Navbar.Toggle aria-controls="navbar-main" />

                        <Navbar.Collapse id="navbar-main">
                            <Nav className="me-auto">
                                <Nav.Link href="/measurements" active={currentPath.startsWith("/measurements")}>
                                    <FontAwesomeIcon icon={faIdCard} /> <Trans>Measurements</Trans>
                                </Nav.Link>
                                <Nav.Link href="/catalog" active={currentPath.startsWith("/catalog")}>
                                    <FontAwesomeIcon icon={faShoePrints} /> <Trans>Fitting</Trans>
                                </Nav.Link>
                                <Nav.Link href="/professions" active={currentPath.startsWith("/professions")}>
                                    <FontAwesomeIcon icon={faUserGear} /> <Trans>Professions</Trans>
                                </Nav.Link>
                                <Nav.Link href="/applications" active={currentPath.startsWith("/applications")}>
                                    <FontAwesomeIcon icon={faQrcode} /> <Trans>Applications</Trans>
                                </Nav.Link>
                                <Nav.Link href="/cart" active={currentPath.startsWith("/cart")}>
                                    <FontAwesomeIcon icon={faShoppingBag} /> <Trans>Shopping list</Trans> <CartCountBadge />
                                </Nav.Link>
                                <Nav.Link href="/profile" active={currentPath.startsWith("/profile")}>
                                    <FontAwesomeIcon icon={faUser} /> <Trans>Profile</Trans>
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>

                        {!mobileNavbarExpanded && (
                            <Nav className='ms-auto'>
                                <Nav.Link href="/cart" active={currentPath.startsWith("/cart")} className='fs-5'>
                                    <FontAwesomeIcon icon={faShoppingBag} /> <CartCountBadge />
                                </Nav.Link>
                            </Nav>
                        )}
                    </Container>
                </Navbar>
            </div>

            <NotificationContainer />
        </div>
    );
};

export default Header;
