class EmployeeApi {
    constructor(sessionId) {
        this.sessionId = sessionId;
    }

    ready() {
        return !!this.sessionId;
    }

    async addEmployee(employee) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/add_employee';

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(employee),
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data;
    }

    async getEmployee(employeeId) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/get_employee';
        const params = new URLSearchParams({ employee_id: employeeId });

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data;
    }

    async getEmployees(queryStr) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/get_employees';
        
        const params = new URLSearchParams();
        if (queryStr) {
            params.append('query_str', queryStr);
        }

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data;
    }

    async updateEmployee(employee) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/update_employee';

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }        

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(employee),
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data;
    }

    async addEmployeeMeasurement(employeeId, measurementId) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/add_employee_measurement';

        let formData = new FormData();
        formData.append('employee_id', employeeId);
        formData.append('measurement_id', measurementId);

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: formData,
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }

    async deleteEmployeeMeasurement(employeeId, measurementId) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/delete_employee_measurement';

        let formData = new FormData();
        formData.append('employee_id', employeeId);
        formData.append('measurement_id', measurementId);

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: formData,
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }

    async getEmployeesMeasurements() {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/get_employees_measurements';
        
        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data;
    }

    async getEmployeeByMeasurement(measurementId) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/get_employee_by_measurement';
        const params = new URLSearchParams({ measurement_id: measurementId });

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (response.status !== 200 && response.status !== 404) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        if (response.status === 200) {
            const data = await response.json();
            return data;
        } else {
            return null;
        }
    }

    async deleteEmployee(employeeId) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/delete_employee';
        const params = new URLSearchParams({ employee_id: employeeId });

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }

    async getDepartments() {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/get_departments';
        
        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data;
    }

    async importEmployees(xlsxFile, replace) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/import_employees';
        
        const formData = new FormData();
        formData.append('employees_xlsx', xlsxFile);
        formData.append('replace', replace ? 'true' : 'false');

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: formData,
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data;
    }

    async exportEmployees(genExampleData) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/export_employees';

        const params = new URLSearchParams();
        if (genExampleData) {
            params.append('gen_example_data', genExampleData);
        }

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.blob();
        return data;
    }
}

export default EmployeeApi;