import { Badge, Button, Carousel, Col, Container, Form, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import { getPropectiveProperties, getShoeDetails, getShoeFitnessText, getShoeInsoles, getTypeBrandNameStr, isShoeOutOfStock } from "../../app/shoeModel";
import AddToCartBlock from "./AddToCartBlock";
import CurrencyFormatter from "../global/CurrencyFormatter";
import FitnessEstimateElement from "./FitnessEstimateElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare, faCopy, faSquare } from "@fortawesome/free-solid-svg-icons";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { CSS_COLORS, getCssColorName } from "../../utils/cssColors";
import "../../styles/ShoeCardDetails.scss";
import AddToComparisionBlock from "./AddToComparisionBlock";
import ShoeVariantCard from "./ShoeVariantCard";
import ReactPlayer from "react-player";

const ShoeDetails = ({ item, measurementId, humanName, selectedSize, setSelectedSize, selectedInsole, setSelectedInsole }) => {
    const protectiveProperties = useMemo(() => getPropectiveProperties(item), [item]);
    const insoles = useMemo(() => getShoeInsoles(item), [item]);
    const isOutOfStock = useMemo(() => isShoeOutOfStock(item), [item]);

    const CAROUSEL_INTERVAL = 5000;
    const [carouselInterval, setCarouselInterval] = useState(CAROUSEL_INTERVAL);
    const [carouselIndex, setCarouselIndex] = useState(0);

    const carouselVideoIndeces = useMemo(() => {
        if (!item.shortVideo) {
            return [];
        }

        return item.shortVideo.map((video, index) => {
            return index;
        });
    }, [item.shortVideo]);

    function isCatalogLink(link) {
        return link.startsWith(process.env.REACT_APP_CATALOG_URL);
    }

    function isWildberriesLink(link) {
        return link.startsWith(process.env.REACT_APP_WILDBERRIES_URL);
    }

    function getCatalogLink(item) {
        if (typeof (item?.externalLinks) !== "object") {
            return null;
        }

        const catalogLink = item.externalLinks.find(link => isCatalogLink(link));
        if (!catalogLink) {
            return null;
        }

        return catalogLink;
    }

    function getWildberriesLink(item) {
        if (typeof (item?.externalLinks) !== "object") {
            return null;
        }

        const wildberriesLink = item.externalLinks.find(link => isWildberriesLink(link));
        if (!wildberriesLink) {
            return null;
        }

        return wildberriesLink;
    }

    function getExtraLinks(item) {
        if (typeof (item?.externalLinks) !== "object") {
            return [];
        }

        return item.externalLinks.filter(link => !isCatalogLink(link) && !isWildberriesLink(link));
    }

    function copyFitnessText(item) {
        const text = getShoeFitnessText(item, humanName);
        navigator.clipboard.writeText(text);
        showNotification(t("Copied to clipboard"), "success");
    }

    useEffect(() => {
        if (!selectedSize && item?.fitnessEstimate?.size && item?.availableSize?.includes(item.fitnessEstimate.size)) {
            setSelectedSize(item.fitnessEstimate.size);
        }

        if (!selectedInsole && insoles.length > 0) {
            if (item.fitnessEstimate?.insole?.id && insoles.find(insole => insole.id === item.fitnessEstimate.insole.id)) {
                setSelectedInsole(item.fitnessEstimate.insole);
            } else {
                setSelectedInsole(insoles[0]);
            }
        }
    }, [insoles, item, selectedInsole, selectedSize, setSelectedInsole, setSelectedSize]);

    useEffect(() => {
        if (carouselVideoIndeces.includes(carouselIndex)) {
            setCarouselInterval(null);
        } else {
            setCarouselInterval(CAROUSEL_INTERVAL);
        }
    }, [carouselIndex, carouselVideoIndeces]);

    return (
        <>
            <h3 className="mt-3">{getTypeBrandNameStr(item)}</h3>

            {/* Image and main details */}
            <Row className="mt-3">
                <Col lg={6}>
                    <Carousel
                        interval={carouselInterval}
                        onSelect={index => setCarouselIndex(index)}>
                        {/* Video */}
                        {item.shortVideo && item.shortVideo.map((video, index) => (
                            <Carousel.Item key={video}>
                                <div className="shoe-details">
                                    <ReactPlayer
                                        muted
                                        playing={index === carouselIndex}
                                        url={video}
                                        width="100%"
                                        height={"100%"}
                                        onEnded={() => setCarouselInterval(500)} />
                                </div>
                            </Carousel.Item>
                        )
                        )}

                        {/* Images */}
                        {item.imagesFixed.map(image => (
                            <Carousel.Item key={image}>
                                <img
                                    className="shoe-details"
                                    src={image}
                                    alt={item.name}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
                <Col lg={6}>
                    <Container fluid className="p-3 shadow rounded">
                        {/* Price */}
                        <div className="mt-3">
                            <span className="fw-bold fs-3">
                                <CurrencyFormatter amount={item.price} />
                            </span>
                        </div>

                        {/* Out of stock */}
                        {isOutOfStock && (
                            <div className="mt-3">
                                <span className="fs-4"><Badge bg="secondary" ><Trans>outOfStock</Trans></Badge></span>
                            </div>
                        )}

                        {/* Alternate variants */}
                        {typeof (item.relatedShoes) === "object" && item.relatedShoes.length >= 2 && (
                            <div className="mt-3 d-flex justify-content-start">
                                {item.relatedShoes.map(relatedShoeID => (
                                    <ShoeVariantCard key={relatedShoeID} modelId={relatedShoeID} measurementId={measurementId} humanName={humanName} selected={item.modelId === relatedShoeID} />
                                ))}
                            </div>
                        )}

                        <Row>
                            <Col xs={12} lg={6}>
                                {/* Add to cart */}
                                <div className="mt-3 d-flex justify-content-start">
                                    <AddToCartBlock item={item} humanName={humanName} selectedSize={selectedSize} setSelectedSize={setSelectedSize} selectedInsole={selectedInsole} disabled={isOutOfStock} />
                                </div>

                                {/* Insoles */}
                                {insoles.length > 0 && (
                                    <div className="mt-3 d-flex justify-content-start">
                                        <div><Trans>Insole</Trans></div>
                                        <div>
                                            <Form className="ms-3">
                                                {insoles.map((insole) => (
                                                    <Form.Check
                                                        key={insole.id}
                                                        type="radio"
                                                        id={insole.id}
                                                        label={insole.name + (item?.fitnessEstimate?.insole?.id === insole.id ? " ★" : "")}
                                                        checked={selectedInsole?.id === insole.id}
                                                        onChange={() => setSelectedInsole(insole)}
                                                        name="selected-insole"
                                                    />
                                                ))}
                                            </Form>
                                        </div>
                                    </div>
                                )}
                            </Col>

                            <Col xs={12} lg={6}>
                                {/* Comparision block */}
                                <div className="mt-3">
                                    <AddToComparisionBlock item={item} humanName={humanName} selectedSize={selectedSize} selectedInsole={selectedInsole} />
                                </div>
                            </Col>
                        </Row>

                        {/* Main details */}
                        <div className="mt-3">
                            {getShoeDetails(item, true).map((detail) => (
                                <Row key={detail.key}>
                                    <Col><span className="text-muted">{detail.key}</span></Col>
                                    <Col>{detail.value}</Col>
                                </Row>
                            ))}
                        </div>

                        {/* Catalog & buy buttons */}
                        <div className="mt-3 d-flex justify-content-center">
                            {getCatalogLink(item) && (
                                <a href={getCatalogLink(item)} target="_blank" rel="noopener noreferrer" className="btn btn-secondary mx-1">
                                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="me-1" /><Trans>Go to catalog</Trans>
                                </a>
                            )}
                            {getWildberriesLink(item) && (
                                <a href={getWildberriesLink(item)} target="_blank" rel="noopener noreferrer" className="btn btn-dark mx-1" style={{ backgroundColor: "#CA11AB", borderColor: "#CA11AB" }}>
                                    <img src="/wildberries.png" alt="Wildberries" className="img-inline rounded me-1" /><Trans>Buy on Wildberries</Trans>
                                </a>
                            )}
                        </div>
                    </Container>
                </Col>
            </Row>

            {/* Fitness estimate */}
            {item.fitnessEstimate && (
                <div className="mt-3 p-3 shadow-sm rounded bg-light">
                    <div className="float-end"><Button variant="light" size="sm" onClick={() => copyFitnessText(item)}><FontAwesomeIcon icon={faCopy} /></Button></div>

                    <h3 className=""><Trans>Fitness estimate</Trans>{humanName && <span> <Trans>for</Trans> <b>{humanName}</b></span>}</h3>

                    <FitnessEstimateElement fitnessEstimate={item.fitnessEstimate} />
                </div>
            )}

            {/* Description */}
            {item.shortDescription && (
                <>
                    <h3 className="mt-3"><Trans>Description</Trans></h3>
                    <p className="mt-2 keep-newlines">{item.shortDescription}</p>
                </>
            )}

            {/* Video */}
            {item.video && (
                <>
                    <h3 className="mt-3"><Trans>shoeDetailsVideoTitrle</Trans></h3>

                    <div className="mt-3">
                    {item.video.map((video) => (
                        <div className="page-video" key={video}>
                            <ReactPlayer
                                url={video}
                                controls
                                width="100%"
                                height="100%"
                            />
                        </div>
                    ))}
                    </div>
                </>
            )}

            {/* Details */}
            <Row className="mt-3">
                <Col>
                    <h3 className="mt-3"><Trans>Details</Trans></h3>
                </Col>
            </Row>

            <Row className="mt-3">
                {getShoeDetails(item, false, true).map((detail) => [
                    <Col xs={6} lg={3} key={detail.key}>
                        <span className="text-muted">{detail.key}</span>
                    </Col>,
                    <Col xs={6} lg={3} key={detail.key + "_value"}>
                        {detail.isCssColor ? (
                            <span>
                                {getCssColorName(detail.value)} <FontAwesomeIcon className="mx-2" icon={faSquare} style={{ color: CSS_COLORS[detail.value] }} />
                            </span>
                        ) : (
                            <span>{detail.value}</span>
                        )}
                    </Col>
                ]).flat()}
            </Row>

            {/* Propective properties */}
            {protectiveProperties.length > 0 && (
                <>
                    <Row className="mt-3">
                        <Col>
                            <h3 className="mt-3"><Trans>Protective properties</Trans></h3>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        {protectiveProperties.map((property) => [
                            <Col xs={2} lg={1} key={property.code}>
                                <Badge className="border" bg="light">{property.code}</Badge>
                            </Col>,
                            <Col xs={10} lg={5} key={property.code + "_desc"}>
                                {property.description}
                            </Col>
                        ]).flat()}
                    </Row>
                </>
            )}

            {/* External links */}
            {getExtraLinks(item).length > 0 && (
                <>
                    <Row className="mt-3">
                        <Col>
                            <h3 className="mt-3"><Trans>Additional links</Trans></h3>
                        </Col>
                    </Row>

                    <ul>
                        {getExtraLinks(item).map((link) => (
                            <li key={link}>
                                <div className="text-truncate">
                                    <a href={link} target="_blank" rel="noreferrer">{link}</a>
                                </div>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </>
    );
};

export default ShoeDetails;
