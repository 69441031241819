import { useCallback, useMemo, useState } from "react";
import { Badge, Button, Container } from "react-bootstrap";
import { Trans } from "react-i18next";
import RiskFactorEdit from "./RiskFactorEdit";
import RiskFactorDetails from "./RiskFactorDetails";
import { parseProtPropertiesStr } from "../../utils/protPropertiesStr";

function RiskFactorTableRow({ riskFactor, allProtProperties, onSave, onDelete }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const parsedProtProperties = useMemo(() => parseProtPropertiesStr(riskFactor.prot_properties_str), [riskFactor]);

    const getPropertyName = useCallback((codeName) => {
        if (!allProtProperties) {
            return null;
        }

        return allProtProperties.filter((prop) => prop.codeName === codeName)[0]?.name;
    }, [allProtProperties]);

    return [
        <tr key={riskFactor.id}>
            <td><Badge>{riskFactor.code_name}</Badge> {riskFactor.name}</td>
            <td className="d-none d-md-table-cell">
                {allProtProperties ? (
                    parsedProtProperties.map((propVariants) => (
                        <div>
                            {propVariants.map((prop, index) => (
                                <div>
                                    {index > 0 && <span className="ms-2"><Trans>riskFactorProtPropertyOr</Trans></span>} <Badge color="secondary" className="me-1">{prop}</Badge> {getPropertyName(prop) || <></>}
                                </div>
                            ))}
                        </div>
                    ))
                ) : (
                    <span>{riskFactor.prot_properties_str}</span>
                )}
            </td>
            <td>
                <Button
                    variant="link"
                    size="sm"
                    onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
                >{isExpanded ? <Trans>riskFactorTableHide</Trans> : <Trans>riskFactorTableShow</Trans>}</Button>
            </td>
        </tr>,
        isExpanded && (
            <tr key={`${riskFactor.id}-details`}>
                <td colSpan={3}>
                    <Container>
                        {isEdit ? (
                            <RiskFactorEdit
                                riskFactor={riskFactor}
                                onSave={onSave}
                                onDelete={onDelete}
                                onCancel={() => setIsEdit(false)}
                            />
                        ) : (
                            <RiskFactorDetails
                                riskFactor={riskFactor}
                                onEdit={() => setIsEdit(true)}
                                onDelete={onDelete} />
                        )}
                    </Container>
                </td>
            </tr>
        )
    ];
}

export default RiskFactorTableRow;
