import { t } from "i18next";
import { lazy, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import Header from "../components/global/Header";
import Loader from "../components/global/Loader";
import { Trans } from "react-i18next";
import Footer from "../components/global/Footer";
import { Container } from "react-bootstrap";

const ConnectButton = lazy(() => import("../components/crypto/ConnectButton"));
const WalletWrapper = lazy(() => import("../components/crypto/WalletWrapper"));
const WalletMeasurements = lazy(() => import("../components/crypto/WalletMeasurements"));

function WalletMeasurementsView() {
    return (
        <>
            <Helmet>
                <title>{t("walletMeasuremensPageTitle")} - FeetApp</title>
            </Helmet>

            <Header />

            <Container>
                <Suspense fallback={<Loader />}>
                    <WalletWrapper>
                        <div className="my-3 d-flex justify-content-between">
                            <h3><Trans>NFT measurements</Trans></h3>
                            <ConnectButton />
                        </div>

                        <WalletMeasurements />
                    </WalletWrapper>
                </Suspense>
            </Container>

            <Footer />
        </>
    );
}

export default WalletMeasurementsView;
