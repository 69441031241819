import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useCallback, useState } from "react";
import { Button, Form, FormGroup, InputGroup } from "react-bootstrap";
import { Trans } from "react-i18next";
import { showNotification } from "../../utils/notification";
import { faArrowCircleRight, faCheck } from "@fortawesome/free-solid-svg-icons";

function ProfileChangeEmail({ clientApi, email, handleEmailChanged }) {
    const [newEmail, setNewEmail] = useState(email);

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState('');

    const confirmEmail = useCallback(async () => {
        try {
            if (confirmationCode === '') {
                await clientApi.changeEmail({ email: newEmail });
                setConfirmationOpen(true);
                showNotification(t("profileEmailConfirmationSent"), 'success');
            } else {
                await clientApi.changeEmail({ code: confirmationCode, email: newEmail });
                setConfirmationOpen(false);
                showNotification(t("profileEmailConfirmed"), 'success');
                handleEmailChanged();
            }
        } catch (error) {
            console.error(error);
            showNotification(t("profileEmailConfirmError") + ": " + error.message, 'danger');
        }
    }, [clientApi, confirmationCode, handleEmailChanged, newEmail]);

    return (
        <FormGroup className="mt-3">
            <Form.Label><Trans>profileEmail</Trans></Form.Label>

            <InputGroup>
                <Form.Control
                    type="email"
                    placeholder={t("profileEmailInput")}
                    value={newEmail}
                    onChange={e => setNewEmail(e.target.value)}
                />

                {!confirmationOpen && (
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => confirmEmail()}
                    >
                        <Trans>profileEmailSendCode</Trans>
                    </Button>
                )}
            </InputGroup>

            {confirmationOpen && (
                <div className="d-flex justify-content-end mt-3">
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faArrowCircleRight} className="" />

                        <Form.Control
                            type="text"
                            placeholder={t("profileEmailConfirmationCodePlaceholder")}
                            className="ms-2"
                            value={confirmationCode}
                            onChange={e => setConfirmationCode(e.target.value)}
                        />

                        <Button
                            variant="primary"
                            size="sm"
                            className="ms-2"
                            onClick={() => confirmEmail(confirmationCode)}
                        >
                            <Trans>profileEmailConfirm</Trans>
                        </Button>
                    </div>
                </div>
            )}
        </FormGroup>
    )
}

export default ProfileChangeEmail;
