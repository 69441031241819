import { t } from "i18next";
import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { useLocation, useSearchParams } from "react-router-dom";
import { apiGetShoeModel } from "../app/catalogApi";
import Header from "../components/global/Header";
import Loader from "../components/global/Loader";
import ShoeDetails from "../components/catalog/ShoeDetails";
import { showNotification } from "../utils/notification";
import { Breadcrumb, Container } from "react-bootstrap";
import Footer from "../components/global/Footer";
import { apiGetMeasurement } from "../app/measurementApi";
import { getShoeInsoles, getTypeBrandNameStr } from "../app/shoeModel";

const ShoeView = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [item, setItem] = useState();

    const [measurementId, setMeasurementId] = useState();
    const [selectedSize, setSelectedSize] = useState(null);
    const [selectedInsole, setSelectedInsole] = useState(null);
    const [humanName, setHumanName] = useState();

    useEffect(() => {
        // If location state is passed, set data from state
        if (location.state?.item) {
            setIsLoading(false);
            setItem(location.state?.item);

            if (location.state?.selectedSize) {
                setSelectedSize(location.state?.selectedSize);
            } else if (location.state?.item?.fitnessEstimate?.size && location.state.item?.availableSize?.includes(location.state.item.fitnessEstimate.size)) {
                setSelectedSize(location.state.item.fitnessEstimate.size);
            } else {
                setSelectedSize(location.state?.item.availableSize[0]);
            }

            if (location.state?.insoleId) {
                const insoles = getShoeInsoles(location.state.item);
                setSelectedInsole(insoles.find(insole => insole.id === location.state.insoleId));
            }

            if (location.state?.measurementId) {
                setMeasurementId(location.state.measurementId);
            }
            if (location.state?.humanName) {
                setHumanName(location.state.humanName);
            }
        } else if (searchParams.get('modelId')) {
            const modelId = searchParams.get('modelId');
            const measurementId = searchParams.get('measurementId');
            const selectedSize = searchParams.get('selectedSize');
            const insoleId = searchParams.get('insoleId');

            apiGetShoeModel(modelId, measurementId)
                .then(data => {
                    setIsLoading(false);
                    setItem(data);

                    if (selectedSize) {
                        setSelectedSize(selectedSize);
                    } else if (data?.fitnessEstimate?.size && data?.availableSize?.includes(data.fitnessEstimate.size)) {
                        setSelectedSize(data.fitnessEstimate.size);
                    } else {
                        setSelectedSize(data.availableSize[0]);
                    }

                    if (insoleId) {
                        const insoles = getShoeInsoles(data);
                        setSelectedInsole(insoles.find(insole => insole.id === insoleId));
                    }
                })
                .catch(err => {
                    console.log(err);
                    showNotification(t("Error loading shoe model") + ": " + err, 'danger');
                    setIsLoading(false);
                });

            if (measurementId) {
                apiGetMeasurement(measurementId)
                    .then(data => {                    
                        setMeasurementId(measurementId);
                        
                        const measurement = data;
                        if (measurement?.humanInfo?.name) {
                            setHumanName(measurement.humanInfo.name);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        showNotification(t("Error loading measurement") + ": " + err, 'danger');
                    });
            }
        } else {
            setIsLoading(false);
        }
    }, [location.state, searchParams]);

    return (
        <>
            <Helmet>
                <title>{item?.name ? item?.name + " - " + t("FeetApp") : t("FeetApp")}</title>
            </Helmet>

            <Header />

            {item && (
                <Container>
                    <Breadcrumb className="mt-3">
                        <Breadcrumb.Item href="/catalog">{t("Catalog")}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{getTypeBrandNameStr(item)}</Breadcrumb.Item>
                    </Breadcrumb>

                    <ShoeDetails item={item} measurementId={measurementId} humanName={humanName} selectedSize={selectedSize} setSelectedSize={setSelectedSize} selectedInsole={selectedInsole} setSelectedInsole={setSelectedInsole} />
                </Container>
            )}

            {isLoading && <Loader />}

            <Footer />
        </>
    );
};

export default ShoeView;
