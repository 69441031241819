import { t } from "i18next";

function getInformativeMeasurements(measurements) {
    // NOTE: allow non-finished measurements, so measurements should be filtered on request
    return measurements?.filter(m => m.left || m.right || m?.humanInfo?.name);
}

function getMeasurementName(measurement) {
    return measurement?.humanInfo?.name ? measurement.humanInfo.name : t("Measurement") + " " + measurement.measurementId;
}

function makeShareLink(measurement) {
    return window.location.protocol + "//" + window.location.host + "/share/measurements/" + measurement.measurementId;
}

export { getInformativeMeasurements, getMeasurementName, makeShareLink };
