import { t } from "i18next";
import { useCallback, useMemo, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Trans } from "react-i18next";
import { showNotification } from "../../utils/notification";
import HCaptcha from "@hcaptcha/react-hcaptcha";

function LoginRegister({ clientApi, handleRegData }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeated, setPasswordRepeated] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');

    const [phone, setPhone] = useState('');

    const [captchaToken, setCaptchaToken] = useState('');
    const captchaRef = useRef(null);

    const [registration_code, setRegistrationCode] = useState('');
    const [codeSent, setCodeSent] = useState(false);

    const codeSendEnabled = useMemo(() => {
        return email.length > 0
            && captchaToken.length > 0;
    }, [email.length, captchaToken.length]);

    const registrationEnabled = useMemo(() => {
        // All fields should be filled
        return email.length > 0
            && password.length > 0
            && password === passwordRepeated
            && firstName.length > 0
            && lastName.length > 0
            && companyName.length > 0
            && phone.length > 0
            && password === passwordRepeated
            && registration_code.length > 0;
    }, [password, passwordRepeated, firstName.length, lastName.length, companyName.length, email.length, phone.length, registration_code.length]);

    const execCaptcha = useCallback(() => {
        // captchaRef.current.execute({ render: 'explicit' });
    }, []);

    const sendCode = useCallback(async () => {
        try {
            await clientApi.passwordRegisterAskCode(email, captchaToken);
            showNotification(t("registrationCodeSent"), "success");
            setCodeSent(true);
        } catch (e) {
            console.error(e);
            showNotification(t("registrationCodeSendError") + ": " + e.message, "danger");
        }

        // Reset captcha
        setCaptchaToken("");
        captchaRef.current.resetCaptcha();
    }, [clientApi, email, captchaToken]);

    return (
        <Form>
            <Form.Group className="mb-3">
                <Form.Label><Trans>registerEmail</Trans></Form.Label>
                <Form.Control
                    type="email"
                    placeholder={t("emailPlaceholder")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label><Trans>registerPassword</Trans></Form.Label>
                <Form.Control
                    type="password"
                    placeholder={t("registerPasswordPlaceholder")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label><Trans>registerPasswordRepeated</Trans></Form.Label>
                <Form.Control
                    type="password"
                    placeholder={t("registerPasswordRepeatedPlaceholder")}
                    value={passwordRepeated}
                    onChange={(e) => setPasswordRepeated(e.target.value)}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label><Trans>firstName</Trans></Form.Label>
                <Form.Control
                    type="text"
                    placeholder={t("firstNamePlaceholder")}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label><Trans>lastName</Trans></Form.Label>
                <Form.Control
                    type="text"
                    placeholder={t("lastNamePlaceholder")}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label><Trans>companyName</Trans></Form.Label>
                <Form.Control
                    type="text"
                    placeholder={t("companyNamePlaceholder")}
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label><Trans>phone</Trans></Form.Label>
                <Form.Control
                    type="text"
                    placeholder={t("phonePlaceholder")}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
            </Form.Group>

            {!codeSent ? (
                <div>
                    <HCaptcha
                        sitekey={process.env.REACT_APP_HCAPTCHA_SITEKEY}
                        onLoad={execCaptcha}
                        onVerify={setCaptchaToken}
                        ref={captchaRef}
                    />

                    <Button
                        variant="secondary"
                        onClick={sendCode}
                        disabled={!codeSendEnabled}
                    >
                        {t("sendCode")}
                    </Button>
                </div>
            ) : (
                <div>
                    <Form.Group className="mb-3">
                        <Form.Label><Trans>registrationCode</Trans></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t("registrationCodePlaceholder")}
                            value={registration_code}
                            onChange={(e) => setRegistrationCode(e.target.value)}
                        />
                    </Form.Group>

                    <Button
                        variant="primary"
                        onClick={() => handleRegData({
                            email,
                            password,
                            password_repeated: passwordRepeated,
                            first_name: firstName,
                            last_name: lastName,
                            company_name: companyName,
                            phone,
                            registration_code
                        })}
                        disabled={!registrationEnabled}
                    >
                        {t("register")}
                    </Button>
                </div>
            )}
        </Form>
    );
}

export default LoginRegister;
