import { useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";

import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "react-i18next";
import FiltersForm from "./FiltersForm";

const FiltersButton = ({shoeProperties, selected, setSelected, defaultSelected }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                <FontAwesomeIcon icon={faFilter} /> <Trans>Filters</Trans>
            </Button>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <Trans>Filters</Trans>
                    </Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <FiltersForm shoeProperties={shoeProperties} selected={selected} setSelected={setSelected} defaultSelected={defaultSelected} />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
};

export default FiltersButton;
