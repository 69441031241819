import { useCallback, useEffect, useState } from "react";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import { Button, Form } from "react-bootstrap";

function ProfileChangePassword({ clientApi, handlePasswordChanged }) {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRepeated, setNewPasswordRepeated] = useState('');

    const [changeEnabled, setChangeEnabled] = useState(false);

    const changePassword = useCallback(async () => {
        if (newPassword !== newPasswordRepeated) {
            showNotification(t('passwordChangeMismatch'), 'danger');
            return;
        }
        try {
            await clientApi.changePassword(oldPassword, newPassword, newPasswordRepeated);
            setOldPassword('');
            setNewPassword('');
            setNewPasswordRepeated('');
            showNotification(t('passwordChangeSuccess'), 'success');
            handlePasswordChanged();
        } catch (error) {
            console.error(error);
            showNotification(t('passwordChangeError') + ': ' + error.message, 'danger');
        }
    }, [newPassword, newPasswordRepeated, clientApi, oldPassword, handlePasswordChanged]);

    useEffect(() => {
        setChangeEnabled(oldPassword !== '' && newPassword !== '' && newPasswordRepeated !== '' && newPassword === newPasswordRepeated);
    }, [oldPassword, newPassword, newPasswordRepeated]);

    return (
        <Form>
            <Form.Group className="mt-3">
                <Form.Label>{t('oldPassword')}</Form.Label>
                <Form.Control
                    type="password"
                    value={oldPassword}
                    onChange={e => setOldPassword(e.target.value)}
                />
            </Form.Group>

            <Form.Group className="mt-3">
                <Form.Label>{t('newPassword')}</Form.Label>
                <Form.Control
                    type="password"
                    value={newPassword}
                    onChange={e => setNewPassword(e.target.value)}
                />
            </Form.Group>

            <Form.Group className="mt-3">
                <Form.Label>{t('newPasswordRepeat')}</Form.Label>
                <Form.Control
                    type="password"
                    value={newPasswordRepeated}
                    onChange={e => setNewPasswordRepeated(e.target.value)}
                />
            </Form.Group>

            <Button className="mt-3" variant="primary" onClick={changePassword} disabled={!changeEnabled}>{t('changePassword')}</Button>
        </Form>
    );
}

export default ProfileChangePassword;
