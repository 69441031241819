import { Badge, Form, FormControl, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap"
import { CSS_COLORS, getCssColorName } from "../../utils/cssColors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSquare } from "@fortawesome/free-solid-svg-icons";
import { parseCodeDescriptionString } from "../../utils/codeDescriptionString";
import { useMemo, useState } from "react";
import { t } from "i18next";

const CssColorLabel = ({ option }) => {
    return (
        <Form.Check.Label htmlFor={option}>
            <FontAwesomeIcon className="mx-2" icon={faSquare} style={{ color: CSS_COLORS[option] }} /> {getCssColorName(option)}
        </Form.Check.Label>
    )
};

const CodeDescriptionLabel = ({ option }) => {
    const { code, description } = parseCodeDescriptionString(option);
    return (
        <>
            <Form.Check.Label htmlFor={option}> </Form.Check.Label>

            <OverlayTrigger
                overlay={
                    <Tooltip>
                        {description}
                    </Tooltip>
                }
                placement="top"
                trigger="click"
                rootClose
            >
                <Badge className="mx-2 border" bg="light">{code}</Badge>
            </OverlayTrigger>
        </>
    )
};

const OptionFilterItem = ({ option, selected, setSelected, isCssColor, isCodeDescription }) => {
    return (
        <Form.Check key={option}>
            <Form.Check.Input
                id={option}
                type="checkbox"
                checked={selected.includes(option)}
                onChange={() => setSelected(selected.includes(option) ?
                    selected.filter(item => item !== option) :
                    [...selected, option])}
            />
            {isCssColor ? <CssColorLabel option={option} /> : isCodeDescription ? <CodeDescriptionLabel option={option} /> : <Form.Check.Label htmlFor={option}>{option}</Form.Check.Label>}
        </Form.Check>
    );
};

const OptionFilter = ({ options, selected, setSelected, isCssColor, isCodeDescription }) => {
    const [filterText, setFilterText] = useState("");
    const filteredOptions = useMemo(() => {
        if (!filterText) {
            return options;
        }
        return options.filter(option => option.toLowerCase().includes(filterText.toLowerCase()));
    }, [filterText, options]);

    return (
        <>
            {/* Input filter */}
            {options.length > 10 && (
                <InputGroup className="mb-3 text-sm">
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <FormControl
                        placeholder={t("Filter options")}
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                    />
                </InputGroup>
            )}

            <div style={{ maxHeight: options.length > 10 ? "300px" : "auto", overflowY: options.length > 10 ? "auto" : "hidden" }}>
                {filteredOptions.map(option => <OptionFilterItem key={option} option={option} selected={selected} setSelected={setSelected} isCssColor={isCssColor} isCodeDescription={isCodeDescription} />)}
            </div>
        </>
    );
};

export default OptionFilter;
