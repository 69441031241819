import { t } from "i18next";
import { NotificationManager } from "react-notifications";

const showNotification = (message, type) => {
    switch (type) {
        case 'success':
            NotificationManager.success(message, '', 3000);
            break;
        case 'warning':
            NotificationManager.warning(message, t("Warning"), 5000);
            break;
        case 'danger':
            NotificationManager.error(message, t("Error"), 5000);
            break;
        default:
            NotificationManager.info(message, '', 3000);
            break;
    }
};

export { showNotification };
