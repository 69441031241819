import { useMemo } from "react";
import useLocalStorageState from "use-local-storage-state";
import ProfessionApi from "./professionApi";

function useProfessionApi() {
    const [sessionId, ] = useLocalStorageState("sessionId", null);
    return useMemo(() => new ProfessionApi(sessionId), [sessionId]);
}

export default useProfessionApi;
