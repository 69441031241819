import { useMemo } from "react";
import useLocalStorageState from "use-local-storage-state";
import ClientMeasurementApi from "./clientMeasurementApi";

function useClientMeasurementApi() {
    const [sessionId, ] = useLocalStorageState("sessionId", null);
    return useMemo(() => new ClientMeasurementApi(sessionId), [sessionId]);
}

export default useClientMeasurementApi;
