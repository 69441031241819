import { faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Overlay, Popover, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import { showNotification } from "../../utils/notification";
import ProtPropertySelect from "./ProtPropertySelect";
import Loader from "../global/Loader";
import useProfessionApi from "../../app/useProfessionApi";
import { buildProtPropertiesStr, parseProtPropertiesStr } from "../../utils/protPropertiesStr";

function RiskFactorEdit({ riskFactor, onSave, onDelete, onCancel }) {
    const professionApi = useProfessionApi();

    const [name, setName] = useState(riskFactor?.name || "");
    const [codeName, setCodeName] = useState(riskFactor?.code_name || "");
    const [protPropertiesStr, setProtPropertiesStr] = useState(riskFactor?.prot_properties_str || "");

    const [allProtProperties, setAllProtProperties] = useState(null);

    const [showProtPropertiesAdd, setShowProtPropertiesAdd] = useState(false);
    const protPropertiesAddTarget = useRef(null);

    const [showProtPropertiesVariant, setShowProtPropertiesVariant] = useState(false);
    const protPropertiesVariantTarget = useRef(null);

    const reset = useCallback(() => {
        setName(riskFactor.name);
        setCodeName(riskFactor.code_name);
        setProtPropertiesStr(riskFactor.prot_properties_str);
    }, [riskFactor]);

    const clear = useCallback(() => {
        setName("");
        setCodeName("");
        setProtPropertiesStr("");
    }, []);

    const onCancelClick = useCallback(() => {
        if (riskFactor.id) {
            reset();
        } else {
            clear();
        }

        onCancel();
    }, [riskFactor, onCancel, reset, clear]);

    const onSaveClick = useCallback(() => {
        onSave({
            id: riskFactor.id,
            name,
            code_name: codeName,
            prot_properties_str: protPropertiesStr
        });

        onCancel();
    }, [onSave, riskFactor, name, codeName, protPropertiesStr, onCancel]);

    const selectedProtProperties = useMemo(() => {
        const props = parseProtPropertiesStr(protPropertiesStr);
        const flattenProps = props.flat();
        const uniqueProps = [...new Set(flattenProps)];
        return uniqueProps;
    }, [protPropertiesStr]);

    const handleProtPropertyAdd = useCallback((codeName) => {
        const props = parseProtPropertiesStr(protPropertiesStr);
        if (props.flat().includes(codeName)) {
            // Remove from list
            const filteredProps = props.map((item) => item.filter((prop) => prop !== codeName));
            setProtPropertiesStr(buildProtPropertiesStr(filteredProps));
        } else {
            // Add to list
            const newProps = [...props, [codeName]];
            setProtPropertiesStr(buildProtPropertiesStr(newProps));
        }
    }, [protPropertiesStr, setProtPropertiesStr]);

    const handleProtPropertyVariant = useCallback((codeName) => {
        const props = parseProtPropertiesStr(protPropertiesStr);
        if (props.flat().includes(codeName)) {
            // Remove from list
            const filteredProps = props.map((item) => item.filter((prop) => prop !== codeName));
            setProtPropertiesStr(buildProtPropertiesStr(filteredProps));
        } else {
            // Add to list as an option to the last item
            if (props.length > 0) {
                props[props.length - 1].push(codeName);
                setProtPropertiesStr(buildProtPropertiesStr(props));
            }
        }
    }, [protPropertiesStr, setProtPropertiesStr]);

    useEffect(() => {
        async function fetchAllProtProperties() {
            try {
                const list = await professionApi.getProtectivePropertyList();
                setAllProtProperties(list);
            } catch (e) {
                console.error(e);
                showNotification(t("errorLoadingProtectiveProperties"), "danger");
            }
        }

        const delayedFetch = setTimeout(fetchAllProtProperties, 200);

        return () => clearTimeout(delayedFetch);
    }, []);

    return (
        <Container>
            <Row>
                <Col xs={12} lg={6} className="mx-auto">
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label><Trans>riskFactorName</Trans></Form.Label>
                            <Form.Control
                                type="text"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                placeholder={t("riskFactorNamePlaceholder")}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label><Trans>riskFactorCodeName</Trans></Form.Label>
                            <Form.Control
                                type="text"
                                value={codeName}
                                onChange={e => setCodeName(e.target.value)}
                                placeholder={t("riskFactorCodeNamePlaceholder")}
                            />
                            <Form.Text className="text-muted"><Trans>riskFactorCodeNameHelp</Trans></Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label><Trans>riskFactorProtPropertiesStr</Trans></Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    value={protPropertiesStr}
                                    onChange={e => setProtPropertiesStr(e.target.value)}
                                    placeholder={t("riskFactorProtPropertiesStrPlaceholder")}
                                />

                                {allProtProperties === null ? (
                                    <Loader compact={true} />
                                ) : (
                                    <>
                                        <Button
                                            variant="outline-secondary"
                                            ref={protPropertiesAddTarget}
                                            onClick={() => setShowProtPropertiesAdd(true)}
                                        >
                                            + <Trans>riskFactorProtPropertyAdd</Trans>
                                        </Button>
                                        <Button
                                            variant="outline-secondary"
                                            ref={protPropertiesVariantTarget}
                                            onClick={() => setShowProtPropertiesVariant(true)}
                                        >
                                            / <Trans>riskFactorProtPropertyVariant</Trans>
                                        </Button>
                                    </>
                                )}
                            </InputGroup>
                            <Form.Text className="text-muted"><Trans>riskFactorProtPropertiesStrHelp</Trans></Form.Text>

                            {allProtProperties !== null && (
                                <>
                                    <Overlay
                                        show={showProtPropertiesAdd}
                                        target={protPropertiesAddTarget.current}
                                        placement="bottom"
                                        rootClose
                                        onHide={() => setShowProtPropertiesAdd(false)}
                                    >
                                        <Popover>
                                            <div className="p-2">
                                                <ProtPropertySelect
                                                    protProperties={allProtProperties}
                                                    values={selectedProtProperties}
                                                    handleSelected={handleProtPropertyAdd}
                                                />
                                            </div>
                                        </Popover>
                                    </Overlay>

                                    <Overlay
                                        show={showProtPropertiesVariant}
                                        target={protPropertiesVariantTarget.current}
                                        placement="bottom"
                                        rootClose
                                        onHide={() => setShowProtPropertiesVariant(false)}
                                    >
                                        <Popover>
                                            <div className="p-2">
                                                <ProtPropertySelect
                                                    protProperties={allProtProperties}
                                                    values={selectedProtProperties}
                                                    handleSelected={handleProtPropertyVariant}
                                                />
                                            </div>
                                        </Popover>
                                    </Overlay>
                                </>
                            )}
                        </Form.Group>

                        <div className="d-flex justify-content-center">
                            {riskFactor.id !== undefined && (
                                <Button
                                    variant="danger"
                                    className="me-auto"
                                    onClick={() => onDelete(riskFactor.id)}
                                >
                                    <FontAwesomeIcon icon={faTrash} /> <Trans>Delete</Trans>
                                </Button>
                            )}

                            <Button
                                variant="secondary"
                                className="ms-auto me-2"
                                onClick={onCancelClick}
                            >
                                <Trans>Cancel</Trans>
                            </Button>

                            <Button
                                variant="primary"
                                className="me-auto"
                                onClick={onSaveClick}
                            >
                                <FontAwesomeIcon icon={faSave} /> <Trans>Save</Trans>
                            </Button>
                        </div>

                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default RiskFactorEdit;
