import { Accordion, Badge, Button, Form } from "react-bootstrap";
import { t } from "i18next";
import OptionFilter from "./OptionFilter";
import { useCallback } from "react";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const FiltersForm = ({ shoeProperties, selected, setSelected, defaultSelected, onClear }) => {
    const getOptionSelected = useCallback((name) => {
        return selected[name] ? selected[name] : [];
    }, [selected]);

    const setOptionSelected = useCallback((name, optionSelected) => {
        setSelected({ ...selected, [name]: optionSelected });
    }, [selected, setSelected]);

    function renderFilter(name, options, title, isCssColor, isCodeDescription) {
        if (!options || options.length === 0) {
            return null;
        }

        const selectedCount = getOptionSelected(name).length;

        return (
            <Accordion.Item eventKey={name}>
                <Accordion.Header>{title} {selectedCount > 0 ? <Badge bg="light" className="ms-2">{selectedCount}</Badge> : ""}</Accordion.Header>
                <Accordion.Body>
                    <OptionFilter
                        options={options}
                        selected={getOptionSelected(name)}
                        setSelected={(selected) => setOptionSelected(name, selected)}
                        isCssColor={isCssColor}
                        isCodeDescription={isCodeDescription}
                    />
                </Accordion.Body>
            </Accordion.Item>
        );
    }

    return (
        <Form>
            <Accordion defaultActiveKey="category">
                {renderFilter("category", shoeProperties.category, t("Category"))}
                {renderFilter("availability", shoeProperties.availability, t("Availability"))}
                {renderFilter("type", shoeProperties.type, t("Type"))}
                {renderFilter("brand", shoeProperties.brand, t("Brand"))}
                {renderFilter("sex", shoeProperties.sex, t("Sex"))}
                {renderFilter("season", shoeProperties.season, t("Season"))}
                {renderFilter("climaticConditions", shoeProperties.climaticConditions, t("Climatic conditions"))}
                {/* {renderFilter("riskFactors", shoeProperties.riskFactors, t("Risk factors"))} */}
                {renderFilter("protectiveProperties", shoeProperties.protectiveProperties, t("Protective properties"), false, true)}
                {renderFilter("color", shoeProperties.color, t("Color"), true)}
                {renderFilter("innerMaterial", shoeProperties.innerMaterial, t("Inner material"))}
                {renderFilter("outerMaterial", shoeProperties.outerMaterial, t("Outer material"))}
                {renderFilter("soleMaterial", shoeProperties.soleMaterial, t("Sole material"))}
                {renderFilter("style", shoeProperties.style, t("filterStyle"))}
                {renderFilter("last", shoeProperties.last, t("Last"))}
                {renderFilter("specialType", shoeProperties.specialType, t("Special type"))}
                {renderFilter("branding", shoeProperties.branding, t("Client branding"))}
                {renderFilter("availability", shoeProperties.insole, t("Availability"))}
            </Accordion>

            {/* Clear button */}
            <Button
                className="w-100 mt-3"
                variant="outline-dark"
                style={{ border: "none" }}
                onClick={() => {
                    setSelected(defaultSelected);

                    if (onClear) {
                        onClear();
                    }
                }}
            >
                <FontAwesomeIcon icon={faXmark} /> <Trans>filtersClear</Trans>
            </Button>
        </Form>
    );
};

export default FiltersForm;
