import { faList, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo } from "react";
import { Button } from "react-bootstrap";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";

const AddToComparisionBlock = ({ item, selectedSize, selectedInsole, humanName }) => {
    const [comparisionItems, setComparisionItems] = useLocalStorageState('comparisionItems', { defaultValue: [] });

    const navigate = useNavigate();

    const modelId = useMemo(() => item.modelId, [item]);
    const measurementId = useMemo(() => item.fitnessEstimate?.measurementId, [item]);

    const isSameItem = useCallback((item1) => {
        return item1.modelId === modelId &&
            item1.selectedSize === selectedSize &&
            item1.selectedInsole?.id === selectedInsole?.id &&
            item1.measurementId === measurementId &&
            item1.humanName === humanName;
    }, [modelId, selectedSize, selectedInsole, measurementId, humanName]);

    const isInComparision = useMemo(() => {
        return comparisionItems.some((item1) => isSameItem(item1));
    }, [comparisionItems, isSameItem]);

    function addToComparision() {
        // Add to the end of comparision array
        const newComparisionItems = [...comparisionItems, { modelId, selectedSize, selectedInsole, measurementId, humanName }];

        // Remove oldest item if count of items is more than 2
        if (newComparisionItems.length > 2) {
            newComparisionItems.shift();
        }

        setComparisionItems(newComparisionItems);
    }

    function removeFromComparision() {
        const newComparisionItems = comparisionItems.filter((item) => {
            return !isSameItem(item);
        });
        setComparisionItems(newComparisionItems);
    }

    function goToComparision() {
        navigate('/comparision');
    }

    return (
        <div className="d-flex align-items-center">
            {isInComparision ? (
                <>
                    <Button variant="outline-primary" size="sm" onClick={goToComparision} disabled={comparisionItems.length !== 2}><FontAwesomeIcon icon={faList} /> <Trans>comparisionGoTo</Trans></Button>
                    <Button variant="outline-danger" size="sm" className="ms-2" style={{ border: 'none' }} onClick={removeFromComparision}><FontAwesomeIcon icon={faXmark} /> <Trans>comparisionRemove</Trans></Button>
                </>
            ) : (
                <Button variant="outline-secondary" size="sm" onClick={addToComparision}><FontAwesomeIcon icon={faList} /> <Trans>comparisionAdd</Trans></Button>
            )}
        </div>
    );
};

export default AddToComparisionBlock;
