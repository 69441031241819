class ClientRiskApi {
    constructor(sessionId) {
        this.sessionId = sessionId;
    }

    ready() {
        return !!this.sessionId;
    }

    async addRiskFactor(riskFactor) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/add_risk_factor';

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(riskFactor)
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return await response.json();
    }

    async getRiskFactor({ riskFactorId, codeName }) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/get_risk_factor';

        const params = new URLSearchParams();
        if (riskFactorId) {
            params.append('risk_factor_id', riskFactorId);
        }
        if (codeName) {
            params.append('code_name', codeName);
        }

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return await response.json();
    }

    async getRiskFactors() {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/get_risk_factors';

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return await response.json();
    }

    async updateRiskFactor(riskFactor) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/update_risk_factor';

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(riskFactor)
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        return await response.json();
    }

    async deleteRiskFactor({ riskFactorId, codeName }) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/delete_risk_factor';

        const params = new URLSearchParams();
        if (riskFactorId) {
            params.append('risk_factor_id', riskFactorId);
        }
        if (codeName) {
            params.append('code_name', codeName);
        }

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }
}

export default ClientRiskApi;
