import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "react-i18next";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import useCartApi from "../../app/useCartApi";
import useCartData from "../../app/useCartData";

const AddToCartButton = ({ item, selectedSize, selectedInsole, humanName, disabled }) => {
    const { cart, setCartItems } = useCartData()
    const cartApi = useCartApi();

    function handleAddToCart() {
        const newCartItems = cart.add(item, selectedSize, selectedInsole, humanName);
        setCartItems(newCartItems);

        if (cart.cartId) {
            cartApi.save(cart.cartId, newCartItems)
                .then(() => {
                    showNotification(t("Shopping list saved"), "success");
                })
                .catch((error) => {
                    console.log(error);
                    showNotification(t("Error saving shopping list"), "danger");
                })
        }
    }

    if (cart.check(item, selectedSize, selectedInsole)) {
        return (
            <a className="btn btn-secondary btn-sm float-end" href="/cart">
                <FontAwesomeIcon icon={faShoppingBag} className="me-1" />
                <Trans>Go to shopping list</Trans>
            </a>
        );
    } else {
        return (
            <button className="btn btn-primary btn-sm float-end" onClick={() => handleAddToCart()} disabled={!selectedSize || disabled}>
                <FontAwesomeIcon icon={faShoppingBag} className="me-1" />
                <Trans>Add to shopping list</Trans>
            </button>
        );
    }
};

export default AddToCartButton;
