import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { Alert, Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import useDebounce from "../../utils/useDebounce";
import { Trans } from "react-i18next";
import { showNotification } from "../../utils/notification";
import useProfessionApi from "../../app/useProfessionApi";

const ProfessionSelect = ({ professionList, active, onEditing, selectedProfession, setSelectedProfession, size }) => {
    const professionApi = useProfessionApi();

    const [filterStr, setFilterStr, debouncedFilterStr] = useDebounce("", 500);
    const [curProfessionList, setCurProfessionList] = useState(professionList);

    const filteredProfessions = useMemo(() => {
        if (!curProfessionList) {
            return [];
        }

        if (!debouncedFilterStr) {
            return curProfessionList;
        }

        return curProfessionList.filter((profession) => {
            return profession.profession.toLowerCase().includes(debouncedFilterStr.toLowerCase());
        });
    }, [curProfessionList, debouncedFilterStr]);

    useEffect(() => {
        async function updateList() {
            try {
                const list = await professionApi.getProfessionList(debouncedFilterStr);
                setCurProfessionList(list);
            } catch (e) {
                console.error(e);
                showNotification(t("errorLoadingProfessions"), "danger");
            }
        }

        if (!professionList && debouncedFilterStr.length >= 3) {
            updateList();
        }
    }, [debouncedFilterStr, professionApi, professionList]);

    return (
        <div>
            {/* Filter input */}
            <InputGroup className="my-3 text-sm">
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control
                    type="text"
                    placeholder={t("professionSearch")}
                    value={filterStr}
                    onChange={(e) => setFilterStr(e.target.value)}
                    onFocus={() => onEditing(true)}
                />
            </InputGroup>

            {/* Professions list with scroll */}
            {active && (
                <div className="d-flex flex-column my-3" style={{ height: "400px", overflowY: "auto" }}>
                    <Container>
                        <Row>
                            {debouncedFilterStr.length >= 3 ? (
                                filteredProfessions.length > 0 ? filteredProfessions.map((profession) => [
                                    <Col xs={6}>
                                        <Button
                                            className="my-1"
                                            key={profession.profession + "." + profession.industry}
                                            variant={selectedProfession === profession ? "primary" : "outline-primary"}
                                            style={{ border: "none", textAlign: "left" }}
                                            // className="w-100"
                                            onClick={() => {
                                                setSelectedProfession(profession);
                                            }}
                                        >
                                            {profession.profession}
                                        </Button>
                                    </Col>,
                                    <Col xs={6}>
                                        <div>{profession.industry}</div>
                                    </Col>,
                                ]).flat() : (
                                    <div className="text-center text-secondary"><Trans>noResults</Trans></div>
                                )) : (
                                <div className="text-center text-secondary"><FontAwesomeIcon icon={faSearch} /> <Trans>professionsUseSearch</Trans></div>
                            )}
                        </Row>
                    </Container>
                </div>
            )}

            {/* Selected profession */}
            {!active && selectedProfession && (
                <Alert variant="primary" className="my-3">
                    {size !== "sm" ? <Alert.Heading>{selectedProfession.profession}</Alert.Heading> : <div>{selectedProfession.profession}</div>}
                    {selectedProfession.industry && <p>{selectedProfession.industry}</p>}
                </Alert>
            )}
        </div>
    );
};

export default ProfessionSelect;
