import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Trans } from "react-i18next";
import { apiGetMeasurement } from "../../app/measurementApi";
import { showNotification } from "../../utils/notification";
import Loader from "../global/Loader";

const AddMeasurementForm = ({ handleAdded }) => {
    const [measurementId, setMeasurementId] = useState("");
    const [humanName, setHumanName] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    async function addMeasurement() {
        setIsLoading(true);

        try {
            // fetch measurement to check it
            await apiGetMeasurement(measurementId);

            setIsLoading(false);
            handleAdded(measurementId);
        } catch (err) {
            console.log(err);
            setIsLoading(false);
            showNotification(t("Error checking measurement") + ": " + err, 'danger');
        }
    }

    useEffect(() => {
        async function getHumanName() {
            if (measurementId) {
                try {
                    const measurement = await apiGetMeasurement(measurementId);
                    if (measurement.humanInfo?.name) {
                        setHumanName(measurement.humanInfo.name);
                    } else {
                        setHumanName("");
                    }
                } catch (err) {
                    setHumanName("");
                }
            } else {
                setHumanName("");
            }
        }

        getHumanName();
    }, [measurementId]);

    return (
        <Form className="d-flex">
            <InputGroup>
                <Form.Label htmlFor="measurementIdInput" visuallyHidden><Trans>Measurement ID</Trans></Form.Label>
                <Form.Control
                    id="measurementIdInput"
                    style={{ maxWidth: "20rem" }}
                    placeholder={t("Measurement ID")}
                    disabled={isLoading}
                    value={measurementId}
                    onChange={(e) => setMeasurementId(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            addMeasurement();
                        }
                    }}
                />

                {!isLoading ? (
                    <Button
                        className="btn btn-primary"
                        onClick={addMeasurement}
                        disabled={measurementId === "" || humanName === "" || isLoading}
                    >
                        <FontAwesomeIcon icon={faUserPlus} /> <Trans>Add</Trans>
                    </Button>
                ) : (
                    <Loader compact />
                )}

                {/* Person name label */}
                <div className="fst-italic ms-2 align-self-center">{humanName}</div>
            </InputGroup>
        </Form>
    )
};

export default AddMeasurementForm;