import { faBars, faCheckCircle, faFileExcel, faFileWord, faHandPointer, faShare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import useCartData from "../../app/useCartData";
import { shareUrl } from "../../utils/share";
import { t } from "i18next";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import ConfirmationDialog from "../global/ConfirmationDialog";
import { downloadBlob } from "../../utils/blob";
import { showNotification } from "../../utils/notification";
import { shoeserverDateToString } from "../../utils/date";

function CartHistoryItem({ cartApi, cart, handleLoadCart, handleDeleteCart }) {
    const { cart: currentCart } = useCartData();
    const isCurrent = useMemo(() => cart.cartId === currentCart.cartId, [cart.cartId, currentCart.cartId]);
    const totalCount = useMemo(() => cart.items.length, [cart.items]);

    const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false);

    const downloadCart = useCallback((format) => {
        cartApi.exportCart(cart.cartId, format)
            .then((blob) => {
                const filename = `${cart.cartId}.${format}`;
                downloadBlob(blob, filename);
            })
            .catch((error) => {
                console.log(error);
                showNotification(t("Error exporting shopping list"), "danger");
            });
    }, [cart.cartId, cartApi]);

    const shareCart = useCallback((cartId) => {
        // Generate share link
        const link = cartApi.makeShareLink(cartId);

        // Share using system share API or clipboard
        shareUrl(t("Share shopping list"), t("Share shopping list"), link);
    }, [cartApi]);

    return (
        <Row className={isCurrent ? "bg-primary-subtle" : ""}>
            <Col className="p-2">
                <div>
                    <Trans>Shopping list</Trans> {cart.name && <b>{cart.name}</b>} {cart.cartId && <span>{cart.cartId}</span>} {cart.submitDate && <FontAwesomeIcon icon={faCheckCircle} className="text-success" />}
                </div>
                <div>
                    <small><Trans>cartHistoryItemCreationDate</Trans>: {shoeserverDateToString(cart.dateUtc)}</small>
                </div>
                {cart.updateDate && <div><small><Trans>cartHistoryItemUpdateDate</Trans>: {shoeserverDateToString(cart.updateDate)}</small></div>}
                {cart.submitDate && <div><small><Trans>cartHistoryItemSubmitDate</Trans>: {shoeserverDateToString(cart.submitDate)}</small></div>}
            </Col>

            <Col className="p-2 d-none d-md-table-cell">
                {totalCount}
            </Col>

            <Col className="p-2">
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-secondary" size="sm">
                            <FontAwesomeIcon icon={faBars} /> <span className="d-none d-sm-inline"><Trans>Action</Trans></span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {!isCurrent && <>
                                <DropdownItem onClick={() => handleLoadCart(cart.cartId)}><FontAwesomeIcon icon={faHandPointer} /> <Trans>cartHistoryItemLoad</Trans></DropdownItem>
                                <Dropdown.Divider />
                            </>
                            }

                            <DropdownItem onClick={() => downloadCart("xlsx")}><FontAwesomeIcon icon={faFileExcel} /> <Trans>cartHistoryItemDownloadExcel</Trans></DropdownItem>
                            <DropdownItem onClick={() => downloadCart("docx")}><FontAwesomeIcon icon={faFileWord} /> <Trans>cartHistoryItemDownloadWord</Trans></DropdownItem>
                            <DropdownItem onClick={() => shareCart(cart.cartId)}><FontAwesomeIcon icon={faShare} /> <Trans>cartHistoryItemShare</Trans></DropdownItem>

                            {!isCurrent && <>
                                <Dropdown.Divider />
                                <DropdownItem onClick={() => setDeleteConfirmationShow(true)}><FontAwesomeIcon icon={faTrash} /> <Trans>cartHistoryItemDelete</Trans></DropdownItem>
                            </>}
                        </Dropdown.Menu>
                    </Dropdown>

                    <ConfirmationDialog
                        show={deleteConfirmationShow}
                        title={t("cartHistoryItemDelete")}
                        message={t("cartHistoryItemDeleteConfirm")}
                        confirmText={t("Delete")}
                        variant="danger"
                        onConfirm={() => handleDeleteCart(cart.cartId)}
                        onCancel={() => setDeleteConfirmationShow(false)}
                    />
            </Col>
        </Row>
    );
}

export default CartHistoryItem;
