import { useEffect, useState } from "react";
import useClientApi from "../../app/useClientApi";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faKey, faRightToBracket, faSpinner } from "@fortawesome/free-solid-svg-icons";
import useLocalStorageState from "use-local-storage-state";

function ProfileBadge() {
    const [, setSessionId] = useLocalStorageState("sessionId", null);
    const clientApi = useClientApi();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        async function fetchData() {
            if (!clientApi || !clientApi.ready()) {
                return;
            }

            try {
                // Check session
                const sessionValid = await clientApi.checkSession();
                if (!sessionValid) {
                    setSessionId(null);
                    return;
                }

                // Get user data
                const data = await clientApi.getUserData();
                setUserData(data);
            } catch (error) {
                console.error(error);
                showNotification(t("profileDataFetchError") + ": " + error.message, 'danger');
            }
        }
        
        const delayedFetch = setTimeout(fetchData, 200);
        return () => clearTimeout(delayedFetch);
    }, [clientApi, setSessionId]);

    return (!clientApi || !clientApi.ready()) ? (
        <Badge bg="secondary" pill><FontAwesomeIcon icon={faRightToBracket} /></Badge>
    ) : (userData ? (
        <Badge bg="secondary" pill>{userData.training_done ? <FontAwesomeIcon icon={faCamera} /> : <FontAwesomeIcon icon={faKey} />}</Badge>
    ) : (
        <Badge bg="secondary" pill><FontAwesomeIcon icon={faSpinner} /></Badge>
    ));
}

export default ProfileBadge;
