import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Nav, Stack } from "react-bootstrap"

const HeaderNavLink = ({ href, title, icon, active, renderBadge, direction = "vertical" }) => {
    return (
        <Nav.Link href={href} active={active}>
            {direction === "horizontal" ? (
                <div className="d-flex align-items-center">
                    {icon && (
                        <div className="me-2">
                            <FontAwesomeIcon icon={icon} className='navbar-icon' /> {renderBadge && renderBadge()}
                        </div>
                    )}
                    <div className="fs-5">{title}</div>
                </div>
            ) : (
                <Stack className="align-items-center">
                    <div>
                        <FontAwesomeIcon icon={icon} className='navbar-icon' /> {renderBadge && renderBadge()}
                    </div>
                    <div className="text-center">
                        {title}
                    </div>
                </Stack>
            )}
        </Nav.Link>
    );
};

export default HeaderNavLink;
