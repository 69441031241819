import { Trans } from "react-i18next";
import EmployeeTableRow from "./EmployeeTableRow";

function EmployeeTable({ employees, riskFactorNames, climaticNames, onSave, onDelete }) {
    return (
        <table className="table table-hover mt-3">
            <thead>
                <tr>
                    <th scope="col" className="d-none d-md-table-cell"><Trans>employeeTableNumber</Trans></th>
                    <th scope="col"><Trans>employeeTableName</Trans></th>
                    <th scope="col" className="d-none d-md-table-cell"><Trans>employeeTableProfession</Trans></th>
                    <th scope="col" className="d-none d-md-table-cell"><Trans>employeeTableDepartment</Trans></th>
                    <th scope="col"><Trans>employeeTableMeasurements</Trans></th>
                    <th scope="col"><Trans>employeeTableAction</Trans></th>
                </tr>
            </thead>

            <tbody>
                {employees.length > 0 ? employees.map((employee) => (
                    <EmployeeTableRow
                        employee={employee}
                        riskFactorNames={riskFactorNames}
                        climaticNames={climaticNames}
                        key={employee.id}
                        onSave={onSave}
                        onDelete={onDelete} />
                )) : (
                    <tr>
                        <td colSpan={6}><Trans>employeeTableEmpty</Trans></td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

export default EmployeeTable;