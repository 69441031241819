import { useMemo } from "react";
import useLocalStorageState from "use-local-storage-state";
import ClientRiskApi from "./clientRiskApi";

function useClientRiskApi() {
    const [sessionId, ] = useLocalStorageState("sessionId", null);
    return useMemo(() => new ClientRiskApi(sessionId), [sessionId]);
}

export default useClientRiskApi;
