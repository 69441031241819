import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Trans } from "react-i18next";
import EmployeeEdit from "./EmployeeEdit";
import EmployeeDetails from "./EmployeeDetails";

function EmployeeTableRow({ employee, riskFactorNames, climaticNames, onSave, onDelete }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    return [
        <tr key={employee.id}>
            <td className="d-none d-md-table-cell">{employee.employee_number}</td>
            <td>{employee.first_name} {employee.last_name}</td>
            <td className="d-none d-md-table-cell">{employee.profession}</td>
            <td className="d-none d-md-table-cell">{employee.department}</td>
            <td>{employee.measurement_ids?.length > 0 ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}</td>
            <td><Button variant="link" size="sm" onClick={() => setIsExpanded((isExpanded) => !isExpanded)}>{isExpanded ? <Trans>employeeTableHide</Trans> : <Trans>employeeTableShow</Trans>}</Button></td>
        </tr>,
        isExpanded && (
            <tr key={`${employee.id}-details`}>
                <td colSpan={6}>
                    <Container>
                        {isEdit ? (
                            <EmployeeEdit
                                employee={employee}
                                riskFactorNames={riskFactorNames}
                                climaticNames={climaticNames}
                                onSave={onSave}
                                onDelete={onDelete}
                                onCancel={() => setIsEdit(false)}
                            />
                        ) : (
                            <EmployeeDetails
                                employee={employee}
                                riskFactorNames={riskFactorNames}
                                climaticNames={climaticNames}
                                onEdit={() => setIsEdit(true)}
                                onDelete={onDelete} />
                        )}
                    </Container>
                </td>
            </tr>
        )
    ];
}

export default EmployeeTableRow;
