import { Alert } from "react-bootstrap"
import { Trans } from "react-i18next";

const EtnReqsSelect = ({ etnReqs }) => {
    return etnReqs ? (
        <Alert variant="secondary" className="my-3">
            <Alert.Heading>{etnReqs}</Alert.Heading>
        </Alert>
    ) : (
        <p><Trans>etnNotSelected</Trans></p>
    );
};

export default EtnReqsSelect;
