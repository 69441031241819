import { t } from "i18next";
import { dataUriToBlob } from "../utils/dataUri";
import { UAParser } from "ua-parser-js";

const softwareName = "FeetAppWeb";

function fixImageUrl(url) {
    // FIXME: This is a hack
    const baseUrl = process.env.REACT_APP_FOOTSERVER_URL;
    const baseUrlDev = 'http://dp2.emitech.tech:8080/footserver';

    if (baseUrl !== baseUrlDev && url.startsWith(baseUrlDev)) {
        return url.replace(baseUrlDev, baseUrl);
    } else {
        return url;
        
    }
}

function getDeviceModel() {
    const ua = navigator.userAgent;
    const parser = new UAParser(ua);

    let deviceModel = parser.getOS().name;

    if (parser.getDevice().model) {
        deviceModel += " " + parser.getDevice().model;
    }

    if (parser.getBrowser().name) {
        deviceModel += " " + parser.getBrowser().name;
    }

    return deviceModel;
}

async function apiCreateMeasurement(humanInfo, cartId) {
    // Setup request data
    const requestData = {}

    // Human info
    if (typeof(humanInfo) === 'string' && humanInfo.length > 0) {
        requestData.humanInfo = {
            name: humanInfo
        };
    } else if (typeof(humanInfo) === 'object') {
        requestData.humanInfo = humanInfo;
    }

    // Session info
    const sessionId = Math.random().toString(36).substring(2, 15);
    requestData.sessionInfo = {
        sessionId: sessionId,
        deviceModel: getDeviceModel(),
        softwareName: softwareName
    }

    // Cart ids
    if (cartId) {
        requestData.cartIds = [cartId];
    }

    const url = process.env.REACT_APP_FOOTSERVER_URL + '/api/createMeasurement';
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_FOOTSERVER_API_KEY
        },
        body: JSON.stringify(requestData),
    });

    if (!response.ok) {
        const replyText = await response.text();
        throw new Error(replyText);
    }

    const data = await response.json();
    return data;
}

function getImageName(mode, type) {
    // Get the current date and time
    const now = new Date();

    // Format name like «Xiaomi_22081212UG_2023-07-07-16-30-39_LEFT_TOPTOE.jpg»
    return `${softwareName}_${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}_${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}_${mode}_${type}.jpg`;
}

function getImageData(image) {
    if (image instanceof File) {
        return image;
    } else {
        // Convert base64 uri to data
        return dataUriToBlob(image);
    }
}

function addImageToUpload(image, mode, type, formData) {
    const imageName = getImageName(mode, type);
    const imageBlob = getImageData(image);

    formData.append(imageName, imageBlob, imageName);
}

function addSessionInfoJsonToUpload(formData, mode, sheetFormat) {
    // Make sessionInfo.json
    const sessionInfoJson = {
        "mode": mode,
        "sheetFormat": sheetFormat
    };

    // Make Blob
    const sessionInfoBlob = new Blob([JSON.stringify(sessionInfoJson)], { type: "application/json" });

    // add to formData
    formData.append("sessionInfo.json", sessionInfoBlob, "sessionInfo.json");
}

async function apiUploadImages(images, mode, measurementId, sheetFormat) {
    // Add images to FormData
    if (images.length < 3 || images.length > 4) {
        throw new Error(t("Invalid number of images"));
    }

    const formData = new FormData();
    let imageIndex = 0;
    addImageToUpload(images[imageIndex++], mode, "TOPTOE", formData);
    addImageToUpload(images[imageIndex++], mode, "TOPHEEL", formData);
    if (images.length === 4) {
        addImageToUpload(images[imageIndex++], mode, "OUTER", formData);
    }
    addImageToUpload(images[imageIndex++], mode, "FLOOR", formData);

    // Add session info to FormData (if needed)
    if (sheetFormat && sheetFormat !== "A4") {
        addSessionInfoJsonToUpload(formData, mode, sheetFormat);
    }

    // Perform request
    let url = process.env.REACT_APP_FOOTSERVER_URL + '/api/uploadImages';

    if (measurementId) {
        url += "?" + new URLSearchParams({ measurementId: measurementId });
    }

    const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
            'X-API-Key': process.env.REACT_APP_FOOTSERVER_API_KEY
        }
    });

    if (!response.ok) {
        const replyText = await response.text();
        throw new Error(replyText);
    }

    const data = await response.json();
    return data;
}

async function apiFinishMeasurement(measurementId) {
    const url = process.env.REACT_APP_FOOTSERVER_URL + '/api/finishMeasurement';
    const response = await fetch(url + "?" + new URLSearchParams({ measurementId: measurementId }), {
        headers: {
            'X-API-Key': process.env.REACT_APP_FOOTSERVER_API_KEY
        }
    });

    if (!response.ok) {
        const replyText = await response.text();
        throw new Error(replyText);
    }

    const data = await response.json();
    return data;
}

async function apiMakeMeasurement(humanInfo, imagesRight, imagesLeft, cartId, sheetFormat, statusCallback) {
    // Create measurement
    if (statusCallback) {
        statusCallback(t("Creating measurement"));
    }

    let measurement = await apiCreateMeasurement(humanInfo, cartId);

    // Upload right foot images
    if (imagesRight && imagesRight.length > 0) {
        if (statusCallback) {
            statusCallback(t("Processing right foot images"));
        }

        await apiUploadImages(imagesRight, "RIGHT", measurement.measurementId, sheetFormat);
    }

    // Upload left foot images
    if (imagesLeft && imagesLeft.length > 0) {
        if (statusCallback) {
            statusCallback(t("Processing left foot images"));
        }

        await apiUploadImages(imagesLeft, "LEFT", measurement.measurementId, sheetFormat);
    }

    // Finish measurement
    if (statusCallback) {
        statusCallback(t("Finishing measurement"));
    }

    measurement = await apiFinishMeasurement(measurement.measurementId);

    return measurement;
}

async function apiGetMeasurement(measurementId) {
    const url = process.env.REACT_APP_FOOTSERVER_URL + '/api/getMeasurement';
    const response = await fetch(url + "?" + new URLSearchParams({ measurementId: measurementId }), {
        headers: {
            'X-API-Key': process.env.REACT_APP_FOOTSERVER_API_KEY
        }
    });

    if (!response.ok) {
        const replyText = await response.text();
        throw new Error(replyText);
    }

    let data = await response.json();

    // Fix image urls
    if (data.smallImages) {
        data.smallImagesFixed = data.smallImages.map(fixImageUrl);
    }

    return data;
}

async function apiGetMeasurements(measurementIds) {
    const measurementIdString = measurementIds.join(',');
    const url = process.env.REACT_APP_FOOTSERVER_URL + '/api/getMeasurements';
    const response = await fetch(url + "?" + new URLSearchParams({ measurementIds: measurementIdString }), {
        headers: {
            'X-API-Key': process.env.REACT_APP_FOOTSERVER_API_KEY
        }
    });

    if (!response.ok) {
        const replyText = await response.text();
        throw new Error(replyText);
    }

    let data = await response.json();

    // Fix image urls
    for (let measurement of data) {
        if (measurement.smallImages) {
            measurement.smallImagesFixed = measurement.smallImages.map(fixImageUrl);
        }
    }

    return data;
}

async function apiGetCartMeasurements(cartId, onlyFinished, maxAgeSeconds) {
    let requestParams = {
        cartId: cartId
    };
    
    if (onlyFinished !== undefined) {
        requestParams.isFinished = onlyFinished;
    }

    if (maxAgeSeconds) {
        requestParams.maxAgeSeconds = maxAgeSeconds;
    }

    const url = process.env.REACT_APP_FOOTSERVER_URL + '/api/getMeasurements';
    const response = await fetch(url + "?" + new URLSearchParams(requestParams), {
        headers: {
            'X-API-Key': process.env.REACT_APP_FOOTSERVER_API_KEY
        }
    });

    if (!response.ok) {
        const replyText = await response.text();
        throw new Error(replyText);
    }

    const data = await response.json();

    // Fix image urls
    for (let measurement of data) {
        if (measurement.smallImages) {
            measurement.smallImagesFixed = measurement.smallImages.map(fixImageUrl);
        }
    }

    return data;
}

async function apiAddMeasurementToCart(measurementId, cartId) {
    const url = process.env.REACT_APP_FOOTSERVER_URL + '/api/addCart';
    const response = await fetch(url + "?" + new URLSearchParams({ measurementId: measurementId, cartId: cartId }), {
        headers: {
            'X-API-Key': process.env.REACT_APP_FOOTSERVER_API_KEY
        }
    });

    if (!response.ok) {
        const replyText = await response.text();
        throw new Error(replyText);
    }

    return true;
}

async function apiRemoveMeasurementFromCart(measurementId, cartId) {
    const url = process.env.REACT_APP_FOOTSERVER_URL + '/api/removeCart';
    const response = await fetch(url + "?" + new URLSearchParams({ measurementId: measurementId, cartId: cartId }), {
        headers: {
            'X-API-Key': process.env.REACT_APP_FOOTSERVER_API_KEY
        }
    });

    if (!response.ok) {
        const replyText = await response.text();
        throw new Error(replyText);
    }

    return true;
}

async function apiGetMeasurementNft(measurementId) {
    const url = process.env.REACT_APP_FOOTSERVER_URL + '/api/getMeasurementNft';
    const response = await fetch(url + "?" + new URLSearchParams({ measurementId: measurementId }), {
        headers: {
            'X-API-Key': process.env.REACT_APP_FOOTSERVER_API_KEY
        }
    });

    if (!response.ok) {
        const replyText = await response.text();
        throw new Error(replyText);
    }

    const data = await response.json();
    return data;
}

async function apiMintMeasurementNft(measurementId, walletAddress) {
    const url = process.env.REACT_APP_FOOTSERVER_URL + '/api/mintMeasurementNft';
    const requestParams = {
        measurementId: measurementId,
        walletAddress: walletAddress
    };

    const response = await fetch(url + "?" + new URLSearchParams(requestParams), {
        headers: {
            'X-API-Key': process.env.REACT_APP_FOOTSERVER_API_KEY
        }
    });

    if (!response.ok) {
        const replyText = await response.text();
        throw new Error(replyText);
    }

    const data = await response.json();
    return data;
}

async function apiCheckAndMint(measurementId, humanName, walletAddress) {
    const measurementData = await apiGetMeasurement(measurementId);

    const measurementHumanName = measurementData.humanInfo?.name;
    if (humanName !== measurementHumanName) {
        throw new Error(t('Person name does not match'));
    }

    const nftData = await apiMintMeasurementNft(measurementId, walletAddress);
    return nftData;
}

export {
    apiUploadImages,
    apiMakeMeasurement,
    apiGetMeasurement,
    apiGetMeasurements,
    apiGetCartMeasurements,
    apiAddMeasurementToCart,
    apiRemoveMeasurementFromCart,
    apiGetMeasurementNft,
    apiMintMeasurementNft,
    apiCheckAndMint
};
