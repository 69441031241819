import { faChevronDown, faChevronUp, faInfoCircle, faPeopleArrows } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Alert, Button, Col, Collapse, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";

const ShootingGuide = () => {
    const [shootingGuideOpen, setShootingGuideOpen] = useState(false);

    return (
        <Alert variant="info" className="mt-3">
            <FontAwesomeIcon icon={faInfoCircle} /> <Trans>How to make a good measurement:</Trans>

            <ol type="i">
                <li><Trans components={{ strong: <strong /> }}>mhLevelSheet</Trans></li>
                <li><Trans components={{ strong: <strong />, u: <u /> }}>mhFootOnSheet</Trans></li>
                <li><Trans components={{ strong: <strong /> }}>mhTake3Photos</Trans></li>
            </ol>

            <FontAwesomeIcon icon={faPeopleArrows} /> <i><Trans>mhAnotherPerson</Trans></i>

            <div align="center">
                <Button variant="link" onClick={() => setShootingGuideOpen(!shootingGuideOpen)} aria-expanded={shootingGuideOpen}>{shootingGuideOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />} <Trans>Show guide</Trans></Button>
            </div>

            <Collapse in={shootingGuideOpen}>
                <div id="shootingGuide" className="mt-3">
                    <Container>
                        <Row>
                            <Col lg={3} sm={6}>
                                <img src="/photoGuide/footOnSheet.jpg" alt="Foot on sheet" className="w-100" />
                                <br />
                                <div className="fst-italic text-center"><Trans>mhFootOnSheetPhoto</Trans></div>
                            </Col>
                            <Col lg={3} sm={6}>
                                <img src="/photoGuide/toePhoto.jpg" alt="Toe" className="w-100" />
                                <br />
                                <div className="fst-italic text-center"><Trans>mhToePhoto</Trans></div>
                            </Col>
                            <Col lg={3} sm={6}>
                                <img src="/photoGuide/heelPhoto.jpg" alt="Heel" className="w-100" />
                                <br />
                                <div className="fst-italic text-center"><Trans>mhHeelPhoto</Trans></div>
                            </Col>
                            <Col lg={3} sm={6}>
                                <img src="/photoGuide/sidePhoto.jpg" alt="Side" className="w-100" />
                                <br />
                                <div className="fst-italic text-center"><Trans>mhSidePhoto</Trans></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Collapse>
        </Alert>
    )
};

export default ShootingGuide;
