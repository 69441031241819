import imageCompression from 'browser-image-compression';

async function imageToJpeg(blob) {
    const options = {
        fileType: "image/jpeg",
    };

    const compressed = await imageCompression(blob, options);

    return compressed;
}

async function imageToBase64(blob) {
    return imageCompression.getDataUrlFromFile(blob);
}

export { imageToJpeg, imageToBase64 };
