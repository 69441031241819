import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { getRatingText } from "../../app/fitness";

const StarsRating = ({ rating, compact }) => {
    return (
        rating >= 0.0 && (
            <div className={getRatingText(rating).style + " d-flex align-items-center"}>
                <span className="fw-bold me-2">{Number(rating).toFixed(1)}</span>
                {!compact ? (
                    [...Array(5)].map((_star, i) => (
                        i < rating ? (
                            <FontAwesomeIcon icon={faStar} key={i} />
                        ) : (
                            <FontAwesomeIcon icon={farStar} key={i} />
                        )
                    ))
                ) : (
                    <FontAwesomeIcon icon={faStar} />
                )}
            </div>
        )
    );
};

export default StarsRating;
