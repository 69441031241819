import { t } from "i18next";
import { getDeviationText, getInsoleText, getRatingText } from "./fitness";
import { parseCodeDescriptionString } from "../utils/codeDescriptionString";

function getAvailableSizes(shoeModel) {
    const sizes = shoeModel.availableSize;
    if (!sizes) {
        return [];
    }

    return [...sizes].sort((a, b) => {
        return Number(a) - Number(b);
    });
}

function getAvailableSizesStr(shoeModel) {
    const sizes = getAvailableSizes(shoeModel);
    return sizes.length > 0 ? sizes.join(', ') : "(" + t("no sizes available") + ")";
}

function getShoeDetails(shoeModel, mainOnly, skipProtectiveProperties) {
    let details = [];

    if (shoeModel.brand) {
        details.push({ key: t('Brand'), value: shoeModel.brand });
    }

    if (shoeModel.name) {
        details.push({ key: t('Model'), value: shoeModel.name });
    }

    if (shoeModel.article) {
        details.push({ key: t('Article'), value: shoeModel.article });
    }

    if (!mainOnly && shoeModel.type) {
        details.push({ key: t('Type'), value: shoeModel.type });
    }

    if (!mainOnly && shoeModel.sex) {
        details.push({ key: t('Sex'), value: shoeModel.sex });
    }

    if (!mainOnly && shoeModel.season) {
        details.push({ key: t('Season'), value: shoeModel.season });
    }

    if (!mainOnly && typeof (shoeModel.climaticConditions) === "object" && shoeModel.climaticConditions.length > 0) {
        details.push({ key: t('Climatic conditions'), value: shoeModel.climaticConditions.join(', ') });
    }

    if (!mainOnly && shoeModel.color) {
        details.push({ key: t('Color'), value: shoeModel.color, isCssColor: true });
    }

    if (!mainOnly && shoeModel.innerMaterial) {
        details.push({ key: t('Inner material'), value: shoeModel.innerMaterial });
    }

    if (!mainOnly && shoeModel.outerMaterial) {
        details.push({ key: t('Outer material'), value: shoeModel.outerMaterial });
    }

    if (!mainOnly && shoeModel.soleMaterial) {
        details.push({ key: t('Sole material'), value: shoeModel.soleMaterial });
    }

    if (!mainOnly && shoeModel.style) {
        details.push({ key: t('detailsStyle'), value: shoeModel.style });
    }

    if (!mainOnly && shoeModel.last) {
        details.push({ key: t('Last'), value: shoeModel.last });
    }

    if (!mainOnly && !skipProtectiveProperties && typeof (shoeModel.protectiveProperties) === "object" && shoeModel.protectiveProperties.length > 0) {
        details.push({ key: t('Protective properties'), value: shoeModel.protectiveProperties.join(', '), isProtectiveProperties: true });
    }

    if (!mainOnly && shoeModel.branding && shoeModel.branding !== t("shoeModelBrandingNone")) {
        details.push({ key: t('Client branding'), value: shoeModel.branding });
    }

    if (!mainOnly && shoeModel.availability) {
        details.push({ key: t('Availability'), value: shoeModel.availability });
    }

    if (!mainOnly && shoeModel.widthIndex) {
        details.push({ key: t('Width index'), value: shoeModel.widthIndex });
    }

    if (!mainOnly) {
        details.push({ key: t('Available sizes'), value: getAvailableSizesStr(shoeModel) });
    }

    return details;
}

function getPropectiveProperties(shoeModel) {
    if (!shoeModel?.protectiveProperties || !shoeModel?.protectiveProperties.length) {
        return [];
    }

    // Parse each protective property, should be in the format "code - description"
    return shoeModel.protectiveProperties.map(prop => parseCodeDescriptionString(prop));
}

function getShoeInsoles(shoeModel) {
    let insoles = [];

    if (shoeModel.insole) {
        insoles.push(shoeModel.insole);
    }

    if (shoeModel.alternateInsoles) {
        insoles = insoles.concat(shoeModel.alternateInsoles);
    }

    return insoles;
}

function getShoeFitnessText(shoeModel, humanName) {
    let items = [];

    items.push(getTypeBrandNameStr(shoeModel.type))
    items.push(t("Article") + ": " + shoeModel.article)

    if (shoeModel.fitnessEstimate) {
        const fitnessEstimate = shoeModel.fitnessEstimate;

        // Overall rating
        let str = t("Fitness estimate");

        if (humanName) {
            str += ` ${t("for")} ${humanName}`;
        }

        if (fitnessEstimate.measurementId) {
            str += ` (${fitnessEstimate.measurementId})`;
        }

        str += `: ${Number(fitnessEstimate.fitnessRating).toFixed(1)} ` + getRatingText(fitnessEstimate.fitnessRating).text;
        items.push(str);

        // Optimal size
        items.push(t("Optimal size") + ": " + fitnessEstimate.size);

        // Length
        if (fitnessEstimate.fitnessRatings['length'] && fitnessEstimate.deviations['length']) {
            items.push(t("Length") + `: ${Number(fitnessEstimate.fitnessRatings['length']).toFixed(1)} ${getRatingText(fitnessEstimate.fitnessRatings['length']).text} (${getDeviationText(fitnessEstimate.deviations['length']).text})`);
        }

        // Width
        if (fitnessEstimate.fitnessRatings['width'] && fitnessEstimate.deviations['width']) {
            items.push(t("Width") + `: ${Number(fitnessEstimate.fitnessRatings['width']).toFixed(1)} ${getRatingText(fitnessEstimate.fitnessRatings['width']).text} (${getDeviationText(fitnessEstimate.deviations['width']).text})`);
        }

        // Height
        if (fitnessEstimate.fitnessRatings['height'] && fitnessEstimate.deviations['height']) {
            items.push(t("Height") + `: ${Number(fitnessEstimate.fitnessRatings['height']).toFixed(1)} ${getRatingText(fitnessEstimate.fitnessRatings['height']).text} (${getDeviationText(fitnessEstimate.deviations['height']).text})`);
        }

        // Insole
        if (fitnessEstimate?.insole) {
            items.push(`${t("Insole")}: ${getInsoleText(fitnessEstimate.insole)}`);
        }

        // Extra insole
        if (fitnessEstimate?.extraInsole) {
            items.push(`${t("Extra insole")}: ${getInsoleText(fitnessEstimate.extraInsole)}`);
        }
    }

    return items.join("\n");
}

function getTypeBrandNameStr(shoeModel) {
    return `${shoeModel.type} ${shoeModel.brand} ${shoeModel.name}`;
}

function isShoeOutOfStock(shoeModel) {
    return shoeModel?.availability === "Нет в наличии";
}

function getShoeImageUrl(shoeModel, small) {
    if (small && shoeModel?.smallImagesFixed?.[0]) {
        return shoeModel.smallImagesFixed[0];
    }

    if (shoeModel?.imagesFixed?.[0]) {
        return shoeModel.imagesFixed[0];
    }

    return "/boot-sketch.jpeg";
}

export { getAvailableSizes, getAvailableSizesStr, getShoeDetails, getPropectiveProperties, getShoeInsoles, getShoeFitnessText, getTypeBrandNameStr, isShoeOutOfStock, getShoeImageUrl };