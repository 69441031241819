import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";

import Catalog from './views/Catalog';
import Measurements from './views/Measurements';
import Cart from "./views/Cart";
import ShoeView from "./views/ShoeView";
import MakeMeasurement from "./views/MakeMeasurement";
import MeasurementView from "./views/MeasurementView";
import ShareMeasurementsLanding from "./components/measurement/ShareMeasurementsLanding";
import Applications from "./views/Applications";
import ShareCartLanding from "./components/cart/ShareCartLanding";
import FitAll from "./views/FitAll";
import Professions from "./views/Professions";
import ComparisionView from "./views/ComparisionView";
import Profile from "./views/Profile";
import Login from "./views/Login";
import WalletMeasurementsView from "./views/WalletMeasurementsView";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/catalog" element={<Catalog />} />
                <Route path="/professions" element={<Professions />} />
                <Route path="/applications" element={<Applications />} />

                <Route path="/measurements" element={<Measurements />} />
                <Route path="/measurements/:measurementIds" element={<Measurements />} />

                <Route path="/cart" element={<Cart />} />
                <Route path="/cart/:cartId" element={<Cart />} />

                <Route path="/shoeModel" element={<ShoeView />} />
                <Route path="/measurement" element={<MeasurementView />} />

                <Route path="/comparision" element={<ComparisionView />} />

                <Route path="/share/measurements/:measurementIds" element={<ShareMeasurementsLanding />} />
                <Route path="/share/shoppingList/:cartId" element={<ShareCartLanding />} />

                <Route path="/fitAll" element={<FitAll />} />

                <Route path="/profile" element={<Profile />} />
                <Route path="/login" element={<Login />} />

                <Route path="/" element={<Navigate to="/measurements" replace />} />

                {/* Secret routes */}
                <Route path="/walletMeasurements" element={<WalletMeasurementsView />} />
                <Route path="/makeMeasurement" element={<MakeMeasurement />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
