import { Container } from 'react-bootstrap';
import { t } from 'i18next';
import '../../styles/Footer.scss';

const Footer = () => {
    return (
        <>
        <div className="footer-divider mt-auto"></div>
        <footer className="footer bg-light mt-3 py-2">
            <Container className='text-center'>
                <p className='text-muted'>
                    &copy; {new Date().getFullYear()} {t('EmitechCompany')} <a href="mailto:info@emitech.tech">info@emitech.tech</a> {/* <br />
                    <small>{t('EmitechAddress')}</small> */}
                </p>
            </Container>
        </footer>
        </>
    );
};

export default Footer;