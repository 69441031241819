import { faSearch, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { Button, Carousel, Col, Collapse, Container, Row, Table } from "react-bootstrap";
import { Trans } from "react-i18next";
import { sexToString } from "../../utils/sex";
import { getMeasurementName, makeShareLink } from "../../app/measurement";
import { shareUrl } from "../../utils/share";
import outputNumber from "../../utils/number";
import { useState } from "react";
import FitnessRatingElement from "../catalog/FitnessRatingElement";
import FeetMeasurementsTable from "./FeetMeasurementsTable";
import FitnessEstimateElement from "../catalog/FitnessEstimateElement";

const MeasurementDetails = ({ measurement, measurementSize }) => {
    const name = getMeasurementName(measurement);
    const humanName = measurement.humanInfo && measurement.humanInfo.name ? measurement.humanInfo.name : "";

    const [feetMeasurementsOpen, setFeetMeasurementsOpen] = useState(false);
    const [optimalLastsOpen, setOptimalLastsOpen] = useState(false);
    const [optimalLastInfoOpen, setOptimalLastInfoOpen] = useState(new Set());

    function getWidthClassificationText(widthClassification) {
        switch (widthClassification) {
            case "SMALL":
                return t("Small width");
            case "MEDIUM":
                return t("Medium width");
            case "LARGE":
                return t("Large width");
            default:
                return t("Unknown width");
        }
    }

    function getHeightClassificationText(heightClassification) {
        switch (heightClassification) {
            case "SMALL":
                return t("Small height");
            case "MEDIUM":
                return t("Medium height");
            case "LARGE":
                return t("Large height");
            default:
                return t("Unknown height");
        }
    }

    function getMeasurementDetails(measurement) {
        let details = [];

        details.push({ key: t("Measurement ID"), value: measurement.measurementId });

        const date = new Date(Date.parse(measurement.dateUtc));
        details.push({ key: t("Date"), value: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}` });

        if (measurement?.humanInfo?.name) {
            details.push({ key: t("Person name"), value: measurement.humanInfo.name });
        }

        if (measurement?.humanInfo?.sex) {
            details.push({ key: t("Sex"), value: sexToString(measurement.humanInfo.sex) });
        }

        if (measurement?.humanInfo?.age) {
            details.push({ key: t("Age"), value: measurement.humanInfo.age });
        }

        if (measurement?.humanInfo?.weight) {
            details.push({ key: t("Weight"), value: measurement.humanInfo.weight + " " + t("kg") });
        }

        if (measurement?.humanInfo?.usualSize) {
            details.push({ key: t("Usual size"), value: measurement.humanInfo.usualSize });
        }

        if (measurement?.sessionInfo?.deviceModel) {
            details.push({ key: t("Device model"), value: measurement.sessionInfo.deviceModel });
        }

        if (measurement?.sessionInfo?.softwareName && measurement?.sessionInfo?.softwareVersion) {
            details.push({ key: t("Software"), value: measurement.sessionInfo.softwareName + " " + measurement.sessionInfo.softwareVersion });
        }

        return details;
    }

    function toggleOptimalLastInfoOpen(last) {
        const newOptimalLastInfoOpen = new Set(optimalLastInfoOpen);
        if (newOptimalLastInfoOpen.has(last.style)) {
            newOptimalLastInfoOpen.delete(last.style);
        } else {
            newOptimalLastInfoOpen.add(last.style);
        }
        setOptimalLastInfoOpen(newOptimalLastInfoOpen);
    }

    function shareMeasurement(measurement) {
        // Generate share link
        const link = makeShareLink(measurement);

        // Share using system share API or clipboard
        shareUrl(t("Share measurement"), t("Share measurement"), link);
    }

    return (
        <>
            <h3 className="mt-3">{name}</h3>

            {/* Image and main details */}
            <Row className="mt-3">
                {/* Carousel with images */}
                <Col lg={6}>
                    <Carousel>
                        {measurement?.smallImagesFixed && measurement.smallImagesFixed.map(image => (
                            <Carousel.Item key={image}>
                                <img
                                    className="meas-details"
                                    src={image}
                                    alt={name}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
                {/* Main details */}
                <Col lg={6}>
                    <Container fluid className="p-3 shadow rounded">
                        {/* Size */}
                        {measurementSize?.size && (
                            <>
                                <Row className="align-items-center">
                                    <Col className="fw-bold"><Trans>Size</Trans></Col>
                                    <Col className="fw-bold text-primary fs-4">{measurementSize.size}</Col>
                                    <Col xs={6}>{measurementSize.sizeTableName && measurementSize.sizeTableName}</Col>
                                </Row>
                                {measurementSize?.alternateSizes && Object.entries(measurementSize.alternateSizes).map((size, index) => (
                                    <Row key={index}>
                                        <Col></Col>
                                        <Col className="text-primary">{size[1]}</Col>
                                        <Col xs={6}>{size[0]}</Col>
                                    </Row>
                                ))}
                            </>
                        )}

                        {/* Width */}
                        {measurementSize?.width && (
                            <>
                                <Row className="align-items-center">
                                    <Col className="fw-bold"><Trans>Width</Trans></Col>
                                    <Col className="fw-bold text-primary fs-4">{measurementSize.width}</Col>
                                    <Col xs={6}>{measurementSize.widthTableName && measurementSize.widthTableName}</Col>
                                </Row>
                                {measurementSize?.alternateWidths && Object.entries(measurementSize.alternateWidths).map((size, index) => (
                                    <Row key={index}>
                                        <Col></Col>
                                        <Col className="text-primary">{size[1]}</Col>
                                        <Col xs={6}>{size[0]}</Col>
                                    </Row>
                                ))}
                            </>
                        )}

                        {measurementSize?.widthClassification && (
                            <Row className="">
                                <Col className=""></Col>
                                <Col className="text-primary">{getWidthClassificationText(measurementSize.widthClassification)}</Col>
                                <Col xs={6}><Trans>Width type</Trans></Col>
                            </Row>
                        )}

                        {/* Height */}
                        {measurementSize?.height && (
                            <Row className="align-items-center">
                                <Col className="fw-bold"><Trans>Height</Trans></Col>
                                <Col className="fw-bold text-primary fs-4">{measurementSize.height}</Col>
                                <Col xs={6}>{measurementSize?.heightTableName && measurementSize.heightTableName}</Col>
                            </Row>
                        )}

                        {measurementSize?.heightClassification && (
                            <Row className="">
                                <Col className=""></Col>
                                <Col className="text-primary">{getHeightClassificationText(measurementSize.heightClassification)}</Col>
                                <Col xs={6}><Trans>Height type</Trans></Col>
                            </Row>
                        )}

                        {/* Share and fit buttons */}
                        <div className="d-flex justify-content-center mt-3">
                            <Button variant="secondary" className="me-3" onClick={() => shareMeasurement(measurement)}>
                                <FontAwesomeIcon icon={faShare} /> <Trans>Share</Trans>
                            </Button>
                            <a href={"/catalog?" + new URLSearchParams({ measurementId: measurement.measurementId, humanName: humanName })}
                                className="btn btn-primary"><FontAwesomeIcon icon={faSearch} /> <Trans>Fit shoes</Trans></a>
                        </div>
                    </Container>
                </Col>
            </Row>

            {/* Measurement details */}
            <h3 className="mt-3"><Trans>Info</Trans></h3>

            <Row className="mt-3">
                {getMeasurementDetails(measurement).map(detail => [
                    <Col xs={6} lg={3} className="text-muted" key={detail.key}>{detail.key}</Col>,
                    <Col xs={6} lg={3} key={detail.key + '_value'}>{detail.value}</Col>
                ]).flat()}
            </Row>

            {/* Feet measurements table */}
            <Button variant="link" className="mt-3" onClick={() => setFeetMeasurementsOpen(!feetMeasurementsOpen)} aria-expanded={feetMeasurementsOpen}>
                <Trans>mdShowFeetMeasurements</Trans>
            </Button>

            <Collapse in={feetMeasurementsOpen}>
                <div id="feetMeasurements">
                    <h3 className="mt-3"><Trans>Feet measurements</Trans></h3>

                    <FeetMeasurementsTable measurement={measurement} />
                </div>
            </Collapse>

            {/* Optimal lasts */}
            {measurementSize?.alternateLasts && (
                <>
                    <Button variant="link" className="mt-3" onClick={() => setOptimalLastsOpen(!optimalLastsOpen)} aria-expanded={optimalLastsOpen}>
                        <Trans>mdShowOptimalLasts</Trans>
                    </Button>
                    <Collapse in={optimalLastsOpen}>
                        <div id="optimalLasts" className="mt-3">
                            <h3 className="mt-3"><Trans>mdOptimalLasts</Trans></h3>

                            <Table className="mt-3">
                                <thead>
                                    <tr>
                                        <th><Trans>mdStyle</Trans></th>
                                        <th><Trans>mdName</Trans></th>
                                        <th><Trans>mdSize</Trans></th>
                                        <th><Trans>mdInsole</Trans></th>
                                        <th><Trans>mdLastFitnessRating</Trans></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {measurementSize?.alternateLasts && Object.values(measurementSize?.alternateLasts)?.map((last, index) => (
                                        <>
                                            <tr key={index + "row"} className={optimalLastInfoOpen.has(last?.style) ? "table-active" : ""}>
                                                <td>{last?.style}</td>
                                                <td>{last?.name}</td>
                                                <td>{last?.size}</td>
                                                <td>{outputNumber(last?.extraInsole?.forefootThickness, t("mm"))}</td>
                                                <td><Button variant="none" onClick={() => toggleOptimalLastInfoOpen(last)}><FitnessRatingElement rating={last?.fitnessRating} /></Button></td>
                                            </tr>

                                            {optimalLastInfoOpen.has(last?.style) && (
                                                <tr key={index + "info"} className="table-active">
                                                    <td colSpan={5}>
                                                        <div className="mt-1 mb-3">
                                                            <FitnessEstimateElement fitnessEstimate={last} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Collapse>
                </>
            )}
        </>
    )
}

export default MeasurementDetails;
