import { Button, Modal } from "react-bootstrap";
import { Trans } from "react-i18next";

function ConfirmationDialog({ show, title, message, confirmText, variant, onConfirm, onCancel }) {
    return (
        <Modal show={show} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button variant={variant} onClick={onConfirm}>{confirmText}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationDialog;
