function isoDateToString(isoDate) {
    // Parse ISO date string
    const date = new Date(isoDate);

    // Convert to local time
    // const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    
    return date.toLocaleDateString();
}

function shoeserverDateToString(shoeserverDateUtc, skipDate, skipTime) {
    // Append 'Z' to the date string to indicate it's UTC since shoeserver doesn't set it
    const date = new Date(shoeserverDateUtc + "Z");
    
    let items = []
    if (!skipDate) {
        items.push(date.toLocaleDateString());
    }
    if (!skipTime) {
        items.push(date.toLocaleTimeString());
    }
    return items.join(" ");
}

export { isoDateToString, shoeserverDateToString };
