import { t } from "i18next";
import outputNumber from "../utils/number";

function getRatingText(rating) {
    if (rating >= 4.0) {
        return {
            text: t("Excellent"),
            style: "text-excellent fw-bold"
        };
    } else if (rating >= 3.0) {
        return {
            text: t("Good"),
            style: "text-good fw-bold"
        };
    } else if (rating >= 0.0) {
        return {
            text: t("Acceptable"),
            style: "text-acceptable fw-bold"
        };
    } else {
        return {
            text: t("Not good"),
            style: "text-bad fw-bold"
        }
    }
}

function getDeviationText(deviation) {
    if (Math.abs(deviation) < 0.33) {
        return {
            text: t("Perfect"),
            style: "text-excellent fw-bold"
        };
    } else if (Math.abs(deviation) < 0.67) {
        return {
            text: deviation > 0 ? t("A little loose") : t("Fit tightly"),
            style: "text-good fw-bold"
        };
    } else if (Math.abs(deviation) < 1.0) {
        return {
            text: deviation > 0 ? t("A little loose") : t("Fit tightly"),
            style: "text-acceptable fw-bold"
        };
    } else {
        return {
            text: deviation > 0 ? t("Very loose") : t("May be tight"),
            style: "text-bad fw-bold"
        };
    }
}

function getInsoleText(insole) {
    if (insole?.forefootThickness === undefined) {
        return "?";
    }

    if (insole?.name) {
        return `${insole.name} (${outputNumber(insole.forefootThickness, t("mm"))})`;
    } else {
        return outputNumber(insole.forefootThickness, t("mm"));
    }
}

export { getRatingText, getDeviationText, getInsoleText };
