import { useCallback, useEffect, useState } from "react";
import useCartApi from "../../app/useCartApi";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import Loader from "../global/Loader";
import { Col, Container, Row } from "react-bootstrap";
import CartHistoryItem from "./CartHistoryItem";
import PaginationBlock from "../global/PaginationBlock";
import { Navigate } from "react-router-dom";
import { Trans } from "react-i18next";

function CartHistory() {
    const cartApi = useCartApi();

    const [carts, setCarts] = useState([]);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    const [loadCartId, setLoadCartId] = useState(null);
    const [cartWasDeleted, setCartWasDeleted] = useState(false);

    const deleteCart = useCallback(async (cartId) => {
        try {
            await cartApi.deleteCart(cartId);
            setCartWasDeleted(true);
        } catch (error) {
            console.error(error);
            showNotification(t("cartHistoryDeleteError") + ": " + error.message, 'danger');
        }
    }, [cartApi]);

    useEffect(() => {
        setIsLoading(true);

        async function fetchCarts() {
            const reply = await cartApi.getCarts(page);

            setCarts(reply.carts);
            setPage(reply.page.page);
            setPageCount(reply.page.pageCount);
            setTotalCount(reply.page.totalCount);
        }

        const delayedFetch = setTimeout(() => {
            fetchCarts()
                .then(() => setIsLoading(false))
                .catch(error => {
                    setIsLoading(false);
                    console.error(error);
                    showNotification(t("cartHistoryFetchError") + ": " + error.message, 'danger');
                });
            
            setCartWasDeleted(false);
        }, 200);

        return () => clearTimeout(delayedFetch);
    }, [cartApi, page, cartWasDeleted]);

    if (isLoading) {
        return (
            <div className="mt-3">
                <Loader />
            </div>
        );
    }

    if (loadCartId) {
        return <Navigate to={`/cart/${loadCartId}`} replace />;
    }

    return (
        <div className="mt-3">
            <Container>
                <Row className="mb-1">
                    <Col className="fw-bold"><Trans>cartHistoryColCart</Trans></Col>
                    <Col className="fw-bold d-none d-md-table-cell"><Trans>cartHistoryColItems</Trans></Col>
                    <Col className="fw-bold"><Trans>cartHistoryColAction</Trans></Col>
                </Row>

                {carts.length > 0 ? carts.map(cart => (
                    <CartHistoryItem key={cart.cartId} cartApi={cartApi} cart={cart} handleLoadCart={setLoadCartId} handleDeleteCart={deleteCart} />
                )) : (
                    <p>{t("cartHistoryNoCarts")}</p>
                )}
            </Container>

            {pageCount > 1 && (
                <div className="mt-3">
                    <PaginationBlock currentPage={page} totalPages={pageCount} setCurrentPage={setPage} maxDisplayedPages={5} />
                </div>
            )}
        </div>
    );
}

export default CartHistory;
