import { useMemo } from "react";
import useLocalStorageState from "use-local-storage-state";
import CartApi from "./cartApi";

function useCartApi() {
    const [sessionId, ] = useLocalStorageState("sessionId", null);
    return useMemo(() => new CartApi(sessionId), [sessionId]);
}

export default useCartApi;
