function deepCompare(obj1, obj2) {
    // Check if both inputs are objects
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return obj1 === obj2;
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the objects have the same number of keys
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Iterate through the keys and compare the values
    for (const key of keys1) {
        // Recursively compare the values if they are objects or arrays
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
            if (!deepCompare(obj1[key], obj2[key])) {
                return false;
            }
        } else if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    // If all checks pass, the objects are the same
    return true;
}

export { deepCompare };
