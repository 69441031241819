import { useMemo } from "react";
import useLocalStorageState from "use-local-storage-state";
import CartData from "./cartData";

function useCartData() {
    const [cartId, setCartId] = useLocalStorageState('cartId');
    const [cartItems, setCartItems] = useLocalStorageState('cartItems', { defaultValue: [] });
    const [cartName, setCartName] = useLocalStorageState('cartName', { defaultValue: '' });
    const [cartSubmitted, setCartSubmitted] = useLocalStorageState('cartSubmitted', { defaultValue: false });

    const [cartHistory, setCartHistory] = useLocalStorageState('cartHistory', { defaultValue: {} });

    const cart = useMemo(() => new CartData(cartId, cartItems, cartName, cartSubmitted), [cartId, cartItems, cartName, cartSubmitted]);

    return { cart, setCartId, setCartItems, setCartName, setCartSubmitted, cartHistory, setCartHistory };
}

export default useCartData;
