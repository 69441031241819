import { t } from 'i18next';

const CurrencyFormatter = (props) => {
    const amount = props.amount;

    return (
        (amount >= 0.0001) ? (
            <span>{parseFloat(amount).toFixed(2)} ₽</span>
        ) : (
            <span className="text-muted">{t("curOnRequest")}</span>
        )
    );
};

export default CurrencyFormatter;
