import { t } from "i18next";

function sexToString(sex) {
    if (!sex || typeof (sex) !== 'string') {
        return t('Unknown');
    }

    switch (sex.toLowerCase()) {
        case 'male':
            return t('Male');
        case 'female':
            return t('Female');
        default:
            return t('Unknown');
    }
}

export { sexToString };
