// based on https://gist.github.com/bobspace/2712980

import { t } from "i18next";

// CSS Color Names
// Compiled by @bobspace.
//
// A javascript object containing all of the color names listed in the CSS Spec.
// This used to be a big array, but the hex values are useful too, so now it's an object.
// If you need the names as an array use Object.keys, but you already knew that!
// 
// The full list can be found here: https://www.w3schools.com/cssref/css_colors.asp
// Use it as you please, 'cuz you can't, like, own a color, man.

const CSS_COLORS = {
    'AliceBlue': '#F0F8FF',
    'AntiqueWhite': '#FAEBD7',
    'Aqua': '#00FFFF',
    'Aquamarine': '#7FFFD4',
    'Azure': '#F0FFFF',
    'Beige': '#F5F5DC',
    'Bisque': '#FFE4C4',
    'Black': '#000000',
    'BlanchedAlmond': '#FFEBCD',
    'Blue': '#0000FF',
    'BlueViolet': '#8A2BE2',
    'Brown': '#A52A2A',
    'BurlyWood': '#DEB887',
    'CadetBlue': '#5F9EA0',
    'Chartreuse': '#7FFF00',
    'Chocolate': '#D2691E',
    'Coral': '#FF7F50',
    'CornflowerBlue': '#6495ED',
    'Cornsilk': '#FFF8DC',
    'Crimson': '#DC143C',
    'Cyan': '#00FFFF',
    'DarkBlue': '#00008B',
    'DarkCyan': '#008B8B',
    'DarkGoldenRod': '#B8860B',
    'DarkGray': '#A9A9A9',
    'DarkGrey': '#A9A9A9',
    'DarkGreen': '#006400',
    'DarkKhaki': '#BDB76B',
    'DarkMagenta': '#8B008B',
    'DarkOliveGreen': '#556B2F',
    'DarkOrange': '#FF8C00',
    'DarkOrchid': '#9932CC',
    'DarkRed': '#8B0000',
    'DarkSalmon': '#E9967A',
    'DarkSeaGreen': '#8FBC8F',
    'DarkSlateBlue': '#483D8B',
    'DarkSlateGray': '#2F4F4F',
    'DarkSlateGrey': '#2F4F4F',
    'DarkTurquoise': '#00CED1',
    'DarkViolet': '#9400D3',
    'DeepPink': '#FF1493',
    'DeepSkyBlue': '#00BFFF',
    'DimGray': '#696969',
    'DimGrey': '#696969',
    'DodgerBlue': '#1E90FF',
    'FireBrick': '#B22222',
    'FloralWhite': '#FFFAF0',
    'ForestGreen': '#228B22',
    'Fuchsia': '#FF00FF',
    'Gainsboro': '#DCDCDC',
    'GhostWhite': '#F8F8FF',
    'Gold': '#FFD700',
    'GoldenRod': '#DAA520',
    'Gray': '#808080',
    'Grey': '#808080',
    'Green': '#008000',
    'GreenYellow': '#ADFF2F',
    'HoneyDew': '#F0FFF0',
    'HotPink': '#FF69B4',
    'IndianRed': '#CD5C5C',
    'Indigo': '#4B0082',
    'Ivory': '#FFFFF0',
    'Khaki': '#F0E68C',
    'Lavender': '#E6E6FA',
    'LavenderBlush': '#FFF0F5',
    'LawnGreen': '#7CFC00',
    'LemonChiffon': '#FFFACD',
    'LightBlue': '#ADD8E6',
    'LightCoral': '#F08080',
    'LightCyan': '#E0FFFF',
    'LightGoldenRodYellow': '#FAFAD2',
    'LightGray': '#D3D3D3',
    'LightGrey': '#D3D3D3',
    'LightGreen': '#90EE90',
    'LightPink': '#FFB6C1',
    'LightSalmon': '#FFA07A',
    'LightSeaGreen': '#20B2AA',
    'LightSkyBlue': '#87CEFA',
    'LightSlateGray': '#778899',
    'LightSlateGrey': '#778899',
    'LightSteelBlue': '#B0C4DE',
    'LightYellow': '#FFFFE0',
    'Lime': '#00FF00',
    'LimeGreen': '#32CD32',
    'Linen': '#FAF0E6',
    'Magenta': '#FF00FF',
    'Maroon': '#800000',
    'MediumAquaMarine': '#66CDAA',
    'MediumBlue': '#0000CD',
    'MediumOrchid': '#BA55D3',
    'MediumPurple': '#9370DB',
    'MediumSeaGreen': '#3CB371',
    'MediumSlateBlue': '#7B68EE',
    'MediumSpringGreen': '#00FA9A',
    'MediumTurquoise': '#48D1CC',
    'MediumVioletRed': '#C71585',
    'MidnightBlue': '#191970',
    'MintCream': '#F5FFFA',
    'MistyRose': '#FFE4E1',
    'Moccasin': '#FFE4B5',
    'NavajoWhite': '#FFDEAD',
    'Navy': '#000080',
    'OldLace': '#FDF5E6',
    'Olive': '#808000',
    'OliveDrab': '#6B8E23',
    'Orange': '#FFA500',
    'OrangeRed': '#FF4500',
    'Orchid': '#DA70D6',
    'PaleGoldenRod': '#EEE8AA',
    'PaleGreen': '#98FB98',
    'PaleTurquoise': '#AFEEEE',
    'PaleVioletRed': '#DB7093',
    'PapayaWhip': '#FFEFD5',
    'PeachPuff': '#FFDAB9',
    'Peru': '#CD853F',
    'Pink': '#FFC0CB',
    'Plum': '#DDA0DD',
    'PowderBlue': '#B0E0E6',
    'Purple': '#800080',
    'RebeccaPurple': '#663399',
    'Red': '#FF0000',
    'RosyBrown': '#BC8F8F',
    'RoyalBlue': '#4169E1',
    'SaddleBrown': '#8B4513',
    'Salmon': '#FA8072',
    'SandyBrown': '#F4A460',
    'SeaGreen': '#2E8B57',
    'SeaShell': '#FFF5EE',
    'Sienna': '#A0522D',
    'Silver': '#C0C0C0',
    'SkyBlue': '#87CEEB',
    'SlateBlue': '#6A5ACD',
    'SlateGray': '#708090',
    'SlateGrey': '#708090',
    'Snow': '#FFFAFA',
    'SpringGreen': '#00FF7F',
    'SteelBlue': '#4682B4',
    'Tan': '#D2B48C',
    'Teal': '#008080',
    'Thistle': '#D8BFD8',
    'Tomato': '#FF6347',
    'Turquoise': '#40E0D0',
    'Violet': '#EE82EE',
    'Wheat': '#F5DEB3',
    'White': '#FFFFFF',
    'WhiteSmoke': '#F5F5F5',
    'Yellow': '#FFFF00',
    'YellowGreen': '#9ACD32',
};

function getCssColorName(cssColor) {
    const CSS_COLOR_NAMES = {
        'AliceBlue': t('Alice Blue'),
        'AntiqueWhite': t('Antique White'),
        'Aqua': t('Aqua'),
        'Aquamarine': t('Aquamarine'),
        'Azure': t('Azure'),
        'Beige': t('Beige'),
        'Bisque': t('Bisque'),
        'Black': t('Black'),
        'BlanchedAlmond': t('Blanched Almond'),
        'Blue': t('Blue'),
        'BlueViolet': t('Blue Violet'),
        'Brown': t('Brown'),
        'BurlyWood': t('Burly Wood'),
        'CadetBlue': t('Cadet Blue'),
        'Chartreuse': t('Chartreuse'),
        'Chocolate': t('Chocolate'),
        'Coral': t('Coral'),
        'CornflowerBlue': t('Cornflower Blue'),
        'Cornsilk': t('Cornsilk'),
        'Crimson': t('Crimson'),
        'Cyan': t('Cyan'),
        'DarkBlue': t('Dark Blue'),
        'DarkCyan': t('Dark Cyan'),
        'DarkGoldenRod': t('Dark Golden Rod'),
        'DarkGray': t('Dark Gray'),
        'DarkGrey': t('Dark Grey'),
        'DarkGreen': t('Dark Green'),
        'DarkKhaki': t('Dark Khaki'),
        'DarkMagenta': t('Dark Magenta'),
        'DarkOliveGreen': t('Dark Olive Green'),
        'Darkorange': t('Darkorange'),
        'DarkOrchid': t('Dark Orchid'),
        'DarkRed': t('Dark Red'),
        'DarkSalmon': t('Dark Salmon'),
        'DarkSeaGreen': t('Dark Sea Green'),
        'DarkSlateBlue': t('Dark Slate Blue'),
        'DarkSlateGray': t('Dark Slate Gray'),
        'DarkSlateGrey': t('Dark Slate Grey'),
        'DarkTurquoise': t('Dark Turquoise'),
        'DarkViolet': t('Dark Violet'),
        'DeepPink': t('Deep Pink'),
        'DeepSkyBlue': t('Deep Sky Blue'),
        'DimGray': t('Dim Gray'),
        'DimGrey': t('Dim Grey'),
        'DodgerBlue': t('Dodger Blue'),
        'FireBrick': t('Fire Brick'),
        'FloralWhite': t('Floral White'),
        'ForestGreen': t('Forest Green'),
        'Fuchsia': t('Fuchsia'),
        'Gainsboro': t('Gainsboro'),
        'GhostWhite': t('Ghost White'),
        'Gold': t('Gold'),
        'GoldenRod': t('Golden Rod'),
        'Gray': t('Gray'),
        'Grey': t('Grey'),
        'Green': t('Green'),
        'GreenYellow': t('Green Yellow'),
        'HoneyDew': t('Honey Dew'),
        'HotPink': t('Hot Pink'),
        'IndianRed': t('Indian Red'),
        'Indigo': t('Indigo'),
        'Ivory': t('Ivory'),
        'Khaki': t('Khaki'),
        'Lavender': t('Lavender'),
        'LavenderBlush': t('Lavender Blush'),
        'LawnGreen': t('Lawn Green'),
        'LemonChiffon': t('Lemon Chiffon'),
        'LightBlue': t('Light Blue'),
        'LightCoral': t('Light Coral'),
        'LightCyan': t('Light Cyan'),
        'LightGoldenRodYellow': t('Light Golden Rod Yellow'),
        'LightGray': t('Light Gray'),
        'LightGrey': t('Light Grey'),
        'LightGreen': t('Light Green'),
        'LightPink': t('Light Pink'),
        'LightSalmon': t('Light Salmon'),
        'LightSeaGreen': t('Light Sea Green'),
        'LightSkyBlue': t('Light Sky Blue'),
        'LightSlateGray': t('Light Slate Gray'),
        'LightSlateGrey': t('Light Slate Grey'),
        'LightSteelBlue': t('Light Steel Blue'),
        'LightYellow': t('Light Yellow'),
        'Lime': t('Lime'),
        'LimeGreen': t('Lime Green'),
        'Linen': t('Linen'),
        'Magenta': t('Magenta'),
        'Maroon': t('Maroon'),
        'MediumAquaMarine': t('Medium Aqua Marine'),
        'MediumBlue': t('Medium Blue'),
        'MediumOrchid': t('Medium Orchid'),
        'MediumPurple': t('Medium Purple'),
        'MediumSeaGreen': t('Medium Sea Green'),
        'MediumSlateBlue': t('Medium Slate Blue'),
        'MediumSpringGreen': t('Medium Spring Green'),
        'MediumTurquoise': t('Medium Turquoise'),
        'MediumVioletRed': t('Medium Violet Red'),
        'MidnightBlue': t('Midnight Blue'),
        'MintCream': t('Mint Cream'),
        'MistyRose': t('Misty Rose'),
        'Moccasin': t('Moccasin'),
        'NavajoWhite': t('Navajo White'),
        'Navy': t('Navy'),
        'OldLace': t('Old Lace'),
        'Olive': t('Olive'),
        'OliveDrab': t('Olive Drab'),
        'Orange': t('Orange'),
        'OrangeRed': t('Orange Red'),
        'Orchid': t('Orchid'),
        'PaleGoldenRod': t('Pale Golden Rod'),
        'PaleGreen': t('Pale Green'),
        'PaleTurquoise': t('Pale Turquoise'),
        'PaleVioletRed': t('Pale Violet Red'),
        'PapayaWhip': t('Papaya Whip'),
        'PeachPuff': t('Peach Puff'),
        'Peru': t('Peru'),
        'Pink': t('Pink'),
        'Plum': t('Plum'),
        'PowderBlue': t('Powder Blue'),
        'Purple': t('Purple'),
        'RebeccaPurple': t('Rebecca Purple'),
        'Red': t('Red'),
        'RosyBrown': t('Rosy Brown'),
        'RoyalBlue': t('Royal Blue'),
        'SaddleBrown': t('Saddle Brown'),
        'Salmon': t('Salmon'),
        'SandyBrown': t('Sandy Brown'),
        'SeaGreen': t('Sea Green'),
        'SeaShell': t('Sea Shell'),
        'Sienna': t('Sienna'),
        'Silver': t('Silver'),
        'SkyBlue': t('Sky Blue'),
        'SlateBlue': t('Slate Blue'),
        'SlateGray': t('Slate Gray'),
        'SlateGrey': t('Slate Grey'),
        'Snow': t('Snow'),
        'SpringGreen': t('Spring Green'),
        'SteelBlue': t('Steel Blue'),
        'Tan': t('Tan'),
        'Teal': t('Teal'),
        'Thistle': t('Thistle'),
        'Tomato': t('Tomato'),
        'Turquoise': t('Turquoise'),
        'Violet': t('Violet'),
        'Wheat': t('Wheat'),
        'White': t('White'),
        'WhiteSmoke': t('White Smoke'),
        'Yellow': t('Yellow'),
        'YellowGreen': t('Yellow Green'),
    };

    return CSS_COLOR_NAMES[cssColor] || cssColor;
}

export { CSS_COLORS, getCssColorName };
