import { t } from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { showNotification } from "../utils/notification";
import { apiGetCartMeasurements } from "../app/measurementApi";
import { apiGetShoeModels } from "../app/catalogApi";
import { Alert, Col, Container, ProgressBar, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import useCartApi from "../app/useCartApi";
import useCartData from "../app/useCartData";

function FitAll() {
    const location = useLocation();

    const selectedProperties = useMemo(() => {
        const selectedPropertiesState = location.state?.selectedProperties;
        if (!selectedPropertiesState) {
            showNotification(t("faNoProperties"), 'danger');
            setErrorText(t("faNoProperties"));
        }

        return selectedPropertiesState;
    }, [location]);

    const searchString = useMemo(() => {
        return location.state?.searchString;
    }, [location]);

    const { cart, setCartItems } = useCartData();
    const cartApi = useCartApi();

    const [progress, setProgress] = useState(0);
    const [active, setActive] = useState(true);
    const [currentMsg, setCurrentMsg] = useState(null);

    const [errorText, setErrorText] = useState(null);
    const [successText, setSuccessText] = useState(null);

    const getHumanName = useCallback(async (measurement) => {
        if (measurement.humanInfo?.name) {
            return measurement.humanInfo.name;
        } else {
            return t("faNoName");
        }
    }, []);

    const fitForMeasurement = useCallback(async (measurementId) => {
        const data = await apiGetShoeModels(1, 1, measurementId, searchString, selectedProperties, "RATING");
        const shoeModels = data.shoeModels;

        if (shoeModels.length > 0) {
            const shoeModel = shoeModels[0];
            return shoeModel;
        } else {
            return null;
        }
    }, [selectedProperties, searchString]);

    const fitAll = useCallback(async () => {
        setActive(true);
        setErrorText(null);
        setSuccessText(null);

        // Load measurements for cart   
        setProgress(0);
        setCurrentMsg(t("faLoadingMeasurements"));

        if (!cart.cartId) {
            setActive(false);
            throw new Error(t("faNoCart"));
        }

        // Load measurements for cart
        const measurements = await apiGetCartMeasurements(cart.cartId);
        if (measurements.length === 0) {
            setActive(false);
            throw new Error(t("faNoMeasurements"));
        }

        // Fit for all measurements
        let addedCount = 0;
        for (let i = 0; i < measurements.length; i++) {
            setProgress(i / measurements.length);

            // Get human name
            const humanName = await getHumanName(measurements[i]);
            setCurrentMsg(t("faFittingMeasurement") + " " + (i + 1) + " / " + measurements.length + " (" + humanName + ")");

            // Fit for measurement
            const shoeModel = await fitForMeasurement(measurements[i].measurementId, selectedProperties);

            // Add to cart
            if (shoeModel) {
                setCartItems(cartItems => cart.add(cartItems, shoeModel, shoeModel.fitnessEstimate.size, shoeModel.fitnessEstimate.insole, humanName));
                addedCount++;
            }
        }

        // Success
        setProgress(1);
        setActive(false);
        setSuccessText(t("faSuccess") + " " + addedCount);
    }, [getHumanName, fitForMeasurement, selectedProperties, setCartItems, cart]);

    // Main fit-all effect
    useEffect(() => {
        const timeout = setTimeout(() => {
            fitAll()
                .catch((error) => {
                    console.log(error);
                    showNotification(error.message, "danger");
                });
        }, 500);

        return () => clearTimeout(timeout);
    }, [fitAll]);

    // Save cart effect
    useEffect(() => {
        if (cart.cartId) {
            cartApi.save(cart.cartId, cart.cartItems)
                .catch((error) => {
                    console.log(error);
                    showNotification(t("Error saving shopping list"), "danger");
                });
        }
    }, [cart.cartId, cart.cartItems, cartApi, setCartItems]);

    return (
        <Container fluid className="my-5 mx-auto">
            <Row>
                <Col xs={12} md={6} className="mx-auto">
                    <h1 className="text-center mt-3">{t("faFitAll")}</h1>
                    <p className="text-center mt-1">{t("faFitAllDesc") + " (" + cart.cartId + ")"}</p>

                    <ProgressBar className="mt-3" animated={active} now={progress * 100} />
                    <p className="text-center mt-1">{currentMsg}</p>

                    {errorText && (
                        <Alert variant="danger" className="text-center mt-3">{errorText}</Alert>
                    )}

                    {successText && (
                        <>
                            <Alert variant="success" className="text-center mt-3">{successText}</Alert>
                            <p className="text-center mt-1"><Link to="/cart" className="btn btn-success mt-1"><FontAwesomeIcon icon={faShoppingCart} /> {t("faViewCart")}</Link></p>
                        </>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default FitAll;
